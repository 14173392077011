import React from "react";

import { push } from "connected-react-router";
import { useDispatch } from "react-redux";

import { Button, Card, Col } from "antd";

import { Application, Routes } from "../../constants";

const NewUserPrompt = () => {
    const dispatch = useDispatch();

    return (
        <Col offset={6} span={12}>
            <Card title="New Users">
                <div>
                    Signing up for a {Application.Name} account currently
                    requires a sign up code.<br/>Click the button below to enter
                    your sign up code.
                </div>
                <div className="tt-spacer" />
                <Button
                    onClick={() => dispatch(push(Routes.SIGNUP))}
                    type="primary"
                    className="tt-login-button"
                >
                    Enter Code
                </Button>
            </Card>
        </Col>
    );
};

export default NewUserPrompt;
