import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { Spin } from "antd";

import { acceptInviteAsync } from "../../redux/actions/personActions";
import { Routes } from "../../constants";

export const InviteAcceptContainer = (props) => {
    const invitationId = props?.match?.params?.inviteId; // Get the inviteId from the URL

    const dispatch = useDispatch();

    const isAuthReady = useSelector((state) => state.auth.isReady);
    const isAuthenticated = useSelector((state) => state.auth.authenticated);

    // Effect to get invite data and try accept the invite
    useEffect(() => {
        const invitePageURL = Routes.INVITE.replace(":inviteId", invitationId);
        const redirectToInvitePage = () => {
            dispatch(push(invitePageURL));
        };

        const acceptInvite = async () => {
            try {
                let updatedInvite = await acceptInviteAsync(invitationId);
                console.log("Successfully accepted invite", updatedInvite);
                dispatch(push(Routes.INVITE_ACCEPTED));
            } catch (ex) {
                console.log("Failed to accept invite with error:", ex);
                redirectToInvitePage();
            }
        };

        if (!isAuthReady) {
            return; // Can't proceed until auth is ready
        }

        // Redirect to the invite page to display login prompts/invite info/error messages, if:
        //     - Not logged in
        //     - Invalid invitation id
        if (!isAuthenticated || !invitationId) {
            redirectToInvitePage();
            return;
        }

        acceptInvite();
    }, [isAuthReady, invitationId, dispatch, isAuthenticated]);

    return <Spin />;
};

export default InviteAcceptContainer;
