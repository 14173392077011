import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, Input, Row, Spin, Form, Col, Card, Alert, Space } from "antd";

import {
    showNotificationError,
    showNotificationSuccess,
    getUserSignupCodeAsync,
    getSignupCodeStatusAsync,
} from "../../functions";
import {
    APIEndpoints,
    Application,
    Routes,
    SignupCodeStatuses,
} from "../../constants";
import { ajaxPostAsync2 } from "../../redux/actions/ajaxActions";
import ThingInput from "../things/ThingInput";
import { QueryParameterNames } from "../../auth/AuthRoutes";
import LoginButtons from "../login/LoginButtons";
import { push } from "connected-react-router";

export const SignupContainer = (props) => {
    const signupCodeFromURL = props?.match?.params?.signupCode; // Get the signupCode from the URL
    const notificationKey = "NK_SignupContainer";

    const dispatch = useDispatch();

    const isAuthenticated = useSelector((state) => state.auth.authenticated);
    const userId = useSelector((state) => state.auth.userId);
    const [userSignupCode, setUserSignupCode] = useState(false);
    const [isCheckingSignupCode, setIsCheckingSignupCode] = useState(false);

    const [isProcessingSignup, setIsProcessingSignup] = useState(false);
    const [signupCode, setSignupCode] = useState(signupCodeFromURL);
    const [signupData, setSignupData] = useState(null);

    const [form] = Form.useForm();

    const checkSignupCode = useCallback(
        async (code) => {
            try {
                setIsProcessingSignup(true);
                let signupData = await getSignupCodeStatusAsync(code);
                // console.log("Signup Status:", signupData);
                setSignupData(signupData);
            } catch (ex) {
                console.error(ex);
                showNotificationError(
                    notificationKey,
                    "Failed to get sign up code status!",
                    null
                );
            } finally {
                setIsProcessingSignup(false);
            }
        },
        [setIsProcessingSignup]
    );

    // Effect to check if a user already has a signup code
    useEffect(() => {
        // Need a method here as useEffect can't be async
        const checkSignupCode = async () => {
            try {
                setIsCheckingSignupCode(true);
                let userSignupCode = await getUserSignupCodeAsync(userId);
                console.log("User Signup Code:", userSignupCode);
                setUserSignupCode(userSignupCode);
            } catch (ex) {
                console.log(ex);
                showNotificationError(
                    notificationKey,
                    "Failed to get sign up code status!",
                    null
                );
            } finally {
                setIsCheckingSignupCode(false);
            }
        };
        checkSignupCode();
    }, [userId]);

    // Effect to get signup status when code is provided through the URL
    useEffect(() => {
        if (signupData == null && signupCodeFromURL != null) {
            checkSignupCode(signupCodeFromURL);
        }
    }, [signupCodeFromURL, checkSignupCode, signupData]);

    // React function names can't begin with the word 'use'. That's why this function is named in an odd manner
    const makeUseOfSignupCode = (signupCode) => {
        if (signupCode === null || signupCode === "") return null;
        return ajaxPostAsync2(APIEndpoints.Signup + `SignupCode/`, {
            SignupCode: signupCode,
        });
    };

    const handleUseSignupCode = async (code) => {
        const newAccountRelativeUrl =
            Routes.NEW_ACCOUNT + encodeURIComponent(code);

        if (!isAuthenticated) {
            dispatch(
                push(
                    `${Routes.LOGIN}?${QueryParameterNames.ReturnUrl}=${window.location.origin + newAccountRelativeUrl
                    }`
                )
            );
            return;
        }

        try {
            setIsProcessingSignup(true);
            await makeUseOfSignupCode(code);
            showNotificationSuccess(
                notificationKey,
                "Sign up Code successfully used!",
                null
            );
            dispatch(push(newAccountRelativeUrl));
        } catch (ex) {
            let errorMessage = ex.response?.data || ex;
            showNotificationError(notificationKey, errorMessage, null);
        } finally {
            setIsProcessingSignup(false);
        }
    };

    const renderAlreadyHasSignupCodeSection = () => {
        return (
            <Fragment>
                <div>
                    <Alert
                        type="error"
                        message={`You have already signed up for a ${Application.Name} account`}
                    />
                </div>
            </Fragment>
        );
    };

    const renderSignupCodeForm = (isReadonly) => {
        return (
            <Row>
                <Form
                    name="signupCodeForm"
                    form={form}
                    onFinish={() => checkSignupCode(signupCode)}
                    layout="vertical"
                    hideRequiredMark
                    scrollToFirstError
                    initialValues={{
                        signupCode: signupCode,
                    }}
                >
                    <ThingInput visible={true} label="Enter Sign Up Code:">
                        <Form.Item
                            name="signupCode"
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: "Required",
                                    whitespace: true,
                                },
                            ]}
                        >
                            <Input
                                readOnly={isReadonly}
                                disabled={isReadonly}
                                onChange={(e) => setSignupCode(e.target.value)}
                                value={signupCode}
                                autoFocus
                                allowClear
                                autoComplete="off"
                            />
                        </Form.Item>
                    </ThingInput>
                    {!isReadonly && (
                        <Form.Item>
                            {isProcessingSignup && <Spin />}
                            {!isProcessingSignup && (
                                <Fragment>
                                    <Button type="primary" htmlType="submit">
                                        Check Sign Up Code
                                    </Button>
                                </Fragment>
                            )}
                        </Form.Item>
                    )}
                </Form>
            </Row>
        );
    };

    const renderSignupStatus = (signupData) => {
        if (!signupData) {
            return null;
        }

        const statusString = signupData?.statusString;

        if (statusString === SignupCodeStatuses.ValidNotUsed) {
            return (
                <Alert
                    type="success"
                    message={
                        <Fragment>
                            <div>
                                Sign up code {signupData.signupCode} is valid
                            </div>
                        </Fragment>
                    }
                />
            );
        }

        if (statusString === SignupCodeStatuses.ValidUsed) {
            return (
                <Alert
                    type="error"
                    message={`Sign up code ${signupData.signupCode} is not valid`}
                />
            );
        }

        // statusString is null or Invalid
        return (
            <Alert
                type="error"
                message={`Sign up code ${signupData?.signupCode} is not valid`}
            />
        );
    };

    const renderInLayout = (content) => {
        return (
            <Fragment>
                <Col offset={6} span={12}>
                    <Card title="Account Sign Up">{content}</Card>
                </Col>
            </Fragment>
        );
    };

    // Wait until done checking the signup code
    if (isCheckingSignupCode) {
        return renderInLayout(<Spin />);
    }

    // Check if the user already has a signup code
    if (userSignupCode !== null) {
        return renderInLayout(
            <Fragment>{renderAlreadyHasSignupCodeSection()}</Fragment>
        );
    }

    // Check if the signup code is valid
    if (signupData?.statusString === SignupCodeStatuses.ValidNotUsed) {
        // Render a readonly form and the login buttons
        return renderInLayout(
            <Space direction="vertical" style={{ width: "100%" }}>
                {renderSignupStatus(signupData)}
                {/* Disabling read only sign up code view here as it is confusing to see a disabled input field when the code is valid */}
                {/* {renderSignupCodeForm(true)} */}
                {isAuthenticated ? (
                    <Button
                        type="primary"
                        onClick={() =>
                            handleUseSignupCode(signupData.signupCode)
                        }
                    >
                        Setup Your Account
                    </Button>
                ) : (
                        <LoginButtons message={isAuthenticated
                            ? `Click the button below to setup a ${Application.Name} account.`
                            : `${Application.Name} uses the following options to login to an account. Click one of the buttons below to login and continue the account sign up process.`}
                            returnUrl={Routes.NEW_ACCOUNT + signupData.signupCode} />
                    )}
            </Space>
        );
    }

    // Render any messages with a form so the user can enter their signup code
    return renderInLayout(
        <Space direction="vertical" style={{ width: "100%" }}>
            {renderSignupStatus(signupData)}
            {renderSignupCodeForm(false)}
        </Space>
    );
};

export default SignupContainer;
