import React from "react";
import { Route } from "react-router";

export const AppRoute = ({
    component: Component,
    render,
    layout: Layout,
    ...rest
}) => {
    return (
        <Route
            {...rest}
            render={(props) => (
                <Layout>
                    {!!Component && <Component {...props} />}
                    {!!render && render(props)}
                </Layout>
            )}
        />
    );
};

export default AppRoute;
