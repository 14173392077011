import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { List } from "antd";
import classNames from "classnames";

import { clearSearchAndRedirectToThing } from "../../redux/actions";
import { getInProgressThingsListDispatchable } from "../../redux/actions/thingListsActions";

import { MessageError } from "../../shared/MessageError";
import ThingCard from "../../shared/ThingCard";
import LoadingIndicator from "../../shared/LoadingIndicator";
import { ThingTabs } from "../../constants";
import ThingIsDirtyCheck from "../../shared/ThingIsDirtyCheck";

export const InProgressThingsList = () => {
    const dispatch = useDispatch();
    const currentAccount = useSelector((state) => state.account.currentAccount);
    const activeThingId = useSelector((state) => state.thing.activeThingId);
    const thingList = useSelector((state) => state.thinglists.inProgressThings);
    const loading = useSelector(
        (state) => state.thinglists.inProgressThingsLoading
    );
    const error = useSelector(
        (state) => state.thinglists.inProgressThingsError
    );
    const thingRefs =
        thingList &&
        thingList.listData.reduce((tr, thing) => {
            tr[thing.thingId] = React.createRef();
            return tr;
        }, {});

    useEffect(() => {
        if (
            thingRefs &&
            activeThingId &&
            thingRefs[activeThingId] &&
            thingRefs[activeThingId].current
        ) {
            thingRefs[activeThingId].current.scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
        }
    }, [activeThingId, thingRefs]);

    useEffect(() => {
        if (!currentAccount) {
            return; // Can't load lists until currentAccount is populated
        }

        dispatch(getInProgressThingsListDispatchable());
    }, [dispatch, currentAccount]);

    const handleSetActiveThing = (thingId) => {
        dispatch(
            clearSearchAndRedirectToThing(thingId, ThingTabs.IN_PROGRESS_THINGS)
        );
    };

    const renderItem = (thing) => {
        let active = activeThingId === thing.thingId;
        return (
            <ThingIsDirtyCheck>
                <List.Item
                    key={thing.thingId}
                    className={classNames("tt-list-item", {
                        "tt-list-item-active": active,
                        "tt-hover": !active,
                    })}
                    onClick={() => handleSetActiveThing(thing.thingId)}
                >
                    <div
                        ref={thingRefs[thing.thingId]}
                        className="tt-list-item-scroll-container"
                    >
                        <ThingCard thing={thing} />
                    </div>
                </List.Item>
            </ThingIsDirtyCheck>
        );
    };

    if (error) {
        return <MessageError error={error} />;
    }
    if (loading || !thingList) {
        return <LoadingIndicator />;
    }
    if (thingList.listData.length === 0) {
        return (
            <div className="tt-empty-list">
                You do not have any in progress things
            </div>
        );
    }
    return (
        <List
            dataSource={thingList.listData}
            renderItem={(thing) => renderItem(thing)}
        />
    );
};

export default InProgressThingsList;
