import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";

import { Form, Input, Button, Select, Spin, Row, Col } from "antd";

import Axios from "axios";
//import debounce from 'lodash.debounce';

import {
    APIEndpoints,
    Application,
    Routes,
    SignupCodeStatuses,
} from "../../constants";
import {
    showNotificationSuccess,
    showNotificationError,
    getUserSignupCodeAsync,
    getSignupCodeStatusAsync,
} from "../../functions";
import { ajaxPostAsync2 } from "../../redux/actions/ajaxActions";
import { Redirect } from "react-router-dom";
import {
    getMyAccountsDispatchable,
    setCurrentAccountDispatchable,
} from "../../redux/actions/accountActions";
import store from "../../redux/store";
import wrapUserAccountPage from "../../shared/UserAccountPage";

const signupCodeValidator = (rule, value, callback) => {
    return new Promise(async (resolve, reject) => {
        const signupCode = value;

        if (!signupCode || !signupCode.trim()) {
            resolve(); // This is an invalid sign up code, but it is null/empty so we don't need to check it. Other validators will handle the required aspect of the field (if applicable)
        }

        const status = await getSignupCodeStatusAsync(signupCode);
        const statusString = status.statusString;
        if (statusString === SignupCodeStatuses.ValidNotUsed) {
            resolve();
            return;
        }
        if (statusString === SignupCodeStatuses.ValidUsed) {
            reject(`Sign up code ${signupCode} is not valid.`);
            return;
        }

        // Else is invalid
        reject(`Sign up code ${signupCode} is not valid.`);
    });
};

const NewAccountContainer = wrapUserAccountPage(
    {
        redirectToNewAccountIfNoUserAccounts: false,
        attemptCookieAccountDeductionIfNoSelectedAccount: true,
        redirectToAccountSelectIfNoSelectedAccount: true,
    },
    (props) => {
        const signupCodeFromURL = props?.match?.params?.signupCode; // Get the signupCode from the URL

        const { Option } = Select;

        const dispatch = useDispatch();

        const [form] = Form.useForm();

        const [isSubmittingForm, setIsSubmittingForm] = useState(false);

        const emailAddress = useSelector((state) => state.auth.userName);
        const countries = useSelector((state) => state.sharedLists.countries);
        const languages = useSelector((state) => state.sharedLists.languages);
        const currencies = useSelector((state) => state.sharedLists.currencies);
        const timezones = useSelector((state) => state.sharedLists.timeZones);

        const [countryCode, setCountryCode] = useState("");
        const [languageCode, setLanguageCode] = useState("");
        const [timeZoneId, setTimeZoneId] = useState("");

        const notificationKey = "NK_CreateAccount";

        const userId = useSelector((state) => state.auth.userId);
        const [userSignupCode, setUserSignupCode] = useState(
            signupCodeFromURL ?? null
        );
        const [isCheckingSignupCode, setIsCheckingSignupCode] = useState(false);

        const [redirectToDashboard, setRedirectToDashboard] = useState(false);

        // Effect to redirect to the Dashboard if the user already has an account
        const isNewAccount = useSelector((state) => state.account.newAccount);
        const doesAccountStillNeedToLoad = useSelector(
            (state) => state.account.accountLoad
        );
        useEffect(() => {
            if (!doesAccountStillNeedToLoad && !isNewAccount) {
                dispatch(push(Routes.DASHBOARD));
            }
        }, [dispatch, doesAccountStillNeedToLoad, isNewAccount]);

        // Effect to get status of signup code on component load
        useEffect(() => {
            // Need a method here as useEffect can't be async
            const checkSignupCode = async () => {
                if (!!userSignupCode) {
                    return; // Don't fetch the sign up code as there is already one. (Probably supplied from the url)
                }

                try {
                    setIsCheckingSignupCode(true);
                    let userSignupCode = await getUserSignupCodeAsync(userId);
                    // console.log("User Signup Code:", userSignupCode);
                    setUserSignupCode(userSignupCode);
                } catch (ex) {
                    console.log(ex);
                    showNotificationError(
                        notificationKey,
                        "Failed to get sign up code status!",
                        null
                    );
                } finally {
                    setIsCheckingSignupCode(false);
                }
            };
            checkSignupCode();
        }, [userId, userSignupCode]);

        // Effect to update the signup code field when the signup code changes
        useEffect(() => {
            if (form) {
                if (userSignupCode !== null) {
                    // Set the form field value
                    form.setFieldsValue({ signupCode: userSignupCode });
                }
            }
        }, [userSignupCode, form]);

        // Effect to get the user's location
        useEffect(() => {
            const getGeoInfo = () => {
                Axios.get("https://ipapi.co/json/")
                    .then((response) => {
                        console.info("ipapi.co response", response.data);
                        setCountryCode(
                            getCountryCode(response.data.country_code)
                        );
                        if (response.data.languages.indexOf(",") > 0) {
                            setLanguageCode(
                                getLanguageCode(
                                    response.data.languages.split(",")[0]
                                )
                            );
                        } else {
                            setLanguageCode(
                                getLanguageCode(navigator.language)
                            );
                        }
                        setTimeZoneId(
                            getTimeZoneId(
                                response.data.utc_offset,
                                response.data.timezone
                            )
                        );
                        form.setFieldsValue({ countryCode: countryCode });
                        form.setFieldsValue({ languageCode: languageCode });
                        form.setFieldsValue({ timeZoneId: timeZoneId });
                        if (countryCode === "CA") {
                            form.setFieldsValue({ currencyCode: "CAD" });
                        } else if (countryCode === "GB") {
                            form.setFieldsValue({ currencyCode: "GBP" });
                        } else {
                            form.setFieldsValue({ currencyCode: "USD" });
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            };

            const getCountryCode = (countryCode) => {
                let validCode = "";
                if (countries) {
                    for (let index = 0; index < countries.length; index++) {
                        if (
                            countries[index].code.toLowerCase() ===
                            countryCode.toLowerCase()
                        ) {
                            validCode = countryCode;
                            break;
                        }
                    }
                }
                return validCode;
            };

            const getLanguageCode = (languageCode) => {
                let validCode = "";
                if (languages) {
                    for (let index = 0; index < languages.length; index++) {
                        if (
                            languages[index].languageCode.toLowerCase() ===
                            languageCode.toLowerCase()
                        ) {
                            validCode = languageCode;
                            break;
                        }
                    }
                }
                return validCode;
            };

            const getTimeZoneId = (utcOffset, timezone) => {
                let timeZoneCode = "";
                if (timezones) {
                    let offset = `(UTC${utcOffset.substr(
                        0,
                        3
                    )}:${utcOffset.substr(3, 2)}) `;
                    for (let index = 0; index < timezones.length; index++) {
                        if (timezones[index].displayName.startsWith(offset)) {
                            timeZoneCode = timezones[index].timeZoneId;
                            break;
                        }
                    }
                }
                return timeZoneCode;
            };

            getGeoInfo();
            form.setFieldsValue({ emailAddress: emailAddress });
        }, [
            form,
            emailAddress,
            dispatch,
            countryCode,
            languageCode,
            countries,
            languages,
            timeZoneId,
            timezones,
        ]);

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
        };
        const tailLayout = {
            wrapperCol: { offset: 0, span: 16 },
        };

        const submitForm = (values) => {
            dispatch(async (dispatchAsync) => {
                try {
                    setIsSubmittingForm(true);
                    let response = await ajaxPostAsync2(
                        APIEndpoints.Account + "NewAccount",
                        values
                    );
                    let newAccountInfo = response.data;
                    showNotificationSuccess(
                        notificationKey,
                        "Account Created!",
                        null
                    );

                    // Update the my accounts list
                    await dispatchAsync(getMyAccountsDispatchable());

                    // Select the newly created account
                    let state = store.getState();
                    let account = state.account.myAccounts.data.accounts.find(
                        (a) => (a.accountId = newAccountInfo.accountId)
                    );
                    await dispatchAsync(setCurrentAccountDispatchable(account));

                    setRedirectToDashboard(true);
                } catch (error) {
                    console.error(error);
                    showNotificationError(
                        notificationKey,
                        "Account creation failed! Please try again."
                    );
                } finally {
                    setIsSubmittingForm(false);
                }
            });
        };

        if (redirectToDashboard) {
            return <Redirect to={Routes.DASHBOARD} />;
        }

        if (isSubmittingForm || isCheckingSignupCode) {
            return <Spin />;
        }

        return (
            <div className="container-fluid">
                <Row>
                    <Col offset={6} className="col-xs-8 col-md-8 col-offset-8">
                        <Form
                            {...layout}
                            name="newAccountForm"
                            form={form}
                            onFinish={submitForm}
                            layout="vertical"
                            hideRequiredMark
                            scrollToFirstError
                            initialValues={{
                                countryCode: countryCode,
                                languageCode: languageCode,
                                currencyCode: "USD",
                                timeZoneId: "Pacific Standard Time",
                                emailAddress: "",
                                firstName: "",
                                lastName: "",
                            }}
                        >
                            <Form.Item
                                name="emailAddress"
                                label="Email Address"
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: "Enter a valid email address",
                                        type: "email",
                                    },
                                ]}
                            >
                                <Input allowClear />
                            </Form.Item>
                            <Form.Item
                                name="firstName"
                                label="First Name"
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: "Enter your first name",
                                        pattern: /^[a-zA-Z]+$/,
                                    },
                                ]}
                            >
                                <Input allowClear autoFocus />
                            </Form.Item>
                            <Form.Item
                                name="lastName"
                                label="Last Name"
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: "Enter your last name",
                                        pattern: /^[a-zA-Z]+$/,
                                    },
                                ]}
                            >
                                <Input allowClear />
                            </Form.Item>
                            <Form.Item
                                name="countryCode"
                                label="Country"
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: "Select your country",
                                    },
                                ]}
                            >
                                <Select>
                                    {countries &&
                                        countries.map((c) => (
                                            <Option key={c.code}>
                                                {c.name}
                                            </Option>
                                        ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="languageCode"
                                label="Language"
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: "Select your default language",
                                    },
                                ]}
                            >
                                <Select>
                                    {languages &&
                                        languages.map((l) => (
                                            <Option key={l.languageCode}>
                                                {l.name}
                                            </Option>
                                        ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="currencyCode"
                                label="Default Currency"
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: "Select your default currency",
                                    },
                                ]}
                            >
                                <Select>
                                    {currencies &&
                                        currencies.map((c) => (
                                            <Option
                                                key={c.currencyCode}
                                                value={c.currencyCode}
                                            >
                                                {c.currencySymbol}
                                            </Option>
                                        ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="timeZoneId"
                                label="Time Zone"
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: "Select your time zone",
                                    },
                                ]}
                            >
                                <Select>
                                    {timezones &&
                                        timezones.map((tz) => (
                                            <Option
                                                key={tz.timeZoneId}
                                                value={tz.timeZoneId}
                                            >
                                                {tz.displayName}
                                            </Option>
                                        ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="signupCode"
                                label="Signup Code"
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: `Creating a ${Application.Name} account requires a sign up code`,
                                        whitespace: true,
                                    },
                                    {
                                        validator: signupCodeValidator,
                                    },
                                ]}
                            >
                                <Input
                                    disabled={!!userSignupCode}
                                    readOnly={!!userSignupCode}
                                    allowClear
                                    autoComplete="off"
                                />
                            </Form.Item>
                            <Form.Item
                                {...tailLayout}
                                className="tt-account-create"
                            >
                                <Button type="primary" htmlType="submit">
                                    Setup Account
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </div>
        );
    }
);

export default NewAccountContainer;
