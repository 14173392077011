import React, { useState, Fragment, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, TreeSelect, Switch, Drawer, Form, Button } from "antd";
import parse from "html-react-parser";

import ThingInput from "./ThingInput";
import { Defaults, ThingPostTypes, LastPostStatus } from "../../constants";
import ThingDetailsEditor from "./ThingDetailsEditor";
import { resetThingPost } from "../../redux/actions";
import { getAccountLists } from "../../redux/actions/accountActions";
import {
    setFocus,
    jsonClone,
    sleep,
    createLengthValidationRule,
} from "../../functions";
import ThingNewThingType from "./ThingNewThingType";
import LoadingIndicator from "../../shared/LoadingIndicator";
import {
    createTreeSelectValidator,
    createUniqueThingNameValidator,
} from "../../shared/FormItemValidators";

const isPayingForAnyRelatedThings = (relatedThings) => {
    let thingBeingPaidFor = relatedThings.find(
        (t) => t.paymentThing === true && t.thisThingPaymentThing === false
    );
    return thingBeingPaidFor !== undefined;
};

export const ThingDetailsContainer = (props) => {
    const dispatch = useDispatch();
    const form = props.form;
    const thingTypeSelect = useRef(null);
    const initialFormValues = props.initialFormValues;
    const thingTypesList = useSelector((state) => state.account.thingTypesList);
    const newThing = useSelector((state) => state.thing.newThing);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [thingDetails, setThingDetails] = useState(
        initialFormValues && initialFormValues.thingDetails
            ? initialFormValues.thingDetails
            : ""
    );
    const [editorDrawerVisible, setEditorDrawerVisible] = useState(false);
    const newThingTypeKey = "NEW_THING_TYPE";
    const lastPostType = useSelector((state) => state.thing.lastPostType);
    const lastPostStatus = useSelector((state) => state.thing.lastPostStatus);
    const lastPostData = useSelector((state) => state.thing.lastPostData);

    const activeThingId = useSelector((state) => state.thing.activeThingId);
    const activeThingRelatedThings = useSelector(
        (state) => state.thing.activeThingRelatedThings
    );
    const isPayingForThings = isPayingForAnyRelatedThings(
        activeThingRelatedThings
    );

    useEffect(() => {
        if (lastPostStatus) {
            if (
                lastPostType === ThingPostTypes.NEW_THING_TYPE &&
                lastPostStatus === LastPostStatus.OK &&
                lastPostData
            ) {
                // new  thing type was created, so reload the thing types list and set the id to the new id
                var newThingTypeId =
                    lastPostData.data.thingTypeId +
                    ":" +
                    lastPostData.data.name;
                sleep(500).then(() => {
                    form.setFieldsValue({ thingTypeId: newThingTypeId });
                    setFocus(thingTypeSelect.current);
                    props.handleValuesChange();
                });
                dispatch(getAccountLists());
                dispatch(resetThingPost());
            }
        }
    }, [dispatch, form, lastPostData, lastPostStatus, lastPostType, props]);

    // add node for new thing
    var thingTypesList2 = jsonClone(thingTypesList);
    if (thingTypesList2) {
        thingTypesList2.splice(0, 0, {
            key: newThingTypeKey,
            value: newThingTypeKey,
            title: "< Add a New Thing Type >",
            selectable: true,
            checkable: false,
            children: null,
        });
    }

    const handleEditDetailsClick = () => {
        setEditorDrawerVisible(true);
    };

    const handleEditorDrawerClose = () => {
        setEditorDrawerVisible(false);
    };

    const updateThingDetails = (content) => {
        setThingDetails(content);
        form.setFieldsValue({ thingDetails: content }); // force form isFieldsTouched to register change
        props.handleValuesChange();
    };

    const handleNewItemClick = () => {
        form.setFieldsValue({ thingTypeId: undefined });
        dispatch(resetThingPost());
        setDrawerVisible(true);
    };

    const handleDrawerClose = () => {
        setDrawerVisible(false);
        setFocus(thingTypeSelect.current);
    };

    const addThingType = (thingTypeId) => {
        if (thingTypeId === newThingTypeKey) {
            handleNewItemClick();
            return;
        }
    };

    if (!thingTypesList2) {
        return <LoadingIndicator />;
    }

    return (
        <Fragment>
            <ThingInput
                visible={true}
                tooltipkey="ThingInput_ThingImportant"
                label="Important Thing"
            >
                <Form.Item name="important" valuePropName="checked">
                    <Switch checkedChildren={"On"} unCheckedChildren={"Off"} />
                </Form.Item>
            </ThingInput>
            <ThingInput
                visible={true}
                label="Name"
                tooltipkey="ThingInput_ThingName"
            >
                <Form.Item
                    name="thingName"
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: "Required",
                            whitespace: true,
                        },
                        createLengthValidationRule(100, "Thing Name"),
                        {
                            validator: (rule, value, callback) =>
                                createUniqueThingNameValidator(
                                    value,
                                    activeThingId
                                ),
                        },
                    ]}
                >
                    <Input
                        placeholder="Enter a name for your thing"
                        allowClear
                        autoFocus={newThing}
                        autoComplete="off"
                    />
                </Form.Item>
            </ThingInput>
            <Input.Group>
                <ThingInput
                    visible={true}
                    label="Type"
                    tooltipkey={
                        isPayingForThings
                            ? "ThingInput_ThingType_PaymentTypeDisabled"
                            : "ThingInput_ThingType"
                    }
                >
                    <Form.Item
                        name="thingTypeId"
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: "Required",
                            },
                            {
                                validator: (rule, value, callback) =>
                                    createTreeSelectValidator(
                                        value,
                                        thingTypesList,
                                        "Invalid Thing Type"
                                    ),
                            },
                        ]}
                    >
                        <TreeSelect
                            name="thingTypeId"
                            showSearch
                            treeNodeFilterProp="title"
                            dropdownClassName="tt-ted-dropdown"
                            placeholder="Select thing type (type to search)"
                            treeData={thingTypesList2}
                            ref={thingTypeSelect}
                            onSelect={addThingType}
                            autoComplete="off"
                            disabled={isPayingForThings}
                        />
                    </Form.Item>
                </ThingInput>
            </Input.Group>
            <ThingInput visible={true} label="Details">
                <Form.Item name="thingDetails" className="tt-hidden">
                    <Input />
                </Form.Item>
                <div className="tt-ted-html-details">
                    {thingDetails ? (
                        <Fragment>{parse(thingDetails)}</Fragment>
                    ) : (
                        <span className="tt-ted-html-details-placeholder">
                            Click the Edit Details button below to modify the
                            content that appears here. You can use rich text to
                            describe your Thing in as much detail as you want.
                        </span>
                    )}
                </div>
                <Button onClick={handleEditDetailsClick}>Edit Details</Button>
            </ThingInput>

            <Drawer
                title="New Thing Type"
                placement="right"
                width={Defaults.DRAWER_WIDTH}
                closable={false}
                onClose={handleDrawerClose}
                visible={drawerVisible}
            >
                <ThingNewThingType onClose={handleDrawerClose} />
            </Drawer>

            <Drawer
                title="Thing Details"
                placement="right"
                width={Defaults.EDITOR_DRAWER_WIDTH}
                closable={false}
                onClose={handleEditorDrawerClose}
                visible={editorDrawerVisible}
            >
                <ThingDetailsEditor
                    title="Thing Details"
                    content={thingDetails}
                    onClose={handleEditorDrawerClose}
                    onChange={updateThingDetails}
                />
            </Drawer>
        </Fragment>
    );
};

export default ThingDetailsContainer;
