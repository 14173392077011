import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { List } from "antd";

import { clearSearchAndRedirectToThing } from "../../redux/actions";
import { getUpcomingThingsListDispatchable } from "../../redux/actions/thingListsActions";

import { MessageError } from "../../shared/MessageError";
import ThingReminderCard from "../../shared/ThingReminderCard";
import { ThingTabs } from "../../constants";
import LoadingIndicator from "../../shared/LoadingIndicator";

export const UpcomingThingsList = () => {
    const dispatch = useDispatch();
    const thingList = useSelector((state) => state.thinglists.upcomingThings);
    const thingsCount = useSelector((state) =>
        state.thinglists.upcomingThings
            ? state.thinglists.upcomingThings.count
            : 0
    );
    const loading = useSelector(
        (state) => state.thinglists.upcomingThingsLoading
    );
    const error = useSelector((state) => state.thinglists.upcomingThingsError);

    useEffect(() => {
        dispatch(getUpcomingThingsListDispatchable());
    }, [dispatch]);

    if (error) {
        return <MessageError error={error} />;
    }
    if (loading) {
        return <LoadingIndicator />;
    }
    if (thingsCount === 0) {
        return (
            <div className="tt-empty-list">
                You do not have any upcoming renewals or payments
            </div>
        );
    }
    return (
        <List
            dataSource={thingList.listData}
            renderItem={(thing) => (
                <List.Item
                    key={`${thing.thingId}-${thing.type}`}
                    onClick={() =>
                        dispatch(
                            clearSearchAndRedirectToThing(
                                thing.thingId,
                                ThingTabs.ALL_THINGS
                            )
                        )
                    }
                >
                    <ThingReminderCard thing={thing} />
                </List.Item>
            )}
        />
    );
};

export default UpcomingThingsList;
