import keyMirror from "keymirror";
import { trimAuthInfoFromEmail } from "../../functions";

export const AuthActions = keyMirror({
    AUTH_SET_MESSAGE: null,
    AUTH_SET_USERINFO: null,
});

export function setAuthMessage(isReady, message) {
    return {
        type: AuthActions.AUTH_SET_MESSAGE,
        data: { isReady: isReady, message: message },
    };
}

export function setAuthInfo(isAuthenticated, isExpired, userName, userId) {
    // remove login provider prefix from username
    userName = trimAuthInfoFromEmail(userName);
    return {
        type: AuthActions.AUTH_SET_USERINFO,
        data: {
            isReady: true,
            isAuthenticated: isAuthenticated,
            isExpired: isExpired,
            userName: userName,
            userId: userId,
        },
    };
}
