import { ActionTypes, Defaults } from "../../constants";

const initialState = {
    thingTabId: Defaults.THING_TAB,
    groupByType: Defaults.GROUP_BY_TYPE,
    searchText: "",
};

function selected(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.NEW_THING:
            return {
                ...state,
                searchText: "",
            };

        case ActionTypes.CHANGE_THING_TAB:
            return {
                ...state,
                thingTabId: action.data.thingTabId,
            };
        case ActionTypes.CHANGE_GROUP_BY_TYPE:
            return {
                ...state,
                groupByType: action.data.groupByType,
            };
        case ActionTypes.CHANGE_SEARCH_TEXT:
            return {
                ...state,
                searchText: action.data.searchText,
            };

        default:
            return state;
    }
}

export default selected;
