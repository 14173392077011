import store from "../store";

import { SharedListsActions } from "../reducers/sharedListsReducer";
import { ajaxGetAsync2 } from "./ajaxActions";
import { APIEndpoints } from "../../constants";

function setSharedListsIsLoadingDispatchable(isLoading) {
    return {
        type: SharedListsActions.SHARED_LISTS_SET_IS_LOADING,
        data: isLoading,
    };
}

function setSharedListsResultDispatchable(data, error) {
    return {
        type: SharedListsActions.SHARED_LISTS_SET_RESULT,
        data: { data, error },
    };
}

export function getSharedListsDispatchable() {
    return async (dispatchAsync) => {
        let state = store.getState();
        if (state.sharedLists.isLoading) {
            return;
        }

        try {
            await dispatchAsync(setSharedListsIsLoadingDispatchable(true));
            let result = await ajaxGetAsync2(APIEndpoints.List + "SharedLists");
            await dispatchAsync(setSharedListsResultDispatchable(result.data, null));
        } catch (error) {
            console.error("Failed to get Shared Lists with error:", error);
            await dispatchAsync(setSharedListsResultDispatchable(null, error));
        }
    };
}
