import { Button } from "antd";
import React, { Fragment } from "react";
import { Application, Routes } from "../../constants";
import authService, { AuthProviders } from "../../auth/AuthenticationService";

const LoginButtons = (props) => {
    const returnUrl = props?.returnUrl ?? Routes.DASHBOARD;
    const message =
        props?.message !== undefined
            ? props.message
            : `Login to your ${Application.Name} account using one of the following options.`;

    return (
        <div>
            {!!message && (
                <Fragment>
                    <div>{message}</div>
                    <div className="tt-spacer" />
                </Fragment>
            )}
            <div>
                <Button
                    htmlType="button"
                    type="primary"
                    onClick={() =>
                        authService.performLoginAsync(
                            AuthProviders.Microsoft,
                            returnUrl
                        )
                    }
                    className="tt-button tt-login-button"
                >
                    Login with Microsoft
                </Button>
            </div>
            <div>
                <Button
                    htmlType="button"
                    type="primary"
                    onClick={() =>
                        authService.performLoginAsync(
                            AuthProviders.Google,
                            returnUrl
                        )
                    }
                    className="tt-button tt-login-button"
                >
                    Login with Google
                </Button>
            </div>
            <div>
                <Button
                    htmlType="button"
                    type="primary"
                    onClick={() =>
                        authService.performLoginAsync(
                            AuthProviders.Amazon,
                            returnUrl
                        )
                    }
                    className="tt-button tt-login-button"
                >
                    Login with Amazon
                </Button>
            </div>
        </div>
    );
};

export default LoginButtons;
