import React, { Fragment, useState, useRef } from "react";
import JoditEditor from "../../shared/JoditEditorTemp";
//import JoditEditor from 'jodit-react';
// TODO: We need to restore JoditEditorTemp to jodit-react once https://github.com/jodit/jodit-react/issues/106 is resolved
import { Alert, Button, Row, Col } from "antd";
import { getSizeInBytes } from "../../functions";
import { Defaults } from "../../constants";

export const ThingDetailsEditor = (props) => {
    const editor = useRef(null);
    const [editorContent, setEditorContent] = useState(props.content);
    const [isContentTooLarge, setIsContentTooLarge] = useState(
        getSizeInBytes(editorContent) > Defaults.MAX_RICH_TEXT_CONTENT_SIZE
    );
    const editorConfig = {
        // all options from https://xdsoft.net/jodit/doc/
        readonly: false,
        uploader: {
            insertImageAsBase64URI: true,
        },
        toolbarButtonSize: "small",
        enter: "BR",
        defaultMode: "1",
        toolbarSticky: false,
        buttons:
            ",,,,,,,,,,,,,,,,,,,,,,,table,link,|,align,undo,redo,\n,selectall,cut,copy,paste,copyformat,|,hr,symbol,fullsize,print",
        height: window.innerHeight - 360,
        autofocus: true,
    };

    const handleClose = () => {
        if (isContentTooLarge) {
            return;
        }
        props.onChange(editorContent);
        props.onClose();
    };

    const cancelChanges = () => {
        setEditorContent(props.content);
        props.onClose();
    };

    const updateDetails = (content) => {
        setEditorContent(content);
        setIsContentTooLarge(
            getSizeInBytes(content) > Defaults.MAX_RICH_TEXT_CONTENT_SIZE
        );
    };

    return (
        <Fragment>
            <h3>{props.title}</h3>
            <div className="tt-ted-html-details-editor-warning">
                <strong className="tt-color-red">
                    PLEASE DO NOT USE THIS AREA TO STORE USERNAMES AND PASSWORDS
                </strong>
                <br />
                We do not recommend storing passwords and other account
                credentials here. If you do not have an existing system to
                securely store account credentials, passwords etc. please read
                &nbsp;
                <a
                    href="/info/passwords"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Storing Passwords Securely
                </a>{" "}
                for more information.
            </div>
            <JoditEditor
                ref={editor}
                value={editorContent}
                config={editorConfig}
                tabIndex={1} // tabIndex of textarea
                onBlur={(newContent) =>
                    updateDetails(newContent.target.innerHTML)
                } // preferred to use only this option to update the content for performance reasons
                // .target.innerHTML added due to Open issue https://github.com/jodit/jodit-react/issues/93
                className="tt-ted-html-details-editor"
            />
            <div className="tt-spacer" />
            {isContentTooLarge && (
                <Alert
                    type="error"
                    message={`Content size exceeds maximum content size of ${Defaults.MAX_RICH_TEXT_CONTENT_SIZE_DISPLAY}.`}
                />
            )}
            <div className="tt-spacer" />
            <Row>
                <Col span="9">
                    <Button
                        type="primary"
                        htmlType="button"
                        onClick={handleClose}
                        disabled={isContentTooLarge}
                    >
                        Apply Changes
                    </Button>
                    <Button
                        type="secondary"
                        htmlType="button"
                        onClick={cancelChanges}
                        className="tt-button"
                    >
                        Discard Changes
                    </Button>
                </Col>
                <Col span="15" className="tt-ted-html-editor-alert">
                    <span>
                        You must click the Save button at the bottom of the page{" "}
                        <br />
                        after closing this editor to save your changes.
                    </span>
                </Col>
            </Row>
        </Fragment>
    );
};

export default ThingDetailsEditor;
