import React from "react";
import { Card } from "antd";

import ImportantThingsList from "./ImportantThingsList";
import ImportantMessage from "./ImportantMessage";
import TitleBar from "../../shared/TitleBar";
import wrapUserAccountPage from "../../shared/UserAccountPage";

export const ImportantThingsContainer = wrapUserAccountPage(
    {
        redirectToNewAccountIfNoUserAccounts: true,
        attemptCookieAccountDeductionIfNoSelectedAccount: true,
        redirectToAccountSelectIfNoSelectedAccount: true,
    },
    () => {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xs-12 col-md-12">
                        <Card className="tt-hidden">
                            This page displays Important Things that someone
                            inheriting your account will need to know. You can
                            add Important Things to this page by clicking the
                            Important icon when you are on the Thing page. You
                            can change the order of the Important Things by
                            dragging them up or down as required. You can also
                            customize the message that appears below to guide
                            your inheritor in the right direction. Click here to
                            hide this message.
                        </Card>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-6 col-md-6 tt-message-important">
                        <ImportantMessage />
                    </div>
                    <div className="col-xs-6 col-md-6">
                        <TitleBar
                            tooltipkey="Header_ImportantThings"
                            color="#dc3545"
                        />
                        <ImportantThingsList />
                    </div>
                </div>
            </div>
        );
    }
);

export default ImportantThingsContainer;
