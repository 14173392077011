import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    TreeSelect,
    Input,
    Row,
    Col,
    Drawer,
    Form,
    Popconfirm,
    Popover,
} from "antd";
import parse from "html-react-parser";

import {
    resetThingPost,
    updateRelatedThings,
} from "../../redux/actions";
import {
    Defaults,
    ThingPostTypes,
    LastPostStatus,
    ThingUpdateActions,
} from "../../constants";
import {
    compareValues,
    jsonClone,
    setFocus,
    showNotificationWarning,
    thingRoute,
} from "../../functions";

import ThingInput from "./ThingInput";
import ThingNewRelatedThing from "./ThingNewRelatedThing";
import { DeleteFilled, DollarCircleTwoTone } from "@ant-design/icons";
import { isValidTreeSelection } from "../../shared/FormItemValidators";
import ThingTitleInfo from "../../shared/ThingTitleInfo";
import { reloadThingListsDispatchable } from "../../redux/actions/thingListsActions";

export const ThingRelatedThings = (props) => {
    const dispatch = useDispatch();
    const form = props.form;
    const thing = props.thing;
    const [drawerVisible, setDrawerVisible] = useState(false);
    const relatedThingsTreeSelect = useRef(null);
    const relatedThingsSelectList = useSelector(
        (state) => state.thinglists.relatedThings
    );
    const lastPostType = useSelector((state) => state.thing.lastPostType);
    const lastPostStatus = useSelector((state) => state.thing.lastPostStatus);
    const lastPostData = useSelector((state) => state.thing.lastPostData);
    const activeThingRelatedThings = useSelector(
        (state) => state.thing.activeThingRelatedThings
    );
    const notificationKey = "NK_RelatedThing";
    const newThingNodeKey = "NEW_RELATED_THING";

    // sort list of related things
    var relatedThings = jsonClone(activeThingRelatedThings);
    if (relatedThings) {
        relatedThings.forEach((p) => {
            p.sort = p.name + ": " + p.thingTypeName;
        });
        relatedThings.sort(compareValues("sort", "asc"));
    }

    useEffect(() => {
        if (lastPostStatus) {
            if (
                lastPostType === ThingPostTypes.NEW_RELATED_THING &&
                lastPostStatus === LastPostStatus.OK &&
                lastPostData
            ) {
                // new related thing was created, so add it to related things list
                var newList2 = jsonClone(activeThingRelatedThings);
                newList2.push({
                    thingRelatedThingId: null,
                    relatedThingId: lastPostData.data.thingId,
                    name: lastPostData.data.thingName,
                    thingTypeId: lastPostData.data.thingTypeId,
                    thingTypeName: lastPostData.data.thingTypeName,
                    existing: false,
                    action: ThingUpdateActions.NEW,
                });
                dispatch(resetThingPost());
                dispatch(updateRelatedThings(cleanupList(newList2)));
                dispatch(reloadThingListsDispatchable());
                form.setFieldsValue({ relatedThingId: null }); // force form isFieldsTouched to register change
                props.handleValuesChange();
            }
        }
    }, [
        activeThingRelatedThings,
        dispatch,
        form,
        lastPostData,
        lastPostStatus,
        lastPostType,
        props,
    ]);

    // trim tree select data to remove current thing to stop self-relationship
    var relatedThingsSelectListTrimmed = jsonClone(relatedThingsSelectList);
    var foundIndex = -1;
    if (relatedThingsSelectListTrimmed && thing) {
        for (let type of relatedThingsSelectListTrimmed) {
            var index = -1;
            for (let childThing of type.children) {
                index++;
                if (childThing.key.startsWith(thing.thingId)) {
                    childThing.selectable = false;
                    foundIndex = index;
                    break;
                }
            }
            if (foundIndex > -1) {
                delete type.children[foundIndex];
                break;
            }
        }
    }

    // add node for new thing
    if (relatedThingsSelectListTrimmed) {
        relatedThingsSelectListTrimmed.splice(0, 0, {
            checkable: false,
            children: null,
            key: newThingNodeKey,
            selectable: true,
            title: "< Add a New Related Thing >",
            value: newThingNodeKey,
        });
    }

    const findRelatedThingByKey = (relatedThingId) => {
        let foundThing = null;
        if (relatedThingsSelectList) {
            for (let type of relatedThingsSelectList) {
                for (let childThing of type.children) {
                    if (childThing.key === relatedThingId) {
                        foundThing = childThing;
                        break;
                    }
                }
                if (foundThing !== null) {
                    break;
                }
            }
        }
        return foundThing;
    };

    const findRelatedThingParentByKey = (relatedThingId) => {
        let foundType = null;
        if (relatedThingsSelectList) {
            for (let type of relatedThingsSelectList) {
                for (let childThing of type.children) {
                    if (childThing.key === relatedThingId) {
                        foundType = type;
                        break;
                    }
                }
                if (foundType !== null) {
                    break;
                }
            }
        }
        return foundType;
    };

    // remove any non-existing items which have been marked for deletion
    const cleanupList = (list) => {
        var newList = [];
        list.forEach((t) => {
            if (t.existing || t.action !== ThingUpdateActions.DELETE) {
                newList.push(t);
            }
        });
        return newList;
    };

    const deleteThing = (relatedThingId) => {
        var newList = jsonClone(activeThingRelatedThings);
        newList.forEach((t) => {
            if (t.relatedThingId === relatedThingId && !t.paymentThing) {
                t.action = ThingUpdateActions.DELETE;
            }
        });
        dispatch(updateRelatedThings(cleanupList(newList)));
        form.setFieldsValue({ relatedThingId: null }); // force form isFieldsTouched to register change
        props.handleValuesChange();
    };

    const addThing = (relatedThingIdKey) => {
        if (relatedThingIdKey === newThingNodeKey) {
            handleNewItemClick();
            return;
        }

        if (!isValidTreeSelection(relatedThingIdKey, relatedThingsSelectList)) {
            // The thing selected was valid; add it!
            return;
        }

        var relatedThingId = relatedThingIdKey.substring(
            0,
            relatedThingIdKey.indexOf(":")
        );
        // don't allow relationship back to self
        if (thing && thing.thingId === relatedThingId) {
            showNotificationWarning(
                notificationKey,
                "You cannot relate a thing to itself",
                null
            );
            form.setFieldsValue({ relatedThingId: null }); // force form isFieldsTouched to register change
            return;
        }
        if (
            activeThingRelatedThings.some(
                (p) => p.relatedThingId === relatedThingId
            )
        ) {
            var newList1 = jsonClone(activeThingRelatedThings);
            newList1.forEach((t) => {
                if (t.relatedThingId === relatedThingId) {
                    t.action = t.existing
                        ? ThingUpdateActions.NOCHANGE
                        : ThingUpdateActions.NEW;
                }
            });
            dispatch(updateRelatedThings(cleanupList(newList1)));
        } else {
            var newList2 = jsonClone(activeThingRelatedThings);
            var selectedThing = findRelatedThingByKey(relatedThingIdKey);
            var selectedType = findRelatedThingParentByKey(relatedThingIdKey);
            newList2.push({
                thingRelatedThingId: null,
                relatedThingId: relatedThingId,
                name: selectedThing.title,
                thingTypeId: selectedType.key,
                thingTypeName: selectedType.title,
                existing: false,
                action: ThingUpdateActions.NEW,
            });
            dispatch(updateRelatedThings(cleanupList(newList2)));
        }
        form.setFieldsValue({ relatedThingId: undefined });
        props.handleValuesChange();
    };

    const handleNewItemClick = () => {
        form.setFieldsValue({ relatedThingId: undefined });
        dispatch(resetThingPost());
        setDrawerVisible(true);
    };

    const handleDrawerClose = () => {
        setDrawerVisible(false);
        setFocus(relatedThingsTreeSelect.current);
    };

    const paymentThingPopoverContent = (thing, relatedThing) => {
        var content = "";
        if (thing && thing.name) {
            if (relatedThing.thisThingPaymentThing) {
                content =
                    "<strong>" +
                    relatedThing.thingTypeName +
                    ": " +
                    relatedThing.name +
                    "</strong> has been set as the payment method for <strong>" +
                    thing.thingType +
                    ": " +
                    thing.name +
                    "</strong>.<br/><br/>To remove this relationship you must change the Payment Method in the Payment Details section below.";
            } else {
                content =
                    "<strong>" +
                    thing.thingType +
                    ": " +
                    thing.name +
                    "</strong> is used as payment method for <strong>" +
                    relatedThing.thingTypeName +
                    ": " +
                    relatedThing.name +
                    "</strong>.<br/><br/>To remove this relationship you must go to <strong>" +
                    relatedThing.thingTypeName +
                    ": " +
                    relatedThing.name +
                    "</strong> and change the Payment Method in the Payment Details section.";
            }
        }
        return content;
    };

    return (
        <div className="tt-ted-panel-container">
            {relatedThings &&
                relatedThings.map((t) =>
                    t.action === ThingUpdateActions.DELETE ? null : (
                        <Row
                            className="tt-ted-list-item"
                            key={t.thingRelatedThingId + ":" + t.relatedThingId}
                        >
                            <Col flex="1 1" className="tt-no-overflow">
                                <ThingTitleInfo
                                    thing={{ ...t, thingType: t.thingTypeName }}
                                    hideInProgressIcon={true}
                                    hideImportantIcon={true}
                                    titleLinkUrl={thingRoute(t.relatedThingId)}
                                />
                            </Col>
                            <Col flex="0 1" className="tt-ted-list-item-icon">
                                {t.paymentThing ? (
                                    <Popover
                                        trigger="hover"
                                        placement="rightTop"
                                        title={
                                            t.thisThingPaymentThing
                                                ? "Paid By"
                                                : "Used to Pay"
                                        }
                                        content={parse(
                                            paymentThingPopoverContent(thing, t)
                                        )}
                                        mouseEnterDelay={
                                            Defaults.POPOVER_MOUSE_ENTER_DELAY_FAST
                                        }
                                        mouseLeaveDelay={
                                            Defaults.POPOVER_MOUSE_LEAVE_DELAY
                                        }
                                    >
                                        {t.thisThingPaymentThing ? (
                                            <DollarCircleTwoTone twoToneColor="#52c41a" />
                                        ) : (
                                            <DollarCircleTwoTone />
                                        )}
                                    </Popover>
                                ) : (
                                    <Popover
                                        trigger="hover"
                                        placement="rightTop"
                                        title="Delete Related Thing"
                                        content="Click here to delete this related thing"
                                        mouseEnterDelay={
                                            Defaults.POPOVER_MOUSE_ENTER_DELAY_FAST
                                        }
                                        mouseLeaveDelay={
                                            Defaults.POPOVER_MOUSE_LEAVE_DELAY
                                        }
                                    >
                                        <Popconfirm
                                            title="Are you sure you want to delete this thing?"
                                            okText="Yes"
                                            cancelText="No"
                                            onConfirm={(e) =>
                                                deleteThing(t.relatedThingId)
                                            }
                                        >
                                            <DeleteFilled />
                                        </Popconfirm>
                                    </Popover>
                                )}
                            </Col>
                        </Row>
                    )
                )}
            <div className="tt-spacer" />
            <Input.Group>
                <ThingInput
                    visible={true}
                    label="Select Related Thing"
                    tooltipkey="ThingInput_ReleatedThingSelect"
                >
                    <Form.Item name="relatedThingId">
                        <TreeSelect
                            name="relatedThingId"
                            showSearch
                            treeNodeFilterProp="title"
                            dropdownClassName="tt-ted-dropdown"
                            placeholder="Select related thing (type to search)"
                            treeData={relatedThingsSelectListTrimmed}
                            ref={relatedThingsTreeSelect}
                            onSelect={addThing}
                            autoComplete="off"
                        />
                    </Form.Item>
                </ThingInput>
            </Input.Group>

            <Drawer
                title="New Thing"
                placement="right"
                width={Defaults.DRAWER_WIDTH}
                closable={false}
                onClose={handleDrawerClose}
                visible={drawerVisible}
            >
                <ThingNewRelatedThing onClose={handleDrawerClose} />
            </Drawer>
        </div>
    );
};

export default ThingRelatedThings;
