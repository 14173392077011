import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

import { Routes } from "../constants";
import { QueryParameterNames } from "./AuthRoutes";

/** Component to render routes if authenticated, else trigger a login and redirect back once authenticated. */
export const AuthorizedRoute = (props) => {
    const isAuthenticated = useSelector((state) => state.auth.authenticated);
    const isReady = useSelector((state) => state.auth.isReady);

    if (!isReady) return null;

    const { component: Component, render, ...rest } = props;
    return (
        <Route
            {...rest}
            render={(props) => {
                if (isAuthenticated) {
                    if (!!Component) {
                        return <Component {...props} />;
                    } else {
                        return render(props);
                    }
                } else {
                    const redirectUrl = `${Routes.LOGIN}?${
                        QueryParameterNames.ReturnUrl
                    }=${encodeURIComponent(window.location.href)}`;
                    return <Redirect to={redirectUrl} />;
                }
            }}
        />
    );
};

export default AuthorizedRoute;
