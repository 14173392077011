import React from "react";

import ThingTitleInfo from "./ThingTitleInfo";
import ThingDueInfo from "./ThingDueInfo";

export const ThingReminderCard = (props) => {
    return (
        <div className="tt-reminder-card">
            <ThingTitleInfo thing={props.thing} />
            <ThingDueInfo thing={props.thing} />
        </div>
    );
};

export default ThingReminderCard;
