import keyMirror from "keymirror";

import { ajaxGetAsync, ajaxPostAsync } from "./ajaxActions";

import { APIEndpoints, ActionTypes, ThingPostTypes } from "../../constants";

export const ThingActions = keyMirror({
    THING_SET_IS_SAVING: null,
    THING_SET_IS_DIRTY: null,

    // Get Thing
    GET_THING_BEGIN: null,
    GET_THING_END_OK: null,
    GET_THING_END_FAIL: null,
    GET_THING_END_IGNORE: null,
});

function setThingPostType(type) {
    return {
        type: ActionTypes.POST_THING_TYPE,
        data: type,
    };
}

export function setThingIsSaving(isSaving) {
    return {
        type: ThingActions.THING_SET_IS_SAVING,
        data: isSaving,
    };
}

export function setThingIsDirty(isDirty) {
    return {
        type: ThingActions.THING_SET_IS_DIRTY,
        data: isDirty,
    };
}

export function getThing(thingId) {
    if (thingId) {
        return async (dispatchAsync) => {
            await ajaxGetAsync(
                APIEndpoints.Thing +
                    "ThingDetail/" +
                    encodeURIComponent(thingId),
                ThingActions.GET_THING_BEGIN,
                ThingActions.GET_THING_END_OK,
                ThingActions.GET_THING_END_FAIL,
                dispatchAsync
            );
        };
    }

    return {
        type: ThingActions.GET_THING_END_IGNORE,
    };
}

export function updateThing(postData) {
    return async (dispatchAsync) => {
        await dispatchAsync(setThingIsSaving(true));
        await dispatchAsync(setThingPostType(ThingPostTypes.UPDATE_THING));
        await ajaxPostAsync(
            APIEndpoints.Thing + "Update",
            ActionTypes.POST_THING,
            ActionTypes.POST_THING_OK,
            ActionTypes.POST_THING_FAIL,
            dispatchAsync,
            postData
        );
        await dispatchAsync(setThingIsSaving(false));
    };
}

export function deleteThing(thingId) {
    return async (dispatchAsync) => {
        await dispatchAsync(setThingIsSaving(true));
        await dispatchAsync(setThingPostType(ThingPostTypes.DELETE_THING));
        var postData = {
            thingId: thingId,
        };
        await ajaxPostAsync(
            APIEndpoints.Thing + "Delete",
            ActionTypes.POST_THING,
            ActionTypes.POST_THING_OK,
            ActionTypes.POST_THING_FAIL,
            dispatchAsync,
            postData
        );
        await dispatchAsync(setThingIsSaving(false));
    };
}
