import React from "react";
import { useSelector } from "react-redux";
import { jsonClone, defaultDateFormat } from "../../functions";
import { Row, Col } from "antd";
import moment from "moment";

export const ThingReminderSchedule = (props) => {
    const reminderDate = props.date;
    const scheduleId = props.reminderScheduleId;
    const detailList = useSelector(
        (state) => state.account.reminderScheduleDetailList
    );
    const countryCode = useSelector(
        (state) => state.account.accountCountryCode
    );

    var scheduleList = jsonClone(detailList);
    if (scheduleList) {
        scheduleList = scheduleList.filter(
            (d) => d.reminderScheduleId === scheduleId
        );
    }

    const getWord = (count, singular, plural) => {
        if (count === 1) {
            return singular;
        }
        return plural;
    };

    const getSentence = (count, singular, plural) => {
        if (count === 0) {
            return "On the due date";
        }
        return count + " " + getWord(count, singular, plural) + " before";
    };

    const renderItem = (item, count) => {
        var reminder = "";
        var date;
        if (item.monthsBefore < 0) {
            reminder = getSentence(item.monthsBefore * -1, "month", "months");
            date = moment(reminderDate).subtract(
                item.monthsBefore * -1,
                "months"
            );
        } else if (item.weeksBefore < 0) {
            reminder = getSentence(item.weeksBefore * -1, "week", "weeks");
            date = moment(reminderDate).subtract(
                item.weeksBefore * -1,
                "weeks"
            );
        } else if (item.daysBefore < 0) {
            reminder = getSentence(item.daysBefore * -1, "day", "days");
            date = moment(reminderDate).subtract(item.daysBefore * -1, "days");
        } else {
            reminder = getSentence(item.daysBefore, "day", "days");
            date = moment(reminderDate);
        }

        return (
            <Row
                key={item.reminderScheduleItemId}
                className="tt-ted-schedule-item"
            >
                <Col span={4}>Reminder {count}</Col>
                <Col span={6}>{reminder}</Col>
                <Col span={2}>{date.format("ddd")}</Col>
                <Col span={6}>
                    {date.format(defaultDateFormat(countryCode))}
                </Col>
            </Row>
        );
    };

    if (
        !reminderDate ||
        !scheduleId ||
        (scheduleList && scheduleList.length === 0)
    ) {
        return null;
    }

    var count = 1;
    return (
        <div className="tt-ted-schedule-container1">
            <div className="tt-ted-schedule-container2">
                {scheduleList.map((d) => renderItem(d, count++))}
            </div>
        </div>
    );
};

export default ThingReminderSchedule;
