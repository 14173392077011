import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { List, Row, Col, Tag, Tooltip } from "antd";

import { getPaymentSummaryListDispatchable } from "../../redux/actions/thingListsActions";

import { MessageError } from "../../shared/MessageError";
import LoadingIndicator from "../../shared/LoadingIndicator";
import { formatWithCommas } from "../../functions";

export const PaymentSummaryList = () => {
    const dispatch = useDispatch();
    const paymentList = useSelector((state) => state.thinglists.paymentSummary);
    const paymentCount = useSelector((state) =>
        state.thinglists.paymentSummary
            ? state.thinglists.paymentSummary.count
            : 0
    );
    const loading = useSelector(
        (state) => state.thinglists.paymentSummaryLoading
    );
    const error = useSelector((state) => state.thinglists.paymentSummaryError);
    const currencies = useSelector((state) => state.sharedLists.currencies);

    useEffect(() => {
        dispatch(getPaymentSummaryListDispatchable());
    }, [dispatch]);

    const currencySymbol = (currencyCode) => {
        let symbol = currencyCode;
        if (currencies) {
            for (let currency of currencies) {
                if (currency.currencyCode === currencyCode) {
                    symbol = currency.currencySymbol;
                    break;
                }
            }
        }
        return symbol;
    };

    const renderAmount = (payment) => {
        let fmtAmount = "";
        let prefix = "";
        fmtAmount =
            currencySymbol(payment.currencyCode) +
            formatWithCommas(payment.amount);
        if (payment.approximate) {
            prefix = "approx. ";
        }
        return (
            <span>
                {prefix}
                {fmtAmount}
            </span>
        );
    };

    if (error) {
        return <MessageError error={error} />;
    }
    if (loading || !paymentList) {
        return <LoadingIndicator />;
    }
    if (paymentCount === 0) {
        return (
            <div className="tt-empty-list">
                You do not have any upcoming payments
            </div>
        );
    }
    return (
        <List
            dataSource={paymentList.listData}
            renderItem={(payment) => (
                <List.Item
                    key={payment.paymentThingId + "-" + payment.currencyCode}
                >
                    <div className="tt-payment-summary">
                        <Row>
                            <Col span={24}>
                                <Tooltip title={payment.name} placement="left">
                                    <span className="tt-payment-title-info">
                                        <strong>{payment.name}</strong>
                                    </span>
                                </Tooltip>
                                <Tag className="float-right tt-payment-summary-type-pill">
                                    {payment.thingType}
                                </Tag>
                            </Col>
                            <Col span={24}>{renderAmount(payment)}</Col>
                        </Row>
                    </div>
                </List.Item>
            )}
        />
    );
};

export default PaymentSummaryList;
