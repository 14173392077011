import React, { useEffect, useRef } from "react";
import { Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { getInitialData, setActiveThingTab } from "./redux/actions";
import { setAuthInfo, setAuthMessage } from "./redux/actions/authActions";
import authService from "./auth/AuthenticationService";
import AuthRoutes from "./auth/AuthRoutes";

import { Routes, ThingTabs } from "./constants";
import NewAccountContainer from "./routes/newaccount";
import { HomeContainer } from "./routes/home";
import { DashboardContainer } from "./routes/dashboard";
import { ThingsContainer } from "./routes/things";
import { ImportantThingsContainer } from "./routes/important";
import { PeopleContainer } from "./routes/people";
import AccountSelectContainer from "./routes/accountselect";
// Disabling these routes as we currently aren't using them
// import { TourContainer } from "./routes/tour";
// import { ListsContainer } from "./routes/lists";
// import { SubscriptionContainer } from "./routes/subscription";
// import ReminderSchedulesContainer from "./routes/reminderschedules";

import CheckinContainer from "./routes/checkin";

import InviteContainer from "./routes/invite/InviteContainer";
import InviteAcceptContainer from "./routes/invite/InviteAcceptContainer";
import InviteAcceptedContainer from "./routes/invite/InviteAcceptedContainer";

import PageNotFoundContainer from "./routes/pagenotfound";

import "bootstrap/dist/css/bootstrap.min.css";
import "antd/dist/antd.css";
import "./styles/account.css";
import "./styles/branding.css";
import "./styles/buttonBar.css";
import "./styles/common.css";
import "./styles/lists.css";
import "./styles/messages.css";
import "./styles/navbar.css";
import "./styles/people.css";
import "./styles/pills.css";
import "./styles/popovers.css";
import "./styles/tabs.css";
import "./styles/thingEditor.css";
import "./styles/login.css";

import SignupContainer from "./routes/signup/index";
import { getSharedListsDispatchable } from "./redux/actions/sharedListsActions";
import { clearCookiesOnLogout } from "./cookies/CookieService";

import AppRoute from "./AppRoute";
import AppAuthorizedRoute from "./AppAuthorizedRoute";
import DefaultLayout from "./shared/layouts/DefaultLayout";
import NoAccountLayout from "./shared/layouts/NoAccountLayout";

export const App = () => {
    const dispatch = useDispatch();
    const authSubscriptionId = useRef();

    useEffect(() => {
        // TODO: Temporary fix to redirect users going to old URL
        //var oldURL = "https://localhost:1234";
        var oldURL = "https://app-tracked-life.azurewebsites.net";
        if (window.location.href.startsWith(oldURL)) {
            window.location = window.location.href.replace(
                oldURL,
                "https://my.tracked.life"
            );
        }
    }, []);

    // Effect to fetch the initial authentication state (may trigger a silent authentication)
    useEffect(() => {
        const fetchAuthState = async () => {
            const {
                isAuthenticated,
                isExpired,
                user,
                userId,
            } = await authService.getAuthStateAsync();

            dispatch(
                setAuthInfo(isAuthenticated, isExpired, user?.name, userId)
            );

            dispatch(getSharedListsDispatchable());
            if (isAuthenticated) {
                dispatch(getInitialData());
            }
        };

        authSubscriptionId.current = authService.subscribeToAuthStateChanges(
            () => fetchAuthState()
        );
        authService.addLogoutCallback(() => {
            clearCookiesOnLogout();
        });
        authService.initializeAuthService(() => {
            dispatch(setAuthMessage(true, null));
        });

        // returned function will be called on component unmount
        return () => {
            authService.unsubscribeFromAuthStateChanges(
                authSubscriptionId.current
            );
        };
    }, [dispatch]);

    // Effect to redirect to login if fetching account data results in a 401
    const has401Error = useSelector(
        (state) =>
            state.sharedLists.hasError &&
            state.sharedLists.error?.response?.status === 401
    );
    useEffect(() => {
        const attemptSilentLoginAsync = async () => {
            let result = await authService.performSilentLoginAsync(
                window.location.href
            ); // Redirect to login callback which should perform a silent sign in of the user, refreshing their access token
            console.log(result);
        };

        // if an error occurs accessing account details and the error is 401 (Unauthorized) then redirect to login
        if (has401Error) {
            console.log(
                "Shared Lists API call resulted in 401, so attempting silent login."
            );
            attemptSilentLoginAsync();
        }
    }, [dispatch, has401Error]);

    // Effect to default to the All Things tab if the Important Things tab is empty
    const authIsAuthenticated = useSelector(
        (state) => state.auth.authenticated
    );
    const inProgressThingsLoading = useSelector(
        (state) => state.thinglists.inProgressThingsLoading
    );
    const inProgressThings = useSelector(
        (state) => state.thinglists.inProgressThings
    );
    useEffect(() => {
        if (!authIsAuthenticated) {
            return; // Can't do anything if not yet authenticated
        }

        if (inProgressThingsLoading) {
            return; // Still loading
        }

        if (inProgressThings === null || inProgressThings === undefined) {
            return; // Error state, things didn't load
        }

        if (inProgressThings.count === 0) {
            // Since there are no in-progress things, default to the all things tab
            dispatch(setActiveThingTab(ThingTabs.ALL_THINGS));
        }
    }, [
        dispatch,
        authIsAuthenticated,
        inProgressThingsLoading,
        inProgressThings,
    ]);

    return (
        <Switch>
            <AppRoute
                exact
                path={Routes.HOME}
                layout={NoAccountLayout}
                component={HomeContainer}
            />

            {/* Checkin Routes */}
            <AppRoute
                exact
                path={Routes.CHECKIN}
                layout={NoAccountLayout}
                component={CheckinContainer}
            />
            <AppRoute
                exact
                path={Routes.CHECKIN_WITH_CODE}
                layout={NoAccountLayout}
                component={CheckinContainer}
            />

            {/* Signup Routes */}
            <AppRoute
                exact
                path={Routes.SIGNUP}
                layout={NoAccountLayout}
                component={SignupContainer}
            />
            <AppRoute
                exact
                path={Routes.SIGNUP_WITH_CODE}
                layout={NoAccountLayout}
                component={SignupContainer}
            />

            {/* Invitation Routes */}
            <AppRoute
                exact
                path={Routes.INVITE}
                layout={NoAccountLayout}
                component={InviteContainer}
            />
            <AppRoute
                exact
                path={Routes.INVITE_ACCEPT}
                layout={NoAccountLayout}
                component={InviteAcceptContainer}
            />
            <AppRoute
                exact
                path={Routes.INVITE_ACCEPTED}
                layout={NoAccountLayout}
                component={InviteAcceptedContainer}
            />

            <AppAuthorizedRoute
                exact
                path={Routes.ACCOUNT_SELECT}
                layout={NoAccountLayout}
                component={AccountSelectContainer}
            />
            <AppAuthorizedRoute
                exact
                path={Routes.NEWACCOUNT_WITH_CODE}
                layout={DefaultLayout}
                component={NewAccountContainer}
            />
            <AppAuthorizedRoute
                exact
                path={Routes.NEW_ACCOUNT}
                layout={DefaultLayout}
                component={NewAccountContainer}
            />

            <AppAuthorizedRoute
                exact
                path={Routes.DASHBOARD}
                layout={DefaultLayout}
                component={DashboardContainer}
            />
            <AppAuthorizedRoute
                exact
                path={Routes.THINGS}
                layout={DefaultLayout}
                component={ThingsContainer}
            />
            <AppAuthorizedRoute
                exact
                path={Routes.NEWTHING}
                layout={DefaultLayout}
                component={ThingsContainer}
            />
            <AppAuthorizedRoute
                path={Routes.THING}
                layout={DefaultLayout}
                component={ThingsContainer}
            />
            <AppAuthorizedRoute
                exact
                path={Routes.IMPORTANTTHINGS}
                layout={DefaultLayout}
                component={ImportantThingsContainer}
            />
            <AppAuthorizedRoute
                exact
                path={Routes.PEOPLE}
                layout={DefaultLayout}
                component={PeopleContainer}
            />
            <AppAuthorizedRoute
                exact
                path={Routes.PEOPLE_WITH_INVITE_ID}
                layout={DefaultLayout}
                component={PeopleContainer}
            />
            {/* Disabling these routes as we currently aren't using them */}
            {/* <AppAuthorizedRoute
                        exact
                        path={Routes.TOUR}
                        layout={DefaultLayout}
                        component={TourContainer}
                    /> */}
            {/* <AppAuthorizedRoute
                        exact
                        path={Routes.LISTS}
                        layout={DefaultLayout}
                        component={ListsContainer}
                    /> */}
            {/* <AppAuthorizedRoute
                        exact
                        path={Routes.SUBSCRIPTION}
                        layout={DefaultLayout}
                        component={SubscriptionContainer}
                    /> */}
            {/* <AppAuthorizedRoute
                        exact
                        path={Routes.REMINDERSCHEDULES}
                        layout={DefaultLayout}
                        component={ReminderSchedulesContainer}
                    /> */}

            {/* Authentication Routes */}
            <AppRoute
                exact
                path={Routes.LOGIN}
                layout={NoAccountLayout}
                render={AuthRoutes.renderLoginPage}
            />
            <AppRoute
                exact
                path={Routes.LOGIN_CALLBACK}
                layout={NoAccountLayout}
                render={() => AuthRoutes.renderLoginCallback(dispatch)}
            />
            <AppRoute
                exact
                path={Routes.LOGOUT}
                layout={NoAccountLayout}
                render={() => AuthRoutes.renderLogout(dispatch)}
            />
            <AppRoute
                exact
                path={Routes.LOGOUT_CALLBACK}
                layout={NoAccountLayout}
                render={() => AuthRoutes.renderLogoutCallback(dispatch)}
            />
            <AppRoute
                exact
                path={Routes.LOGIN_FAILED}
                layout={NoAccountLayout}
                render={AuthRoutes.renderLoginFailedRoute}
            />

            {/* Fallback Route */}
            <AppRoute
                layout={NoAccountLayout}
                component={PageNotFoundContainer}
            />
        </Switch>
    );
};

export default App;
