import { ImportantThingsActions } from "../actions/importantThingsActions";

const initialState = {
    importantThings: null,
    isImportantThingsLoading: false,
    importantThingsLoadError: null,

    importantMessage: null,
    isImportantMessageLoading: false,
    importantMessageLoadError: null,
};

function importantThingsReducer(state = initialState, action) {
    switch (action.type) {
        case ImportantThingsActions.GET_IMPORTANT_THINGS_LIST_BEGIN:
            return {
                ...state,
                importantThings: null,
                isImportantThingsLoading: true,
                importantThingsLoadError: null,
            };

        case ImportantThingsActions.GET_IMPORTANT_THINGS_LIST_END_OK:
            return {
                ...state,
                importantThings: action.data,
                isImportantThingsLoading: false,
                importantThingsLoadError: false,
            };
        case ImportantThingsActions.GET_IMPORTANT_THINGS_LIST_END_FAIL:
            return {
                ...state,
                isImportantThingsLoading: false,
                importantThingsLoadError: action.data,
            };

        case ImportantThingsActions.SET_IMPORTANT_THING_LIST:
            return {
                ...state,
                importantThings: {
                    count: action.data.length,
                    listData: action.data,
                },
            };

        case ImportantThingsActions.GET_IMPORTANT_MESSAGE_BEGIN:
            return {
                ...state,
                importantMessage: null,
                isImportantMessageLoading: true,
                importantMessageLoadError: null,
            };

        case ImportantThingsActions.GET_IMPORTANT_MESSAGE_END_OK:
            return {
                ...state,
                importantMessage: action.data,
                isImportantMessageLoading: false,
                importantMessageLoadError: false,
            };
        case ImportantThingsActions.GET_IMPORTANT_MESSAGE_END_FAIL:
            return {
                ...state,
                isImportantMessageLoading: false,
                importantMessageLoadError: action.data,
            };

        case ImportantThingsActions.SET_IMPORTANT_MESSAGE:
            return {
                ...state,
                importantMessage: action.data,
            };

        default:
            return state;
    }
}

export default importantThingsReducer;
