import { notification } from "antd";
import parse from "html-react-parser";

import {
    NotificationTypes,
    Routes,
    PersonTypeIds,
    APIEndpoints,
    SignupCodeStatuses,
    CheckinCodeStatuses
} from "./constants";
import moment from "moment";
import { ajaxGetAsync2 } from "./redux/actions/ajaxActions";

// function to use to sort a JSON array
// key = name of field to sort on
// order = asc|desc
// e.g. myArray.sort(compareValues('field1', 'desc'))
export function compareValues(key, order = "asc") {
    return function innerSort(a, b) {
        if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) return 0;
        const comparison = a[key].localeCompare(b[key]);
        return order === "desc" ? comparison * -1 : comparison;
    };
}

// Sleep for specified milliseconds
export function sleep(milliseconds) {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

// delay and set focus to control
export function setFocus(control, afterDelayMilliSeconds = 250) {
    if (control) {
        sleep(afterDelayMilliSeconds).then(() => {
            try {
                control.focus();
            } catch (err) {
                console.error("setFocus", err);
            }
        });
    }
}

export function showNotificationSuccess(key, message, description) {
    var duration = 3;
    notification.open({
        key: key,
        type: NotificationTypes.SUCCESS,
        message: message,
        description: description,
        duration: duration,
        top: 80,
    });
}

export function showNotificationInfo(key, message, description) {
    var duration = 3;
    notification.open({
        key: key,
        type: NotificationTypes.INFO,
        message: message,
        description: description,
        duration: duration,
        top: 80,
    });
}

export function showNotificationWarning(key, message, description) {
    var duration = 3;
    notification.open({
        key: key,
        type: NotificationTypes.WARNING,
        message: message,
        description: description,
        duration: duration,
        top: 80,
    });
}

export function showNotificationError(key, message, description) {
    var style = {
        width: 600,
        marginLeft: 335 - 600,
    };
    var duration = 0; // don't close automatically
    notification.open({
        key: key,
        type: NotificationTypes.ERROR,
        message: message,
        description: !description ? null : parse(description),
        duration: duration,
        top: 80,
        style: style,
    });
}

export function hideNotification(key) {
    notification.close(key);
}

// clone an object using JSON. e.g. cloning as array of JSON objects
export function jsonClone(jsonObject) {
    return JSON.parse(JSON.stringify(jsonObject));
}

export function nullIfEmptyOrWhitespace(value) {
    if (value) {
        if (value.trim() === "") {
            return null;
        } else {
            return value;
        }
    } else {
        return null;
    }
}

export function replaceHypens(text) {
    if (text) {
        return text.replace(/-/g, "");
    }
    return text;
}

export function thingRoute(thingId) {
    if (thingId) {
        return Routes.THING + "/" + replaceHypens(thingId).toLowerCase();
    }
    return Routes.THINGS;
}

export function convertToGuidString(text) {
    if (text) {
        if (text.length === 32) {
            return (
                text.substring(0, 8) +
                "-" +
                text.substring(8, 12) +
                "-" +
                text.substring(12, 16) +
                "-" +
                text.substring(16, 20) +
                "-" +
                text.substring(20)
            ).toLowerCase();
        }
    }
    return null;
}

export function defaultDateFormat(countryCode) {
    var dateFormat = "D MMM YYYY";
    switch (countryCode) {
        case "CA":
            dateFormat = "D MMM YYYY";
            break;
        case "GB":
            dateFormat = "D/M/YYYY";
            break;
        case "US":
            dateFormat = "M/D/YYYY";
            break;
        default:
    }
    return dateFormat;
}

export function dateFormatList(countryCode) {
    // See https://ant.design/components/date-picker/#API for DATE_FORMAT_LIST info
    // Also see https://momentjs.com/docs/#/displaying/format/ and https://momentjs.com/
    // NOTE the antd DatePicker allows you to enter the date in any format in the list, but displays it in the first format in the list
    var list = [
        "D MMM YYYY",
        "L",
        "l",
        "DD MMM YYYY",
        "D MMM YY",
        "DD MMM YY",
        "D/M/YY",
        "DD/M/YY",
        "D/MM/YY",
        "DD/MM/YY",
        "D/M/YYYY",
        "DD/M/YYYY",
        "D/MM/YYYY",
        "DD/MM/YYYY",
    ];
    switch (countryCode) {
        case "CA":
            list = [
                "D MMM YYYY",
                "L",
                "l",
                "DD MMM YYYY",
                "D MMM YY",
                "DD MMM YY",
                "D/M/YY",
                "DD/M/YY",
                "D/MM/YY",
                "DD/MM/YY",
                "D/M/YYYY",
                "DD/M/YYYY",
                "D/MM/YYYY",
                "DD/MM/YYYY",
            ];
            break;
        case "GB":
            list = [
                "D/M/YYYY",
                "L",
                "l",
                "DD MMM YYYY",
                "D MMM YY",
                "DD MMM YY",
                "D/M/YY",
                "DD/M/YY",
                "D/MM/YY",
                "DD/MM/YY",
                "D/M/YYYY",
                "DD/M/YYYY",
                "D/MM/YYYY",
                "DD/MM/YYYY",
            ];
            break;
        case "US":
            list = [
                "M/D/YYYY",
                "L",
                "l",
                "DD MMM YYYY",
                "D MMM YYYY",
                "D MMM YY",
                "DD MMM YY",
                "M/D/YY",
                "M/DD/YY",
                "MM/D/YY",
                "MM/DD/YY",
                "M/D/YYYY",
                "M/DD/YYYY",
                "MM/D/YYYY",
                "MM/DD/YYYY",
            ];
            break;
        default:
    }
    return list;
}

export function formatWithCommas(num) {
    return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export function getReviewDate(nextRenewalDate, completed) {
    var reviewDate = moment();
    if (completed) {
        reviewDate = moment().add(6, "months");
        if (nextRenewalDate) {
            if (nextRenewalDate < reviewDate) {
                reviewDate = nextRenewalDate;
            }
        }
    }
    return reviewDate;
}

// TODO: get the descriptions from the database
export function personTypeDescription(accountPersonTypeId) {
    switch (accountPersonTypeId) {
        case PersonTypeIds.PERSON:
            return "Person";
        case PersonTypeIds.INHERITOR:
            return "Inheritor";
        case PersonTypeIds.PRIMARY_OWNER:
            return "Primary Owner";
        case PersonTypeIds.SECONDARY_OWNER:
            return "Secondary Owner";
        case PersonTypeIds.VIEWER_INHERITOR:
            return "Viewer Inheritor";
        case PersonTypeIds.VIEWER:
            return "Viewer";
        default:
            return accountPersonTypeId;
    }
}

// TODO: get the descriptions from the database
export function loginProviderIdToString(loginProviderId) {
    switch (loginProviderId) {
        case "Z":
            return "Amazon";
        case "A":
            return "Apple";
        case "G":
            return "Google";
        case "L":
            return "Local";
        case "M":
            return "Microsoft";
        default:
            return "Unknown";
    }
}

export function getTooltipByKey(tooltipKey, tooltipList) {
    let tooltips = tooltipList.filter((t) => t.key === tooltipKey);
    if (tooltips.length < 1) {
        return {
            title: "TOOLTIP_NOT_FOUND",
            content: "TOOLTIP_NOT_FOUND",
        };
    }

    return {
        title: tooltips[0].title,
        content: tooltips[0].content,
    };
}

export function createLengthValidationRule(maxLength, fieldName) {
    return {
        max: maxLength,
        message: `${fieldName} cannot be longer than ${maxLength} characters`,
    };
}

export function getUserSignupCodeAsync(userId) {
    if (userId === undefined || userId === null || userId === "") {
        return null;
    }

    return ajaxGetAsync2(APIEndpoints.Signup + `CheckSignup/`).then(
        (response) => {
            let signupCode = response.data;
            if (
                signupCode === undefined ||
                signupCode === null ||
                signupCode === ""
            ) {
                return null;
            }
            return signupCode;
        }
    );
}

export function getSignupCodeStatusAsync(signupCode) {
    if (signupCode === null || signupCode === "")
        return SignupCodeStatuses.Invalid;
    return ajaxGetAsync2(
        APIEndpoints.Signup + `SignupCode/${encodeURIComponent(signupCode)}/`
    ).then((response) => {
        let status = response.data;
        // Append the original code to the result for context purposes
        status.signupCode = signupCode;
        return status;
    });
}

export function getCheckinCodeStatusAsync(checkinCode) {
    if (checkinCode === null || checkinCode === "")
        return CheckinCodeStatuses.Invalid;
    return ajaxGetAsync2(
        APIEndpoints.Account + `Checkin/${encodeURIComponent(checkinCode)}/`
    ).then((response) => {
        let status = response.data;
        // Append the original code to the result for context purposes
        status.checkinCode = checkinCode;
        return status;
    });
}

export const personTypeToSortOrder = (type) => {
    switch (type) {
        case PersonTypeIds.PRIMARY_OWNER:
            return 1;
        case PersonTypeIds.SECONDARY_OWNER:
            return 2;
        case PersonTypeIds.INHERITOR:
            return 3;
        case PersonTypeIds.VIEWER_INHERITOR:
            return 4;
        case PersonTypeIds.VIEWER:
            return 5;
        default:
            return 6;
    }
};

export function sortPeopleListAlphabetically(peopleList) {
    return peopleList.sort((p1, p2) => {
        // Sort alphabetically
        if (p1.fullName < p2.fullName) return -1;
        if (p1.fullName > p2.fullName) return 1;

        // People are equal
        return 0;
    });
}

export function trimAuthInfoFromEmail(email) {
    if (email) {
        if (
            email.startsWith("Z_") ||
            email.startsWith("A_") ||
            email.startsWith("L_") ||
            email.startsWith("G_") ||
            email.startsWith("M_")
        ) {
            return email.substr(2);
        }
    }

    return email;
}

export function getSizeInBytes(obj) {
    if (obj === null || obj === undefined) {
        return 0;
    }
    return new Blob([obj], {
        type: "text/plain",
    }).size;
}
