import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { routerMiddleware } from "connected-react-router/immutable";

import history from "./history";
import rootReducer from "./reducers/rootReducer";

const composeEnhancers =
    typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
              // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
              // See https://github.com/zalmoxisus/redux-devtools-extension/blob/master/docs/API/Arguments.md
              trace: true,
              traceLimit: 25,
          })
        : compose;

const enhancer = composeEnhancers(
    applyMiddleware(routerMiddleware(history), thunk)
    // other store enhancers if any
);

const store = createStore(rootReducer(history), enhancer);

export default store;
