import { AuthActions } from "../actions/authActions";

const initialState = {
    isReady: false,
    authenticated: false,
    isExpired: false,
    userName: null,
    userId: null,
    message: undefined,
};

function auth(state = initialState, action) {
    switch (action.type) {
        case AuthActions.AUTH_SET_MESSAGE:
            return {
                ...state,
                message: action.data.message,
            };

        case AuthActions.AUTH_SET_USERINFO:
            return {
                ...state,
                isReady: action.data.isReady,
                authenticated: action.data.isAuthenticated,
                isExpired: action.data.isExpired,
                userName: action.data.userName,
                userId: action.data.userId,
            };

        default:
            return state;
    }
}

export default auth;
