import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { List } from "antd";
import classNames from "classnames";

import { clearSearchAndRedirectToThing } from "../redux/actions";
import { getRecentThingsListDispatchable } from "../redux/actions/thingListsActions";

import { ThingTabs } from "../constants";
import { MessageError } from "./MessageError";
import ThingCard from "./ThingCard";
import LoadingIndicator from "./LoadingIndicator";
import ThingIsDirtyCheck from "./ThingIsDirtyCheck";

export const RecentThingsList = (props) => {
    const dispatch = useDispatch();
    const maxItems = props.maxItems;
    const activeThingId = useSelector((state) => state.thing.activeThingId);
    const thingListTrimmed = useSelector(
        (state) =>
            state.thinglists.recentThings &&
            state.thinglists.recentThings.listData.slice(0, maxItems)
    );
    const loading = useSelector(
        (state) => state.thinglists.recentThingsLoading
    );
    const error = useSelector((state) => state.thinglists.recentThingsError);
    const redirectView = props.redirectOnClick === true;

    useEffect(() => {
        dispatch(getRecentThingsListDispatchable());
    }, [dispatch]);

    const handleSetActiveThing = (thingId) => {
        dispatch(
            clearSearchAndRedirectToThing(thingId, ThingTabs.RECENT_THINGS)
        );
    };

    const renderItem = (thing) => {
        let active = activeThingId === thing.thingId;
        return (
            <ThingIsDirtyCheck>
                <List.Item
                    key={thing.thingId}
                    className={classNames("tt-list-item", {
                        "tt-list-item-active": active && !redirectView,
                        "tt-hover": !active,
                    })}
                    onClick={() => handleSetActiveThing(thing.thingId)}
                >
                    <ThingCard thing={thing} />
                </List.Item>
            </ThingIsDirtyCheck>
        );
    };

    if (error) {
        return <MessageError error={error} />;
    }
    if (loading || !thingListTrimmed) {
        return <LoadingIndicator />;
    }
    if (thingListTrimmed.length === 0) {
        return (
            <div className="tt-empty-list">
                You do not have any recent things
            </div>
        );
    }
    return (
        <List
            dataSource={thingListTrimmed}
            renderItem={(thing) => renderItem(thing)}
        />
    );
};

export default RecentThingsList;
