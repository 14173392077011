import { ActionTypes, APIEndpoints } from "../../constants";
import store from "../store";
import { ajaxGetAsync } from "./ajaxActions";

export function reloadThingListsDispatchable() {
    return async (dispatchAsync) => {
        let state = store.getState();

        // set reload for list on other pages
        await dispatchAsync(setReloadForPaymentThingsList());
        await dispatchAsync(setReloadForPaymentSummaryList());
        await dispatchAsync(setReloadForUpcomingPaymentsList());
        await dispatchAsync(setReloadForUpcomingThingsList());
        await dispatchAsync(setReloadForOverdueThingsList());

        // set reload for list on Things page
        await dispatchAsync(setReloadForRelatedThingsList());
        await dispatchAsync(setReloadForRecentThingsList());
        await dispatchAsync(setReloadForInProgressThingsList());
        await dispatchAsync(setReloadForGroupByThingsList());

        // We create an array of promises here and wait for them all to complete later so the following actions can act asynchronously
        let promises = [];
        promises.push(dispatchAsync(getUpcomingThingsListDispatchable()));
        promises.push(dispatchAsync(getUpcomingPaymentsListDispatchable()));
        promises.push(dispatchAsync(getPaymentSummaryListDispatchable()));
        promises.push(dispatchAsync(getPaymentThingsListDispatchable()));
        promises.push(dispatchAsync(getOverdueThingsListDispatchable()));
        
        // reload lists for Things page
        promises.push(dispatchAsync(getRelatedThingsListDispatchable()));
        promises.push(dispatchAsync(getRecentThingsListDispatchable()));
        promises.push(dispatchAsync(getInProgressThingsListDispatchable()));
        promises.push(
            dispatchAsync(
                getGroupByThingsListDispatchable(
                    state.selected.groupByType,
                    state.selected.searchText
                )
            )
        );

        await Promise.all(promises);
    };
}

export function setReloadForInProgressThingsList() {
    return {
        type: ActionTypes.GET_IN_PROGRESS_THINGS_LIST_LOAD,
    };
}
export function getInProgressThingsListDispatchable() {
    let state = store.getState();
    if (
        state.thinglists.inProgressThingsLoad &&
        !state.thinglists.inProgressThingsLoading
    ) {
        return async (dispatchAsync) => {
            await ajaxGetAsync(
                APIEndpoints.Thing + "InProgressThingsList",
                ActionTypes.GET_IN_PROGRESS_THINGS_LIST,
                ActionTypes.GET_IN_PROGRESS_THINGS_LIST_OK,
                ActionTypes.GET_IN_PROGRESS_THINGS_LIST_FAIL,
                dispatchAsync
            );
        };
    }
    return {
        type: ActionTypes.GET_IN_PROGRESS_THINGS_LIST_IGNORE,
    };
}

export function setReloadForUpcomingThingsList() {
    return {
        type: ActionTypes.GET_UPCOMING_THINGS_LIST_LOAD,
    };
}
export function getUpcomingThingsListDispatchable() {
    let state = store.getState();
    if (
        state.thinglists.upcomingThingsLoad &&
        !state.thinglists.upcomingThingsLoading
    ) {
        return async (dispatchAsync) => {
            await ajaxGetAsync(
                APIEndpoints.Thing + "UpcomingThingsList",
                ActionTypes.GET_UPCOMING_THINGS_LIST,
                ActionTypes.GET_UPCOMING_THINGS_LIST_OK,
                ActionTypes.GET_UPCOMING_THINGS_LIST_FAIL,
                dispatchAsync
            );
        };
    }
    return {
        type: ActionTypes.GET_UPCOMING_THINGS_LIST_IGNORE,
    };
}

export function setReloadForUpcomingPaymentsList() {
    return {
        type: ActionTypes.GET_UPCOMING_PAYMENTS_LIST_LOAD,
    };
}
export function getUpcomingPaymentsListDispatchable() {
    let state = store.getState();
    if (
        state.thinglists.upcomingPaymentsLoad &&
        !state.thinglists.upcomingPaymentsLoading
    ) {
        return async (dispatchAsync) => {
            await ajaxGetAsync(
                APIEndpoints.Thing + "UpcomingPaymentsList",
                ActionTypes.GET_UPCOMING_PAYMENTS_LIST,
                ActionTypes.GET_UPCOMING_PAYMENTS_LIST_OK,
                ActionTypes.GET_UPCOMING_PAYMENTS_LIST_FAIL,
                dispatchAsync
            );
        };
    }
    return {
        type: ActionTypes.GET_UPCOMING_PAYMENTS_LIST_IGNORE,
    };
}

export function setReloadForPaymentSummaryList() {
    return {
        type: ActionTypes.GET_PAYMENT_SUMMARY_LIST_LOAD,
    };
}
export function getPaymentSummaryListDispatchable() {
    let state = store.getState();
    if (
        state.thinglists.paymentSummaryLoad &&
        !state.thinglists.paymentSummaryLoading
    ) {
        return async (dispatchAsync) => {
            await ajaxGetAsync(
                APIEndpoints.Thing + "PaymentSummaryList",
                ActionTypes.GET_PAYMENT_SUMMARY_LIST,
                ActionTypes.GET_PAYMENT_SUMMARY_LIST_OK,
                ActionTypes.GET_PAYMENT_SUMMARY_LIST_FAIL,
                dispatchAsync
            );
        };
    }
    return {
        type: ActionTypes.GET_PAYMENT_SUMMARY_LIST_IGNORE,
    };
}

export function setReloadForPaymentThingsList() {
    return {
        type: ActionTypes.GET_PAYMENT_THINGS_LIST_LOAD,
    };
}
export function getPaymentThingsListDispatchable() {
    let state = store.getState();
    if (
        state.thinglists.paymentThingsListLoad &&
        !state.thinglists.paymentThingsListLoading
    ) {
        return async (dispatchAsync) => {
            await ajaxGetAsync(
                APIEndpoints.Thing + "PaymentThingsList",
                ActionTypes.GET_PAYMENT_THINGS_LIST,
                ActionTypes.GET_PAYMENT_THINGS_LIST_OK,
                ActionTypes.GET_PAYMENT_THINGS_LIST_FAIL,
                dispatchAsync
            );
        };
    }
    return {
        type: ActionTypes.GET_PAYMENT_THINGS_LIST_IGNORE,
    };
}

export function setReloadForGroupByThingsList() {
    return {
        type: ActionTypes.GET_GROUP_BY_THINGS_LIST_LOAD,
    };
}
export function getGroupByThingsListDispatchable(groupByType, searchText) {
    let state = store.getState();
    if (
        state.thinglists.groupByThingsLoad &&
        !state.thinglists.groupByThingsLoading
    ) {
        var url = APIEndpoints.Thing + "GroupByThingsList";
        if (groupByType) {
            url = url + "/" + encodeURIComponent(groupByType);
            if (searchText) {
                url = url + "/" + encodeURIComponent(searchText);
            }
        }
        return async (dispatchAsync) => {
            await ajaxGetAsync(
                url,
                ActionTypes.GET_GROUP_BY_THINGS_LIST,
                ActionTypes.GET_GROUP_BY_THINGS_LIST_OK,
                ActionTypes.GET_GROUP_BY_THINGS_LIST_FAIL,
                dispatchAsync
            );
        };
    }
    return {
        type: ActionTypes.GET_GROUP_BY_THINGS_LIST_IGNORE,
    };
}

export function setReloadForOverdueThingsList() {
    return {
        type: ActionTypes.GET_OVERDUE_THINGS_LIST_LOAD,
    };
}
export function getOverdueThingsListDispatchable() {
    let state = store.getState();
    if (
        state.thinglists.overdueThingsLoad &&
        !state.thinglists.overdueThingsLoading
    ) {
        return async (dispatchAsync) => {
            await ajaxGetAsync(
                APIEndpoints.Thing + "OverdueThingsList",
                ActionTypes.GET_OVERDUE_THINGS_LIST,
                ActionTypes.GET_OVERDUE_THINGS_LIST_OK,
                ActionTypes.GET_OVERDUE_THINGS_LIST_FAIL,
                dispatchAsync
            );
        };
    }
    return {
        type: ActionTypes.GET_OVERDUE_THINGS_LIST_IGNORE,
    };
}

export function setReloadForRecentThingsList() {
    return {
        type: ActionTypes.GET_RECENT_THINGS_LIST_LOAD,
    };
}
export function getRecentThingsListDispatchable() {
    let state = store.getState();
    if (
        state.thinglists.recentThingsLoad &&
        !state.thinglists.recentThingsLoading
    ) {
        return async (dispatchAsync) => {
            await ajaxGetAsync(
                APIEndpoints.Thing + "RecentThingsList",
                ActionTypes.GET_RECENT_THINGS_LIST,
                ActionTypes.GET_RECENT_THINGS_LIST_OK,
                ActionTypes.GET_RECENT_THINGS_LIST_FAIL,
                dispatchAsync
            );
        };
    }
    return {
        type: ActionTypes.GET_RECENT_THINGS_LIST_IGNORE,
    };
}

export function setReloadForRelatedThingsList() {
    return {
        type: ActionTypes.GET_RELATED_THINGS_LIST_LOAD,
    };
}
export function getRelatedThingsListDispatchable() {
    let state = store.getState();
    if (
        state.thinglists.relatedThingsLoad &&
        !state.thinglists.relatedThingsLoading
    ) {
        return async (dispatchAsync) => {
            await ajaxGetAsync(
                APIEndpoints.Thing + "RelatedThingsList",
                ActionTypes.GET_RELATED_THINGS_LIST,
                ActionTypes.GET_RELATED_THINGS_LIST_OK,
                ActionTypes.GET_RELATED_THINGS_LIST_FAIL,
                dispatchAsync
            );
        };
    }
    return {
        type: ActionTypes.GET_RELATED_THINGS_LIST_IGNORE,
    };
}
