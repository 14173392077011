import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { List } from "antd";

import { clearSearchAndRedirectToThing } from "../../redux/actions";
import { getUpcomingPaymentsListDispatchable } from "../../redux/actions/thingListsActions";

import { MessageError } from "../../shared/MessageError";
import ThingReminderCard from "../../shared/ThingReminderCard";
import { ThingTabs } from "../../constants";
import LoadingIndicator from "../../shared/LoadingIndicator";

export const UpcomingPaymentsList = () => {
    const dispatch = useDispatch();
    const thingList = useSelector((state) => state.thinglists.upcomingPayments);
    const thingsCount = useSelector((state) =>
        state.thinglists.upcomingPayments
            ? state.thinglists.upcomingPayments.count
            : 0
    );
    const loading = useSelector(
        (state) => state.thinglists.upcomingPaymentsLoading
    );
    const error = useSelector(
        (state) => state.thinglists.upcomingPaymentsError
    );

    useEffect(() => {
        dispatch(getUpcomingPaymentsListDispatchable());
    }, [dispatch]);

    if (error) {
        return <MessageError error={error} />;
    }
    if (loading) {
        return <LoadingIndicator />;
    }
    if (thingsCount === 0) {
        return (
            <div className="tt-empty-list">
                You do not have any upcoming payments
            </div>
        );
    }
    return (
        <List
            dataSource={thingList.listData}
            renderItem={(thing) => (
                <List.Item
                    key={thing.thingId}
                    onClick={() =>
                        dispatch(
                            clearSearchAndRedirectToThing(
                                thing.thingId,
                                ThingTabs.ALL_THINGS
                            )
                        )
                    }
                >
                    <ThingReminderCard thing={thing} />
                </List.Item>
            )}
        />
    );
};

export default UpcomingPaymentsList;
