import Cookies from "universal-cookie";
import { CookieKeys } from "../constants";

const cookieService = new Cookies();

export const clearCookiesOnLogout = () => {
    cookieService.remove(CookieKeys.LastSelectedAccount);
};

export default cookieService;
