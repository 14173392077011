import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
    DatePicker,
    Select,
    Switch,
    Form,
    Input,
    Row,
    Col,
    Drawer,
} from "antd";

import { Defaults } from "../../constants";

import ThingInput from "./ThingInput";
import { dateFormatList } from "../../functions";
import LoadingIndicator from "../../shared/LoadingIndicator";
import ThingReminderSchedule from "./ThingReminderSchedule";

export const ThingRenewalDetailsContainer = (props) => {
    const { Option } = Select;
    const form = props.form;
    const [drawerVisible, setDrawerVisible] = useState(false);
    const countryCode = useSelector(
        (state) => state.account.accountCountryCode
    );
    const frequencies = useSelector((state) => state.account.frequenciesList);
    const reminderSchedules = useSelector(
        (state) => state.account.reminderSchedulesList
    );
    const [hasRenewalDate, setHasRenewalDate] = useState(
        props.initialFormValues && props.initialFormValues.nextRenewalDate
    );
    const [
        resetRenewalFrequencySelectField,
        setResetRenewalFrequencySelectField,
    ] = useState(false);
    const [
        resetReminderScheduleSelectField,
        setResetReminderScheduleSelectField,
    ] = useState(false);
    const [scheduleDate, setScheduleDate] = useState(
        props.initialFormValues.nextRenewalDate
    );
    const [scheduleId, setScheduleId] = useState(
        props.initialFormValues.renewalReminderScheduleId
    );

    useEffect(() => {
        // hacks to force select placeholder to reappear
        if (resetRenewalFrequencySelectField === true) {
            form.setFieldsValue({ renewalFrequencyId: undefined });
            setResetRenewalFrequencySelectField(false);
        }
        if (resetReminderScheduleSelectField === true) {
            form.setFieldsValue({ renewalReminderScheduleId: undefined });
            setResetReminderScheduleSelectField(false);
        }
    }, [
        form,
        resetReminderScheduleSelectField,
        resetRenewalFrequencySelectField,
    ]);

    const handleDrawerClose = () => {
        setDrawerVisible(false);
    };

    const defaultRenewalReminderScheduleId = () => {
        let foundScheduleId = "";
        if (reminderSchedules) {
            for (let schedule of reminderSchedules) {
                if (schedule.name === "Starting 1 Month Before") {
                    foundScheduleId = schedule.reminderScheduleId;
                    break;
                }
            }
        }
        return foundScheduleId;
    };

    const updateScheduleInfo = () => {
        setScheduleDate(form.getFieldValue("nextRenewalDate"));
        setScheduleId(form.getFieldValue("renewalReminderScheduleId"));
    };

    const handleDateChange = (renewalDate) => {
        setHasRenewalDate(renewalDate !== null);
        if (
            renewalDate &&
            (form.getFieldValue("renewalReminderScheduleId") === null ||
                form.getFieldValue("renewalReminderScheduleId") === "")
        ) {
            form.setFieldsValue({
                renewalReminderScheduleId: defaultRenewalReminderScheduleId(),
            });
        }
        updateScheduleInfo();

        // reset review date if completed and review date exceeds renewal date
        if (renewalDate) {
            var completed = form.getFieldValue("completed");
            if (completed === true) {
                var reviewDate = form.getFieldValue("nextReviewDate");
                if (reviewDate) {
                    if (reviewDate > renewalDate) {
                        form.setFieldsValue({ nextReviewDate: renewalDate });
                    }
                }
            }
        }
    };

    const handleRenewalFrequencySelect = (value) => {
        if (value === "") {
            setResetRenewalFrequencySelectField(true);
            return;
        }
    };

    const handleReminderScheduleSelect = (value) => {
        updateScheduleInfo();
        if (value === "") {
            setResetReminderScheduleSelectField(true);
            return;
        }
    };

    if (!frequencies) {
        return <LoadingIndicator />;
    }

    return (
        <div className="tt-ted-panel-container">
            <ThingInput
                visible={true}
                label="Renewal Frequency"
                tooltipkey="ThingInput_RenewalFrequency"
            >
                <Form.Item name="renewalFrequencyId" hasFeedback>
                    <Select
                        placeholder="Select a renewal frequency"
                        onSelect={handleRenewalFrequencySelect}
                    >
                        <Option key="" value=""></Option>
                        {frequencies.map((f) => (
                            <Option key={f.frequencyId} value={f.frequencyId}>
                                {f.localizedName}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
            </ThingInput>
            <Input.Group>
                <Row gutter={16}>
                    <Col span={9}>
                        <ThingInput
                            visible={true}
                            label="Next Renewal Date"
                            tooltipkey="ThingInput_RenewalDate"
                        >
                            <Form.Item name="nextRenewalDate">
                                <DatePicker
                                    disabledTime
                                    onChange={handleDateChange}
                                    format={dateFormatList(countryCode)}
                                />
                            </Form.Item>
                        </ThingInput>
                    </Col>
                    <Col>
                        <ThingInput
                            visible={hasRenewalDate}
                            label="Auto-Rollover Next Renewal Date"
                            tooltipkey="ThingInput_RenewalAutoRollover"
                        >
                            <Form.Item
                                name="autoRolloverRenewalDate"
                                valuePropName="checked"
                            >
                                <Switch
                                    checkedChildren={"Yes"}
                                    unCheckedChildren={"No"}
                                />
                            </Form.Item>
                        </ThingInput>
                    </Col>
                </Row>
            </Input.Group>
            <Input.Group style={{ display: hasRenewalDate ? "block" : "none" }}>
                <ThingInput
                    visible={hasRenewalDate}
                    label="Reminder Schedule"
                    tooltipkey="ThingInput_RenewalReminder"
                >
                    <Form.Item name="renewalReminderScheduleId" hasFeedback>
                        <Select
                            name="renewalReminderScheduleId"
                            placeholder="Select a reminder schedule"
                            onSelect={handleReminderScheduleSelect}
                        >
                            <Option key="" value=""></Option>
                            {reminderSchedules.map((rs) => (
                                <Option
                                    key={rs.reminderScheduleId}
                                    value={rs.reminderScheduleId}
                                >
                                    {rs.localizedName}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </ThingInput>
            </Input.Group>
            <ThingReminderSchedule
                date={scheduleDate}
                reminderScheduleId={scheduleId}
            />

            <Drawer
                title="New Reminder Schedule"
                placement="right"
                width={Defaults.DRAWER_WIDTH}
                closable={false}
                onClose={handleDrawerClose}
                visible={drawerVisible}
            ></Drawer>
        </div>
    );
};

export default ThingRenewalDetailsContainer;
