import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Input,
    Row,
    Col,
    Drawer,
    Form,
    Select,
    Popconfirm,
    Popover,
} from "antd";

import { updateRelatedPeople, resetThingPost } from "../../redux/actions";
import { getAccountLists } from "../../redux/actions/accountActions";
import {
    compareValues,
    jsonClone,
    setFocus,
    personTypeDescription,
    sortPeopleListAlphabetically,
} from "../../functions";

import ThingInput from "./ThingInput";
import { DeleteFilled, DeleteTwoTone } from "@ant-design/icons";
import {
    ThingUpdateActions,
    Defaults,
    ThingPostTypes,
    LastPostStatus,
    Routes,
} from "../../constants";
import ThingNewPerson from "./ThingNewPerson";
import { setThingIsDirty } from "../../redux/actions/thingActions";
import TitleWithPill from "../../shared/TitleWithPill";

export const ThingRelatedPeople = (props) => {
    const { Option } = Select;
    const dispatch = useDispatch();
    const form = props.form;
    const [resetSelectField, setResetSelectField] = useState(false);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const activeThingPeople = useSelector(
        (state) => state.thing.activeThingPeople
    );
    const peopleList = useSelector((state) => state.account.peopleList);
    const relatedPeopleSelect = useRef(null);
    const lastPostType = useSelector((state) => state.thing.lastPostType);
    const lastPostStatus = useSelector((state) => state.thing.lastPostStatus);
    const lastPostData = useSelector((state) => state.thing.lastPostData);
    const newPersonKey = "NEW_RELATED_PERSON";

    // sort and count list of related people
    var relatedPeopleCount = 0;
    var relatedPeople = jsonClone(activeThingPeople);
    if (relatedPeople) {
        relatedPeople.forEach((p) => {
            p.name = p.firstName + " " + p.lastName;
            if (p.action !== ThingUpdateActions.DELETE) {
                relatedPeopleCount++;
            }
        });
        relatedPeople.sort(compareValues("name", "asc"));
    }

    useEffect(() => {
        if (lastPostStatus) {
            if (
                lastPostType === ThingPostTypes.NEW_PERSON &&
                lastPostStatus === LastPostStatus.OK &&
                lastPostData
            ) {
                // new related person was created, so add it to related people list
                var newList2 = jsonClone(activeThingPeople);
                newList2.push({
                    thingPersonId: null,
                    personId: lastPostData.data.personId,
                    firstName: lastPostData.data.firstName,
                    lastName: lastPostData.data.lastName,
                    accountPersonTypeId: lastPostData.data.accountPersonTypeId,
                    existing: false,
                    action: ThingUpdateActions.NEW,
                });
                dispatch(resetThingPost());
                dispatch(updateRelatedPeople(cleanupList(newList2)));
                dispatch(getAccountLists());
                form.setFieldsValue({ personId: undefined }); // force form isFieldsTouched to register change
                props.handleValuesChange();
            }
        }

        // hack to force select placeholder to reappear
        if (resetSelectField === true) {
            form.setFieldsValue({ personId: undefined });
            setResetSelectField(false);
        }
    }, [
        activeThingPeople,
        dispatch,
        form,
        lastPostData,
        lastPostStatus,
        lastPostType,
        props,
        resetSelectField,
    ]);

    // remove any non-existing items which have been marked for deletion
    const cleanupList = (list) => {
        var newList = [];
        list.forEach((p) => {
            if (p.existing || p.action !== ThingUpdateActions.DELETE) {
                newList.push(p);
            }
        });
        return newList;
    };

    const addPerson = (personId) => {
        if (personId === newPersonKey) {
            handleNewItemClick();
            return;
        }

        if (activeThingPeople.some((p) => p.personId === personId)) {
            var newList1 = jsonClone(activeThingPeople);
            newList1.forEach((p) => {
                if (p.personId === personId) {
                    p.action = p.existing
                        ? ThingUpdateActions.NOCHANGE
                        : ThingUpdateActions.NEW;
                }
            });
            dispatch(updateRelatedPeople(cleanupList(newList1)));
        } else {
            var person = findRelatedPersonByKey(personId);
            var newList2 = jsonClone(activeThingPeople);
            newList2.push({
                thingPersonId: null,
                personId: personId,
                firstName: person.firstName,
                lastName: person.lastName,
                accountPersonTypeId: person.accountPersonTypeId,
                existing: false,
                action: ThingUpdateActions.NEW,
            });
            dispatch(updateRelatedPeople(cleanupList(newList2)));
        }
        setResetSelectField(true);
        props.handleValuesChange();
    };

    const findRelatedPersonByKey = (personId) => {
        let foundPerson = null;
        if (peopleList) {
            for (let person of peopleList) {
                if (person.personId === personId) {
                    foundPerson = person;
                    break;
                }
            }
        }
        return foundPerson;
    };

    const deletePerson = (personId) => {
        var newList = jsonClone(activeThingPeople);
        newList.forEach((p) => {
            if (p.personId === personId) {
                p.action = ThingUpdateActions.DELETE;
            }
        });
        dispatch(updateRelatedPeople(cleanupList(newList)));
        setResetSelectField(true);
        props.handleValuesChange();
        dispatch(setThingIsDirty(true));
    };

    const handleNewItemClick = () => {
        setResetSelectField(true);
        dispatch(resetThingPost());
        setDrawerVisible(true);
    };

    const handleDrawerClose = () => {
        setDrawerVisible(false);
        setFocus(relatedPeopleSelect.current);
    };

    if (!peopleList) {
        return null;
    }

    return (
        <div className="tt-ted-panel-container">
            {relatedPeople &&
                relatedPeople.map((p) =>
                    p.action === ThingUpdateActions.DELETE ? null : (
                        <Row className="tt-ted-list-item" key={p.personId}>
                            <Col flex="1 1" className="tt-no-overflow">
                                <TitleWithPill
                                    titleContent={`${p.firstName} ${p.lastName}`}
                                    titleLinkUrl={Routes.PEOPLE}
                                    pillContent={personTypeDescription(
                                        p.accountPersonTypeId
                                    )}
                                />
                            </Col>
                            <Col flex="0 1" className="tt-ted-list-item-icon">
                                {relatedPeopleCount <= 1 ? (
                                    <Popover
                                        trigger="hover"
                                        placement="rightTop"
                                        title="Remove Related Person"
                                        content="You cannot remove this person. A thing must have at least one related person. If you want to remove this person, add another person first."
                                        mouseEnterDelay={
                                            Defaults.POPOVER_MOUSE_ENTER_DELAY_FAST
                                        }
                                        mouseLeaveDelay={
                                            Defaults.POPOVER_MOUSE_LEAVE_DELAY
                                        }
                                    >
                                        <DeleteTwoTone twoToneColor="#6c757d" />
                                    </Popover>
                                ) : (
                                    <Popover
                                        trigger="hover"
                                        placement="rightTop"
                                        title="Remove Related Person"
                                        content="Click here to remove this related person"
                                        mouseEnterDelay={
                                            Defaults.POPOVER_MOUSE_ENTER_DELAY_FAST
                                        }
                                        mouseLeaveDelay={
                                            Defaults.POPOVER_MOUSE_LEAVE_DELAY
                                        }
                                    >
                                        <Popconfirm
                                            title="Are you sure you want to remove this person?"
                                            okText="Yes"
                                            cancelText="No"
                                            onConfirm={(e) =>
                                                deletePerson(p.personId)
                                            }
                                        >
                                            <DeleteFilled />
                                        </Popconfirm>
                                    </Popover>
                                )}
                            </Col>
                        </Row>
                    )
                )}
            <div className="tt-spacer" />
            <Input.Group>
                <ThingInput
                    visible={true}
                    label="Select Person"
                    tooltipkey="ThingInput_RelatedPersonSelect"
                >
                    <Form.Item name="personId">
                        <Select
                            name="personId"
                            ref={relatedPeopleSelect}
                            showSearch
                            optionFilterProp="children"
                            dropdownClassName="tt-ted-dropdown"
                            placeholder="Select person (type to search)"
                            onSelect={addPerson}
                            autoComplete="off"
                        >
                            <Option key={newPersonKey} value={newPersonKey}>
                                {"< Add a New Person >"}
                            </Option>
                            {sortPeopleListAlphabetically(peopleList).map((p) => (
                                <Option key={p.personId} value={p.personId}>
                                    {p.firstName} {p.lastName}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </ThingInput>
            </Input.Group>
            <Drawer
                title="New Person"
                placement="right"
                width={Defaults.DRAWER_WIDTH}
                closable={false}
                onClose={handleDrawerClose}
                visible={drawerVisible}
            >
                <ThingNewPerson onClose={handleDrawerClose} />
            </Drawer>
        </div>
    );
};

export default ThingRelatedPeople;
