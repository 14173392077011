import { ActionTypes } from "../../constants";

const initialState = {
    groupByThingsLoad: true,
    groupByThings: null,
    groupByThingsLoading: false,
    groupByThingsError: null,
    overdueThingsLoad: true,
    overdueThings: null,
    overdueThingsLoading: false,
    overdueThingsError: null,
    recentThingsLoad: true,
    recentThings: null,
    recentThingsLoading: false,
    recentThingsError: null,
    relatedThingsLoad: true,
    relatedThings: null,
    relatedThingsLoading: false,
    relatedThingsError: null,
    inProgressThingsLoad: true,
    inProgressThings: null,
    inProgressThingsLoading: false,
    inProgressThingsError: null,
    upcomingThingsLoad: true,
    upcomingThings: null,
    upcomingThingsLoading: false,
    upcomingThingsError: null,
    upcomingPaymentsLoad: true,
    upcomingPayments: null,
    upcomingPaymentsLoading: false,
    upcomingPaymentsError: null,
    paymentSummaryLoad: true,
    paymentSummary: null,
    paymentSummaryLoading: false,
    paymentSummaryError: null,
    paymentThingsListLoad: true,
    paymentThingsList: null,
    paymentThingsListLoading: false,
    paymentThingsListError: null,
};

function thinglists(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.GET_GROUP_BY_THINGS_LIST:
            return {
                ...state,
                groupByThings: null,
                groupByThingsLoading: true,
                groupByThingsError: null,
            };
        case ActionTypes.GET_GROUP_BY_THINGS_LIST_OK:
            return {
                ...state,
                groupByThings: action.data,
                groupByThingsLoading: false,
                groupByThingsLoad: false,
            };
        case ActionTypes.GET_GROUP_BY_THINGS_LIST_FAIL:
            return {
                ...state,
                groupByThingsError: action.data,
                groupByThingsLoading: false,
            };
        case ActionTypes.GET_GROUP_BY_THINGS_LIST_LOAD:
            return {
                ...state,
                groupByThingsLoad: true,
            };

        case ActionTypes.GET_OVERDUE_THINGS_LIST:
            return {
                ...state,
                overdueThings: null,
                overdueThingsLoading: true,
                overdueThingsError: null,
            };
        case ActionTypes.GET_OVERDUE_THINGS_LIST_OK:
            return {
                ...state,
                overdueThings: action.data,
                overdueThingsLoading: false,
                overdueThingsLoad: false,
            };
        case ActionTypes.GET_OVERDUE_THINGS_LIST_FAIL:
            return {
                ...state,
                overdueThingsError: action.data,
                overdueThingsLoading: false,
            };
        case ActionTypes.GET_OVERDUE_THINGS_LIST_LOAD:
            return {
                ...state,
                overdueThingsLoad: true,
            };

        case ActionTypes.GET_RECENT_THINGS_LIST:
            return {
                ...state,
                recentThings: null,
                recentThingsLoading: true,
                recentThingsError: null,
            };
        case ActionTypes.GET_RECENT_THINGS_LIST_OK:
            return {
                ...state,
                recentThings: action.data,
                recentThingsLoading: false,
                recentThingsLoad: false,
            };
        case ActionTypes.GET_RECENT_THINGS_LIST_FAIL:
            return {
                ...state,
                recentThingsError: action.data,
                recentThingsLoading: false,
            };
        case ActionTypes.GET_RECENT_THINGS_LIST_LOAD:
            return {
                ...state,
                recentThingsLoad: true,
            };

        case ActionTypes.GET_RELATED_THINGS_LIST:
            return {
                ...state,
                relatedThings: null,
                relatedThingsLoading: true,
                relatedThingsError: null,
            };
        case ActionTypes.GET_RELATED_THINGS_LIST_OK:
            return {
                ...state,
                relatedThings: action.data,
                relatedThingsLoading: false,
                relatedThingsLoad: false,
            };
        case ActionTypes.GET_RELATED_THINGS_LIST_FAIL:
            return {
                ...state,
                relatedThingsError: action.data,
                relatedThingsLoading: false,
            };
        case ActionTypes.GET_RELATED_THINGS_LIST_LOAD:
            return {
                ...state,
                relatedThingsLoad: true,
            };

        case ActionTypes.GET_IN_PROGRESS_THINGS_LIST:
            return {
                ...state,
                inProgressThings: null,
                inProgressThingsLoading: true,
                inProgressThingsError: null,
            };
        case ActionTypes.GET_IN_PROGRESS_THINGS_LIST_OK:
            return {
                ...state,
                inProgressThings: action.data,
                inProgressThingsLoading: false,
                inProgressThingsLoad: false,
            };
        case ActionTypes.GET_IN_PROGRESS_THINGS_LIST_FAIL:
            return {
                ...state,
                inProgressThingsError: action.data,
                inProgressThingsLoading: false,
            };
        case ActionTypes.GET_IN_PROGRESS_THINGS_LIST_LOAD:
            return {
                ...state,
                inProgressThingsLoad: true,
            };

        case ActionTypes.GET_UPCOMING_THINGS_LIST:
            return {
                ...state,
                upcomingThings: null,
                upcomingThingsLoading: true,
                upcomingThingsError: null,
            };
        case ActionTypes.GET_UPCOMING_THINGS_LIST_OK:
            return {
                ...state,
                upcomingThings: action.data,
                upcomingThingsLoading: false,
                upcomingThingsLoad: false,
            };
        case ActionTypes.GET_UPCOMING_THINGS_LIST_FAIL:
            return {
                ...state,
                upcomingThingsError: action.data,
                upcomingThingsLoading: false,
            };
        case ActionTypes.GET_UPCOMING_THINGS_LIST_LOAD:
            return {
                ...state,
                upcomingThingsLoad: true,
            };

        case ActionTypes.GET_UPCOMING_PAYMENTS_LIST:
            return {
                ...state,
                upcomingPayments: null,
                upcomingPaymentsLoading: true,
                upcomingPaymentsError: null,
            };
        case ActionTypes.GET_UPCOMING_PAYMENTS_LIST_OK:
            return {
                ...state,
                upcomingPayments: action.data,
                upcomingPaymentsLoading: false,
                upcomingPaymentsLoad: false,
            };
        case ActionTypes.GET_UPCOMING_PAYMENTS_LIST_FAIL:
            return {
                ...state,
                upcomingPaymentsError: action.data,
                upcomingPaymentsLoading: false,
            };
        case ActionTypes.GET_UPCOMING_PAYMENTS_LIST_LOAD:
            return {
                ...state,
                upcomingPaymentsLoad: true,
            };

        case ActionTypes.GET_PAYMENT_SUMMARY_LIST:
            return {
                ...state,
                paymentSummary: null,
                paymentSummaryLoading: true,
                paymentSummaryError: null,
            };
        case ActionTypes.GET_PAYMENT_SUMMARY_LIST_OK:
            return {
                ...state,
                paymentSummary: action.data,
                paymentSummaryLoading: false,
                paymentSummaryLoad: false,
            };
        case ActionTypes.GET_PAYMENT_SUMMARY_LIST_FAIL:
            return {
                ...state,
                paymentSummaryError: action.data,
                paymentSummaryLoading: false,
            };
        case ActionTypes.GET_PAYMENT_SUMMARY_LIST_LOAD:
            return {
                ...state,
                paymentSummaryLoad: true,
            };

        case ActionTypes.GET_PAYMENT_THINGS_LIST:
            return {
                ...state,
                paymentThingsList: null,
                paymentThingsListLoading: true,
                paymentThingsListError: null,
            };
        case ActionTypes.GET_PAYMENT_THINGS_LIST_OK:
            return {
                ...state,
                paymentThingsList: action.data,
                paymentThingsListLoading: false,
                paymentThingsListError: null,
                paymentThingsListLoad: false,
            };
        case ActionTypes.GET_PAYMENT_THINGS_LIST_FAIL:
            return {
                ...state,
                paymentThingsListError: action.data,
                paymentThingsListLoading: false,
            };
        case ActionTypes.GET_PAYMENT_THINGS_LIST_LOAD:
            return {
                ...state,
                paymentThingsListLoad: true,
            };

        default:
            return state;
    }
}

export default thinglists;
