import React, { Fragment, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TreeSelect, Button, Input, Form, Alert } from "antd";

import {
    createRelatedThing,
} from "../../redux/actions";
import { showNotificationSuccess, hideNotification } from "../../functions";
import { LastPostStatus, ThingPostTypes } from "../../constants";

import ThingInput from "./ThingInput";
import { createTreeSelectValidator } from "../../shared/FormItemValidators";
import { createUniqueThingNameValidator } from "../../shared/FormItemValidators";
import { setReloadForRelatedThingsList } from "../../redux/actions/thingListsActions";

export const ThingNewRelatedThing = (props) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [formRef, setFormRef] = useState();
    const thingTypesList = useSelector((state) => state.account.thingTypesList);
    const lastPostType = useSelector((state) => state.thing.lastPostType);
    const lastPostStatus = useSelector((state) => state.thing.lastPostStatus);
    const lastPostData = useSelector((state) => state.thing.lastPostData);
    const newThingName = useRef();
    const notificationKey = "NK_NewRelatedThing";

    useEffect(() => {
        if (formRef) {
            if (lastPostStatus) {
                if (
                    lastPostType === ThingPostTypes.NEW_RELATED_THING &&
                    lastPostStatus === LastPostStatus.OK
                ) {
                    form.resetFields();
                    showNotificationSuccess(
                        notificationKey,
                        'Thing "' + newThingName.current + '" created',
                        null
                    );
                    props.onClose();
                } else if (lastPostStatus === LastPostStatus.FAIL) {
                    hideNotification(notificationKey);
                }
            } else {
                form.resetFields(); // need this to force Form initialValues to reload
                // If we don't use the formRef the above line results in following console warning on page load
                // Warning: Instance created by `useForm` is not connect to any Form element. Forget to pass `form` prop?
                // See formRef solution at https://github.com/ant-design/ant-design/issues/21543#issuecomment-602008000
            }
        }
    }, [form, formRef, lastPostStatus, lastPostType, props]);

    const submitNewThingForm = (values) => {
        values.thingTypeId = values.thingTypeId.substring(
            0,
            values.thingTypeId.indexOf(":")
        );
        //console.info("newRelatedThingForm Values", values);
        newThingName.current = values.thingName;
        dispatch(createRelatedThing(values));
        dispatch(setReloadForRelatedThingsList());
    };

    const initialFormValues = {
        thingName: "",
        thingTypeId: undefined,
    };

    return (
        <Fragment>
            <h3>New Related Thing</h3>
            <Form
                name="newRelatedThingForm"
                form={form}
                ref={setFormRef}
                onFinish={submitNewThingForm}
                layout="vertical"
                hideRequiredMark
                scrollToFirstError
                initialValues={initialFormValues}
            >
                <ThingInput visible={true} label="Name">
                    <Form.Item
                        name="thingName"
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: "Required",
                                whitespace: true,
                            },
                            {
                                validator: (rule, value, callback) =>
                                    createUniqueThingNameValidator(value, null),
                            },
                        ]}
                    >
                        <Input
                            placeholder="Enter a name for your thing"
                            allowClear
                            autoFocus
                            autoComplete="off"
                        />
                    </Form.Item>
                </ThingInput>
                <ThingInput visible={true} label="Type">
                    <Form.Item
                        name="thingTypeId"
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: "Required",
                            },
                            {
                                validator: (rule, value, callback) =>
                                    createTreeSelectValidator(
                                        value,
                                        thingTypesList,
                                        "Invalid Thing Type"
                                    ),
                            },
                        ]}
                    >
                        <TreeSelect
                            name="thingTypeId"
                            showSearch
                            treeNodeFilterProp="title"
                            dropdownClassName="tt-ted-dropdown"
                            placeholder="Select thing type (type to search)"
                            treeData={thingTypesList}
                            autoComplete="off"
                        />
                    </Form.Item>
                </ThingInput>
                <Form.Item>
                    <Button type="danger" htmlType="submit">
                        Save Related Thing
                    </Button>
                    <Button
                        htmlType="reset"
                        onClick={props.onClose}
                        className="tt-button"
                    >
                        Discard Changes
                    </Button>
                </Form.Item>
            </Form>
            {lastPostStatus === LastPostStatus.ACTIVE ? (
                <Alert
                    type="info"
                    message={'Creating Thing "' + newThingName.current + '"...'}
                />
            ) : null}
            {lastPostStatus === LastPostStatus.FAIL ? (
                <Alert
                    type="error"
                    message={
                        'Thing "' +
                        newThingName.current +
                        '" could not be created'
                    }
                    description={
                        lastPostData && lastPostData.message
                            ? lastPostData.message
                            : "An error occurred"
                    }
                />
            ) : null}
        </Fragment>
    );
};

export default ThingNewRelatedThing;
