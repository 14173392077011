import { Button, Card, Col, Spin } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getInviteAsync,
} from "../../redux/actions/personActions";
import { showNotificationError } from "../../functions";
import { Application, Routes } from "../../constants";
import { push } from "connected-react-router";
import LoginButtons from "../login/LoginButtons";

export const InviteContainer = (props) => {
    const invitationId = props?.match?.params?.inviteId; // Get the inviteId from the URL
    const notificationKey = "NK_InviteContainer";

    const dispatch = useDispatch();

    const isAuthReady = useSelector((state) => state.auth.isReady);
    const isAuthenticated = useSelector((state) => state.auth.authenticated);

    const [isProcessingInvite, setIsProcessingInvite] = useState(false);
    const [inviteData, setInviteData] = useState(null);

    // Effect to get invite data
    useEffect(() => {
        const getInviteData = async () => {
            try {
                setIsProcessingInvite(true);
                let inviteData = await getInviteAsync(invitationId);
                setInviteData(inviteData);
            } catch (ex) {
                console.log(ex);
                showNotificationError(
                    notificationKey,
                    "Failed to get invitation information!",
                    null
                );
            } finally {
                setIsProcessingInvite(false);
            }
        };

        if (!isAuthReady) {
            return; // Can't check the invite status until auth is ready
        }

        getInviteData();
    }, [isAuthReady, invitationId, setIsProcessingInvite]);

    const renderInvite = (invite) => {
        if (!invite) {
            return (
                <div>
                    Invitation was not found. Please contact the person who sent
                    you the invite.
                </div>
            );
        }

        if (invite.isExpired) {
            return (
                <div>
                    This invitation has expired. The owner of the account has
                    been notified.
                </div>
            );
        }

        if (invite.isAccepted) {
            return <div>This invitation has already been accepted.</div>;
        }

        if (!invite.canUserAcceptInvite) {
            return (
                <Fragment>
                    <div>
                        You can't accept this invite as you are already
                        associated with another person for this account.
                    </div>
                </Fragment>
            );
        }

        const invitePageURL = Routes.INVITE_ACCEPT.replace(":inviteId", invitationId);
        return (
            <Fragment>
                {isAuthenticated && (
                    <div>{invite.primaryOwner} has invited you to their {Application.Name} account<br /><br /></div>
                )}
                {!isAuthenticated && (
                    <div>{invite.primaryOwner} has invited you to their {Application.Name} account<br /><br /> Use one of the following options to login and accept the invitation</div>
                )}
                <div className="tt-spacer" />
                {!isAuthenticated && (
                    <LoginButtons message={null} returnUrl={invitePageURL} />
                )}
                {isAuthenticated && (
                    <Button type="primary"
                        onClick={() => dispatch(push(invitePageURL))}
                    >Click here to accept the invitation</Button>
                )}
            </Fragment>
        );
    };

    if (isProcessingInvite) {
        return <Spin />;
    }

    return (
        <Col offset={6} span={12}>
            <Card title="Account Invitation">
                {renderInvite(inviteData)}
            </Card>
        </Col>
    );
};

export default InviteContainer;
