import {
    ActionTypes,
    LastPostStatus,
    ThingUpdateActions,
} from "../../constants";

import { ThingActions } from "../actions/thingActions";

const initialState = {
    isSaving: false,
    isDirty: false,

    isThingLoading: false,
    activeThingId: null,
    activeThing: null,
    lastThingName: null,
    thingError: null,
    newThing: false,
    lastPostType: null,
    lastPostStatus: null,
    lastPostData: null,
    activeThingPeople: null,
    activeThingRelatedThings: null,
    activeThingLinks: null,
};

function thingReducer(state = initialState, action) {
    switch (action.type) {
        case ThingActions.THING_SET_IS_SAVING:
            return {
                ...state,
                isSaving: action.data,
            };

        case ThingActions.THING_SET_IS_DIRTY:
            return {
                ...state,
                isDirty: action.data,
            };

        case ThingActions.GET_THING_BEGIN:
            return {
                ...state,
                isThingLoading: true,
                activeThingId: null,
                activeThing: null,
                lastThingName: null,
                thingError: null,
                newThing: false,
            };
        case ThingActions.GET_THING_END_OK:
            var people = [];
            var things = [];
            var links = [];
            if (action.data) {
                action.data.relatedPeople.forEach((p) => {
                    var newP = {
                        ...p,
                        existing: true,
                        action: ThingUpdateActions.NOCHANGE,
                    };
                    people.push(newP);
                });

                action.data.relatedThings.forEach((t) => {
                    var newT = {
                        ...t,
                        existing: true,
                        action: ThingUpdateActions.NOCHANGE,
                    };
                    things.push(newT);
                });

                action.data.links.forEach((l) => {
                    var newL = {
                        ...l,
                        tempLinkId: l.linkId,
                        existing: true,
                        action: ThingUpdateActions.NOCHANGE,
                    };
                    links.push(newL);
                });
            }

            return {
                ...state,
                isThingLoading: false,
                activeThingId: action.data.thingId,
                activeThing: action.data,
                lastThingName: action.data.name,
                activeThingPeople: people,
                activeThingRelatedThings: things,
                activeThingLinks: links,
                lastPostType: null,
                lastPostStatus: null,
                lastPostData: null,
            };
        case ThingActions.GET_THING_END_FAIL:
            return {
                ...state,
                isThingLoading: false,
                thingError: action.data,
            };

        case ActionTypes.NEW_THING:
            return {
                ...state,
                isThingLoading: false,
                activeThingId: null,
                activeThing: null,
                lastThingName: null,
                thingError: null,
                newThing: true,
                lastPostType: null,
                lastPostStatus: null,
                lastPostData: null,
                activeThingPeople: [],
                activeThingRelatedThings: [],
                activeThingLinks: [],
            };

        case ActionTypes.UNLOAD_THING:
            return {
                ...state,
                isThingLoading: false,
                activeThingId: null,
                activeThing: null,
                lastThingName: null,
                thingError: null,
                newThing: false,
                lastPostType: null,
                lastPostStatus: null,
                lastPostData: null,
                activeThingPeople: null,
                activeThingRelatedThings: null,
                activeThingLinks: null,
            };

        case ActionTypes.UPDATE_NEW_THING_PEOPLE:
            var primaryOwner = action.data;
            var newThingPeopleList = [];
            var newPrimaryOwner = {
                thingPersonId: null,
                personId: primaryOwner.personId,
                firstName: primaryOwner.firstName,
                lastName: primaryOwner.lastName,
                accountPersonTypeId: primaryOwner.accountPersonTypeId,
                existing: false,
                action: ThingUpdateActions.NEW,
            };
            newThingPeopleList.push(newPrimaryOwner);
            return {
                ...state,
                activeThingPeople: newThingPeopleList,
            };

        case ActionTypes.POST_THING_TYPE:
            return {
                ...state,
                lastPostType: action.data,
            };
        case ActionTypes.POST_THING_RESET:
            return {
                ...state,
                lastPostType: null,
                lastPostStatus: null,
                lastPostData: null,
            };
        case ActionTypes.POST_THING:
            return {
                ...state,
                lastPostStatus: LastPostStatus.ACTIVE,
                lastPostData: null,
            };
        case ActionTypes.POST_THING_OK:
            return {
                ...state,
                lastPostStatus: LastPostStatus.OK,
                lastPostData: action.data,
            };
        case ActionTypes.POST_THING_FAIL:
            return {
                ...state,
                lastPostStatus: LastPostStatus.FAIL,
                lastPostData: action.data,
            };

        case ActionTypes.UPDATE_RELATED_PEOPLE:
            return {
                ...state,
                activeThingPeople: action.data,
            };
        case ActionTypes.UPDATE_RELATED_THINGS:
            return {
                ...state,
                activeThingRelatedThings: action.data,
            };
        case ActionTypes.UPDATE_RELATED_LINKS:
            return {
                ...state,
                activeThingLinks: action.data,
            };

        default:
            return state;
    }
}

export default thingReducer;
