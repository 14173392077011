import {
    ActionTypes,
    EMPTY_GUID,
    CookieKeys,
} from "../../constants";
import cookieService from "../../cookies/CookieService";
import { jsonClone, personTypeToSortOrder } from "../../functions";

import { AccountActions } from "../actions/accountActions";
import { createAsyncData } from "../AsyncData";

export const MyAccountsData = createAsyncData("MyAccounts");
export const AccountListsData = createAsyncData("AccountLists");

const initialState = {
    // Account Load
    accountLoad: true,
    isAccountLoading: false,
    accountLoadingError: null,

    // My Accounts
    myAccounts: MyAccountsData.reducer(),
    currentAccount: null,

    // Account Data
    newAccount: false,
    accountId: null,
    userPersonId: null,
    accountType: null,
    accountCountryCode: null,
    accountLanguageCode: null,
    accountCurrency: null,

    // Account Lists Load
    accountListsLoad: true,
    accountListsLoaded: false,
    accountListsLoading: false,
    listsError: null,

    // Account Lists Data
    personTypesList: null,
    peopleList: null,
    linkTypesList: null,
    frequenciesList: null,
    paymentThingTypesList: null,
    paymentTypesList: null,
    paymentMethodsList: null,
    thingTypesList: null,
    reminderSchedulesList: null,
    reminderScheduleDetailList: null,
    dispositionActionsList: null,
    tooltipsList: null,

    dashboardMessageLoad: true,
    dashboardMessage: null,
    dashboardMessageLoading: false,
    dashboardMessageError: null,
    dashboardTipLoad: true,
    dashboardTip: null,
    dashboardTipLoading: false,
    dashboardTipError: null,
    thingsMessageLoad: true,
    thingsMessage: null,
    thingsMessageLoading: false,
    thingsMessageError: null,
    importantMessageLoad: true,
    importantMessage: null,
    importantMessageLoading: false,
    importantMessageError: null,
};

const sortPeopleList = (peopleList) => {
    return peopleList.sort((p1, p2) => {
        let p1Type = personTypeToSortOrder(p1.accountPersonTypeId);
        let p2Type = personTypeToSortOrder(p2.accountPersonTypeId);

        if (p1Type < p2Type) return -1;
        if (p1Type > p2Type) return 1;

        if (p1.fullName < p2.fullName) return -1;
        if (p1.fullName > p2.fullName) return 1;

        // People are equal
        return 0;
    });
};

export function accountReducer(originalState = initialState, action) {
    // Propagate reducer
    let state = { ...originalState };
    state.myAccounts = MyAccountsData.reducer(state.myAccounts, action);
    state.accountLists = AccountListsData.reducer(state.accountLists, action);

    switch (action.type) {
        case AccountActions.SET_CURRENT_ACCOUNT:
            // Also set the cookie here
            console.log("Setting last selected account cookie")
            cookieService.set(CookieKeys.LastSelectedAccount, {
                accountId: action.data?.accountId,
            });
            return {
                ...state,
                accountLoad: false,
                newAccount: false,
                isAccountLoading: false,
                currentAccount: action.data,
            };

        case ActionTypes.GET_ACCOUNT_LOAD: // TODO
            return {
                ...state,
                accountLoad: true,
            };
        case AccountActions.GET_ACCOUNT_BEGIN:
            return {
                ...state,
                newAccount: false,
                accountId: null,
                accountType: null,
                accountCountryCode: null,
                accountLanguageCode: null,
                accountCurrency: null,

                isAccountLoading: true,
                accountLoadingError: null,
            };
        case AccountActions.GET_ACCOUNT_END_OK:
            if (action.data.accountId === EMPTY_GUID) {
                return {
                    ...state,
                    newAccount: true,
                    isAccountLoading: false,
                    accountLoad: false,
                };
            } else {
                return {
                    ...state,
                    newAccount: false,
                    accountId: action.data.accountId,
                    userPersonId: action.data.myPersonId,
                    accountType: action.data.accountType,
                    accountCountryCode: action.data.countryCode,
                    accountLanguageCode: action.data.languageCode,
                    accountCurrency: action.data.currencyCode,

                    isAccountLoading: false,
                    accountLoad: false,
                };
            }
        case AccountActions.GET_ACCOUNT_END_FAIL:
            return {
                ...state,
                accountLoadingError: action.data,
                isAccountLoading: false,
                accountLoad: false,
            };

        case AccountActions.UPDATE_PEOPLE_LIST: {
            return {
                ...state,
                peopleList: action.data,
            };
        }

        case AccountActions.UPDATE_PERSON_IN_LIST: {
            let personIndex = state.peopleList.findIndex(
                (p) => p.personId === action.data.personId
            );
            let isAddingPerson = personIndex === -1;

            let newPeopleList;
            if (isAddingPerson) {
                //console.log("Person added to people list: ", action.data);
                newPeopleList = jsonClone(state.peopleList);
                newPeopleList.push(action.data);
            } else {
                //console.log("People list updated at: ", personIndex, state.peopleList[personIndex], action.data);
                newPeopleList = state.peopleList
                    .slice(0, personIndex)
                    .concat(action.data) // Insert the person where the existing person was
                    .concat(state.peopleList.slice(personIndex + 1));
            }
            newPeopleList = sortPeopleList(newPeopleList);
            return {
                ...state,
                peopleList: newPeopleList,
            };
        }

        case AccountActions.REMOVE_PERSON_FROM_LIST: {
            let personIndex = state.peopleList.findIndex(
                (p) => p.personId === action.data
            );

            if (personIndex === -1)
                // Person wasn't found, so nothing to do
                return state;

            let newPeopleList = state.peopleList
                .slice(0, personIndex)
                // The person being removed was here
                .concat(state.peopleList.slice(personIndex + 1));

            return {
                ...state,
                peopleList: newPeopleList,
            };
        }

        case ActionTypes.GET_ACCOUNT_LISTS_LOAD: // TODO
            return {
                ...state,
                accountListsLoad: true,
            };
        case AccountActions.GET_ACCOUNT_LISTS_BEGIN:
            return {
                ...state,
                accountListsLoading: true,
                accountListsLoaded: false,
                listsError: null,

                personTypeList: null,
                linkTypesList: null,
                frequenciesList: null,
                paymentThingTypesList: null,
                paymentTypesList: null,
                paymentMethodsList: null,
                thingTypesList: null,
                reminderSchedulesList: null,
                reminderScheduleDetailList: null,
                dispositionActionsList: null,
                tooltipsList: null,
            };
        case AccountActions.GET_ACCOUNT_LISTS_END_OK:
            if (action.data === "") {
                return {
                    ...state,
                    accountListsLoading: false,
                    accountListsLoad: true,
                };
            } else {
                var personTypes = [];
                if (action.data) {
                    action.data.personTypes.forEach((p) => {
                        var newP = {
                            ...p,
                            sort: personTypeToSortOrder(p.accountPersonTypeId).toString().padStart(2, "0"),
                        };
                        personTypes.push(newP);
                    });
                }

                return {
                    ...state,
                    accountListsLoading: false,
                    accountListsLoaded: true,
                    accountListsLoad: false,

                    personTypesList: personTypes,
                    peopleList: action.data.people,
                    linkTypesList: action.data.linkTypes,
                    frequenciesList: action.data.frequencies,
                    paymentThingTypesList: action.data.paymentThingTypes,
                    paymentTypesList: action.data.paymentTypes,
                    paymentMethodsList: action.data.paymentMethods,
                    thingTypesList: action.data.thingTypes,
                    reminderSchedulesList: action.data.reminderSchedules,
                    reminderScheduleDetailList:
                        action.data.reminderScheduleDetail,
                    dispositionActionsList: action.data.dispositionActions,
                    tooltipsList: action.data.tooltips,
                };
            }
        case ActionTypes.GET_ACCOUNT_LISTS_END_FAIL:
            return {
                ...state,
                listsError: action.data,
                accountListsLoading: false,
                accountListsLoad: false,
            };

        case ActionTypes.POST_NEW_ACCOUNT:
            return {
                ...state,
                accountId: null,
                newAccount: false,
                accountCountryCode: null,
                accountLanguageCode: null,
                isAccountLoading: true,
                accountLoadingError: null,
            };
        case ActionTypes.POST_NEW_ACCOUNT_OK:
            return {
                ...state,
                newAccount: false,
                accountId: action.data.accountId,
                accountCountryCode: action.data.countryCode,
                accountLanguageCode: action.data.languageCode,
                isAccountLoading: false,
                accountLoad: false,
            };
        case ActionTypes.POST_NEW_ACCOUNT_FAIL:
            return {
                ...state,
                accountLoadingError: action.data,
                isAccountLoading: false,
            };

        // TODO: Move the following reducers

        case ActionTypes.GET_DASHBOARD_MESSAGE:
            return {
                ...state,
                dashboardMessage: null,
                dashboardMessageLoading: true,
                dashboardMessageError: null,
            };
        case ActionTypes.GET_DASHBOARD_MESSAGE_OK:
            return {
                ...state,
                dashboardMessage: action.data,
                dashboardMessageLoading: false,
                dashboardMessageLoad: false,
            };
        case ActionTypes.GET_DASHBOARD_MESSAGE_FAIL:
            return {
                ...state,
                dashboardMessageError: action.data,
                dashboardMessageLoading: false,
            };

        case ActionTypes.GET_DASHBOARD_TIP:
            return {
                ...state,
                dashboardTip: null,
                dashboardTipLoading: true,
                dashboardTipError: null,
            };
        case ActionTypes.GET_DASHBOARD_TIP_OK:
            return {
                ...state,
                dashboardTip: action.data,
                dashboardTipLoading: false,
                dashboardTipLoad: false,
            };
        case ActionTypes.GET_DASHBOARD_TIP_FAIL:
            return {
                ...state,
                dashboardTipError: action.data,
                dashboardTipLoading: false,
            };

        case ActionTypes.GET_THINGS_MESSAGE:
            return {
                ...state,
                thingsMessage: null,
                thingsMessageLoading: true,
                thingsMessageError: null,
            };
        case ActionTypes.GET_THINGS_MESSAGE_OK:
            return {
                ...state,
                thingsMessage: action.data,
                thingsMessageLoading: false,
                thingsMessageLoad: false,
            };
        case ActionTypes.GET_THINGS_MESSAGE_FAIL:
            return {
                ...state,
                thingsMessageError: action.data,
                thingsMessageLoading: false,
            };

        default:
            return state;
    }
}

export default accountReducer;
