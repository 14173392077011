import React from "react";
import { useSelector } from "react-redux";
import { Popover, Row, Col } from "antd";
import parse from "html-react-parser";
import { Defaults } from "../../constants";

export const ThingInput = (props) => {
    const tooltips = useSelector((state) => state.account.tooltipsList);
    const visible = props.visible;

    let trigger = "hover";
    if (props.tooltiptrigger) {
        trigger = props.tooltiptrigger;
    }
    let selectedItem = null;
    let title = "TOOLTIP KEY NOT FOUND";
    let content = "";

    let placement = "leftTop"; // options: top, topLeft, topRight, bottom, bottomLeft, bottomRight, left, leftTop, leftBottom, right, rightTop, rightBottom
    if (props.placement) {
        placement = props.placement;
    }

    let showPopover = false;
    if (props.tooltipkey) {
        showPopover = true;
        if (tooltips) {
            selectedItem = tooltips.filter(
                (t) => t.key === props.tooltipkey
            )[0];
            if (selectedItem) {
                title = selectedItem.title;
                content = selectedItem.content;
                if (content === null) {
                    content = "";
                }
            }
        }
    }
    //else {
    // temporarily add a tooltip until form is completed
    //showPopover = true;
    //title = props.label;
    //content = "Popover details need to created and a tooltipkey assigned to this ThingInput";
    //}

    const renderInput = () => {
        if (!props.label) {
            return (
                <div>
                    <Row className="tt-ted-input-field">
                        <Col span={24}>{props.children}</Col>
                    </Row>
                </div>
            );
        }
        return (
            <div>
                <Row className="tt-ted-input-label">
                    <Col span={24}>
                        <span>{props.label}</span>
                    </Col>
                </Row>
                <Row className="tt-ted-input-field">
                    <Col span={24}>{props.children}</Col>
                </Row>
            </div>
        );
    };

    if (!visible) {
        return null;
    }

    if (showPopover) {
        return (
            <Popover
                trigger={trigger}
                placement={placement}
                content={parse(content)}
                title={title}
                mouseEnterDelay={Defaults.POPOVER_MOUSE_ENTER_DELAY_SLOW}
                mouseLeaveDelay={Defaults.POPOVER_MOUSE_LEAVE_DELAY}
            >
                {renderInput()}
            </Popover>
        );
    }

    return renderInput();
};

export default ThingInput;
