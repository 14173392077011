import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Input, Spin } from "antd";

import {
    showNotificationError,
    showNotificationSuccess,
} from "../../functions";
import ThingInput from "../things/ThingInput";
import { ajaxPostAsync2 } from "../../redux/actions/ajaxActions";
import { APIEndpoints } from "../../constants";
import { setImportantMessage } from "../../redux/actions/importantThingsActions";
import JoditEditor from "../../shared/JoditEditorTemp";
//import JoditEditor from 'jodit-react';
// TODO: We need to restore JoditEditorTemp to jodit-react once https://github.com/jodit/jodit-react/issues/106 is resolved

const ImportantMessageTitleMaxLength = 40;

export const ImportantMessageEditor = (props) => {
    const dispatch = useDispatch();
    const notificationKey = "NK_ImportantMessage";

    const { onClose, isVisible } = props;

    const importantMessage = useSelector(
        (state) => state.importantThings.importantMessage
    );
    const importantMessageInformationText =
        "Use the following fields to write a message to your Account Inheritor(s). They will not see this message until they inherit the account.";
    const [isSavingMessage, setIsSavingMessage] = useState(false);

    const [form] = Form.useForm();
    const [formRef, setFormRef] = useState();
    const initialFormValues = {
        title: importantMessage?.title ?? "",
        body: importantMessage?.body ?? "",
    };

    const messageEditor = useRef(null);
    const [messageBody, setMessageBody] = useState(initialFormValues.body);
    const messageEditorConfig = {
        // all options from https://xdsoft.net/jodit/doc/
        readonly: false,
        uploader: {
            insertImageAsBase64URI: true,
        },
        toolbarButtonSize: "small",
        enter: "BR",
        defaultMode: "1",
        toolbarSticky: false,
        buttons:
            ",,,,,,,,,,,,,,,,,,,,,,,table,link,|,align,undo,redo,\n,selectall,cut,copy,paste,copyformat,|,hr,symbol,fullsize,print",
        height: window.innerHeight - 470,
        autofocus: true,
    };

    // Effect: When the editor changes visibility, reset the message
    useEffect(() => {
        if (formRef) {
            // console.log("Reseting ImportantMessageEditor fields", importantMessage);
            form.resetFields();
        }
    }, [isVisible, form, formRef, importantMessage]);

    const onFinishForm = async (values) => {
        setIsSavingMessage(true);

        try {
            let response = await ajaxPostAsync2(
                `${APIEndpoints.Account}ImportantMessage`,
                {
                    title: values.title,
                    body: messageBody,
                }
            );

            let updatedMessage = response.data;
            dispatch(setImportantMessage(updatedMessage));

            showNotificationSuccess(
                notificationKey,
                "Successfully updated message!",
                null
            );
            onClose();
        } catch (ex) {
            showNotificationError(
                notificationKey,
                "Failed to update message!",
                null
            );
        } finally {
            setIsSavingMessage(false);
        }
    };

    const cancelChanges = () => {
        setMessageBody(initialFormValues.body);
        props.onClose();
    };

    const renderButtonBar = () => {
        if (isSavingMessage) {
            return <Spin />;
        }

        return (
            <Fragment>
                <Button type="danger" htmlType="submit">
                    Update Message
                </Button>
                <Button
                    htmlType="reset"
                    onClick={cancelChanges}
                    className="tt-button"
                >
                    Discard Changes
                </Button>
            </Fragment>
        );
    };

    return (
        <Fragment>
            <h3>Important Message</h3>
            <div>{importantMessageInformationText}</div>
            <div className="tt-spacer" />
            <div className="tt-ted-html-details-editor-warning">
                <strong className="tt-color-red">
                    PLEASE DO NOT USE THIS AREA TO STORE USERNAMES AND PASSWORDS
                </strong>
                <br />
                We do not recommend storing passwords and other account
                credentials here. If you do not have an existing system to
                securely store account credentials, passwords etc. please read
                &nbsp;
                <a
                    href="/info/passwords"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Storing Passwords Securely
                </a>{" "}
                for more information.
            </div>
            <Form
                name="importantForm"
                form={form}
                ref={setFormRef}
                onFinish={onFinishForm}
                layout="vertical"
                hideRequiredMark
                scrollToFirstError
                initialValues={initialFormValues}
            >
                <ThingInput visible={true} label="Title">
                    <Form.Item
                        name="title"
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: "Required",
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input
                            placeholder="Enter a title for the message"
                            allowClear
                            maxLength={ImportantMessageTitleMaxLength}
                            autoComplete="off"
                        />
                    </Form.Item>
                </ThingInput>
                <ThingInput visible={true} label="Message">
                    <JoditEditor
                        ref={messageEditor}
                        value={messageBody}
                        config={messageEditorConfig}
                        tabIndex={1} // tabIndex of textarea
                        onBlur={(newValue) =>
                            setMessageBody(newValue.target.innerHTML)
                        } // preferred to use only this option to update the content for performance reasons
                        // .target.innerHTML added due to Open issue https://github.com/jodit/jodit-react/issues/93
                        onChange={(newContent) => {}}
                        className="tt-tc-details-editor"
                    />
                </ThingInput>
                <div className="tt-spacer" />
                <Form.Item>{renderButtonBar()}</Form.Item>
            </Form>
        </Fragment>
    );
};

export default ImportantMessageEditor;
