import keyMirror from "keymirror";

import { ajaxGetAsync } from "./ajaxActions";

import store from "../store";
import { APIEndpoints } from "../../constants";

export const ImportantThingsActions = keyMirror({
    GET_IMPORTANT_THINGS_LIST_BEGIN: null,
    GET_IMPORTANT_THINGS_LIST_END_OK: null,
    GET_IMPORTANT_THINGS_LIST_END_FAIL: null,
    GET_IMPORTANT_THINGS_LIST_END_IGNORE: null,

    SET_IMPORTANT_THING_LIST: null,

    GET_IMPORTANT_MESSAGE_BEGIN: null,
    GET_IMPORTANT_MESSAGE_END_OK: null,
    GET_IMPORTANT_MESSAGE_END_FAIL: null,
    GET_IMPORTANT_MESSAGE_END_IGNORE: null,

    SET_IMPORTANT_MESSAGE: null,
});

export function getImportantThingsList() {
    let state = store.getState();
    if (!state.importantThings.isImportantThingsLoading) {
        return async (dispatchAsync) => {
            await ajaxGetAsync(
                APIEndpoints.Thing + "ImportantThingsList",
                ImportantThingsActions.GET_IMPORTANT_THINGS_LIST_BEGIN,
                ImportantThingsActions.GET_IMPORTANT_THINGS_LIST_END_OK,
                ImportantThingsActions.GET_IMPORTANT_THINGS_LIST_END_FAIL,
                dispatchAsync
            );
        };
    }

    // Get has already suceeded or is in progress
    return {
        type: ImportantThingsActions.GET_IMPORTANT_THINGS_LIST_END_IGNORE,
    };
}

export function setImportantThingList(importantThings) {
    return {
        type: ImportantThingsActions.SET_IMPORTANT_THING_LIST,
        data: importantThings,
    };
}

export function getImportantMessage() {
    let state = store.getState();
    if (!state.importantThings.isImportantMessageLoading) {
        return async (dispatchAsync) => {
            await ajaxGetAsync(
                APIEndpoints.Account + "ImportantMessage",
                ImportantThingsActions.GET_IMPORTANT_MESSAGE_BEGIN,
                ImportantThingsActions.GET_IMPORTANT_MESSAGE_END_OK,
                ImportantThingsActions.GET_IMPORTANT_MESSAGE_END_FAIL,
                dispatchAsync
            );
        };
    }

    // Get has already suceeded or is in progress
    return {
        type: ImportantThingsActions.GET_IMPORTANT_MESSAGE_END_IGNORE,
    };
}

export function setImportantMessage(importantMessage) {
    return {
        type: ImportantThingsActions.SET_IMPORTANT_MESSAGE,
        data: importantMessage,
    };
}
