import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, Select, Form, Drawer, Button } from "antd";
import parse from "html-react-parser";

import ThingInput from "./ThingInput";
import { Defaults, ThingPostTypes, LastPostStatus } from "../../constants";
import ThingDetailsEditor from "./ThingDetailsEditor";
import LoadingIndicator from "../../shared/LoadingIndicator";
import { sleep, setFocus } from "../../functions";
import { resetThingPost } from "../../redux/actions";
import { getAccountLists } from "../../redux/actions/accountActions";
import ThingNewDispositionAction from "./ThingNewDispositionAction";

export const ThingDispositionDetailsContainer = (props) => {
    const dispatch = useDispatch();
    const { Option } = Select;
    const form = props.form;
    const dispositionActionSelect = useRef(null);
    const initialFormValues = props.initialFormValues;
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [dispositionDetails, setDispositionDetails] = useState(
        initialFormValues && initialFormValues.dispositionDetails
            ? initialFormValues.dispositionDetails
            : ""
    );
    const [editorDrawerVisible, setEditorDrawerVisible] = useState(false);
    const dispositionActions = useSelector(
        (state) => state.account.dispositionActionsList
    );
    const [
        resetDispositionActionSelectField,
        setResetDispositionActionSelectField,
    ] = useState(false);
    const newDispositionActionKey = "NEW_DISPOSITION_ACTION";
    const lastPostType = useSelector((state) => state.thing.lastPostType);
    const lastPostStatus = useSelector((state) => state.thing.lastPostStatus);
    const lastPostData = useSelector((state) => state.thing.lastPostData);

    useEffect(() => {
        if (lastPostStatus) {
            if (
                lastPostType === ThingPostTypes.NEW_DISPOSITION_ACTION &&
                lastPostStatus === LastPostStatus.OK &&
                lastPostData
            ) {
                // new disposition action type was created, so reload the disposition actions list and set the id to the new id
                var newDispositionActionId =
                    lastPostData.data.dispositionActionId;
                sleep(500).then(() => {
                    form.setFieldsValue({
                        dispositionActionId: newDispositionActionId,
                    });
                    setFocus(dispositionActionSelect.current);
                    props.handleValuesChange();
                });
                dispatch(getAccountLists());
                dispatch(resetThingPost());
            }
        }

        // hacks to force select placeholder to reappear
        if (resetDispositionActionSelectField === true) {
            form.setFieldsValue({ dispositionActionId: undefined });
            setResetDispositionActionSelectField(false);
        }
    }, [
        dispatch,
        form,
        lastPostData,
        lastPostStatus,
        lastPostType,
        props,
        resetDispositionActionSelectField,
    ]);

    // Effect to reset state when the initialForm values change
    useEffect(() => {
        setDispositionDetails(initialFormValues?.dispositionDetails ?? "");
    }, [initialFormValues]);

    const handleEditDetailsClick = () => {
        setEditorDrawerVisible(true);
    };

    const handleEditorDrawerClose = () => {
        setEditorDrawerVisible(false);
    };

    const updateDispositionDetails = (content) => {
        setDispositionDetails(content);
        form.setFieldsValue({ dispositionDetails: content }); // force form isFieldsTouched to register change
        props.handleValuesChange();
    };

    const handleNewItemClick = () => {
        setResetDispositionActionSelectField(true);
        dispatch(resetThingPost());
        setDrawerVisible(true);
    };

    const handleDrawerClose = () => {
        setDrawerVisible(false);
        setFocus(dispositionActionSelect.current);
    };

    const handleDispositionActionSelect = (dispositionActionId) => {
        if (dispositionActionId === newDispositionActionKey) {
            handleNewItemClick();
            return;
        }
        if (dispositionActionId === "") {
            setResetDispositionActionSelectField(true);
            return;
        }
    };

    if (!dispositionActions) {
        return <LoadingIndicator />;
    }

    return (
        <div className="tt-ted-panel-container">
            <Input.Group>
                <ThingInput
                    visible={true}
                    label="Disposition Action"
                    tooltipkey="ThingInput_DispositionAction"
                >
                    <Form.Item name="dispositionActionId" hasFeedback>
                        <Select
                            name="dispositionActionId"
                            dropdownClassName="tt-ted-dropdown"
                            placeholder="Select a disposition action"
                            ref={dispositionActionSelect}
                            onSelect={handleDispositionActionSelect}
                        >
                            <Option key="" value=""></Option>
                            <Option
                                key={newDispositionActionKey}
                                value={newDispositionActionKey}
                            >
                                {"< Add a New Disposition Action >"}
                            </Option>
                            {dispositionActions.map((da) => (
                                <Option
                                    key={da.dispositionActionId}
                                    value={da.dispositionActionId}
                                >
                                    {da.localizedName}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </ThingInput>
            </Input.Group>

            <ThingInput visible={true} label="Dispostion Details">
                <Form.Item name="dispositionDetails" className="tt-hidden">
                    <Input />
                </Form.Item>
                <div className="tt-ted-html-details">
                    {dispositionDetails ? (
                        parse(dispositionDetails)
                    ) : (
                        <span className="tt-ted-html-details-placeholder">
                            Click the Edit Disposition Details button below to
                            modify the content that appears here. You can use
                            rich text to describe how you want this Thing to be
                            dealt with by your Account Inheritor.
                        </span>
                    )}
                </div>
                <Button onClick={handleEditDetailsClick}>
                    Edit Disposition Details
                </Button>
            </ThingInput>

            <Drawer
                title="New Disposition Action"
                placement="right"
                width={Defaults.DRAWER_WIDTH}
                closable={false}
                onClose={handleDrawerClose}
                visible={drawerVisible}
            >
                <ThingNewDispositionAction onClose={handleDrawerClose} />
            </Drawer>

            <Drawer
                title="Disposition Details"
                placement="right"
                width={Defaults.EDITOR_DRAWER_WIDTH}
                closable={false}
                onClose={handleEditorDrawerClose}
                visible={editorDrawerVisible}
            >
                <ThingDetailsEditor
                    title="Disposition Details"
                    content={dispositionDetails}
                    onClose={handleEditorDrawerClose}
                    onChange={updateDispositionDetails}
                />
            </Drawer>
        </div>
    );
};

export default ThingDispositionDetailsContainer;
