import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Spin, Card, Col } from "antd";

import LoginButtons from "./LoginButtons";
import { push } from "connected-react-router";

const LoginContainer = (props) => {
    const { returnUrl } = props;
    const isAuthServiceReady = useSelector((state) => state.auth.isReady);

    const dispatch = useDispatch();
    const isAuthenticated = useSelector((state) => state.auth.authenticated);
    // Effect to redirect to return Url if the user is already signed in (or a sign-in completes in the background)
    useEffect(() => {
        if (isAuthenticated) {
            dispatch(push(returnUrl));
        }
    }, [dispatch, isAuthenticated, returnUrl]);

    if (!isAuthServiceReady) {
        return <Spin />;
    }

    // Singup Code Prompt/Buttons
    return (
        <Fragment>
            <Col offset={6} span={12}>
                <Card title="Existing Users">
                    <LoginButtons returnUrl={returnUrl} />
                </Card>
            </Col>
        </Fragment>
    );
};

export default LoginContainer;
