import { APIEndpoints, Defaults } from "../constants";
import { ajaxGetAsync2 } from "../redux/actions/ajaxActions";

export const hasUniquePersonNameAsync = async (
    firstName,
    lastName,
    allPeople,
    selectedPerson
) => {
    let normalizedFirstName = firstName.toLowerCase().trim();
    let normalizedLastName = lastName.toLowerCase().trim();

    if (
        normalizedFirstName === "" ||
        normalizedFirstName === undefined ||
        normalizedLastName === "" ||
        normalizedLastName === undefined
    ) {
        // As either the first or last name is blank, we can return true and let the required validator catch this error
        return true;
    }

    // Perform a local unique name check first
    let peopleWithSameName = allPeople.filter((p) => {
        if (p?.personId === selectedPerson?.personId) {
            return false; // Same person, no need to check name
        }

        if (
            p?.firstName.toLowerCase() === normalizedFirstName &&
            p?.lastName.toLowerCase() === normalizedLastName
        ) {
            return true;
        }

        return false;
    });

    let isUniqueLocally = peopleWithSameName.length === 0;
    if (!isUniqueLocally) {
        return false; // Early return here, as there is a person in the local people list who has the same name
    }

    // Perform a server unique name check
    try {
        let urlEncodedFirstName = encodeURIComponent(normalizedFirstName);
        let urlEncodedLastName = encodeURIComponent(normalizedLastName);

        let currentPersonId = selectedPerson?.personId || "";
        let response = await ajaxGetAsync2(
            APIEndpoints.Account +
                `PersonNameCheck/${urlEncodedFirstName}/${urlEncodedLastName}/${currentPersonId}`, // apiEndpoint
            null, // data
            Defaults.REQUEST_TIMEOUT_MILLISECONDS // Add a timeout, in case there are server issues
        );
        return response.data;
    } catch (ex) {
        console.log("PersonNameCheck failed: Defaulting to local response", ex);
        return isUniqueLocally;
    }
};

export const UniqueNameValidationError =
    "Another person has the same first and last name";
export const createUniquePersonNameValidator = (
    form,
    isFirstName,
    firstName,
    lastName,
    allPeople,
    selectedPerson
) => {
    return new Promise(async (resolve, reject) => {
        let otherFieldName = isFirstName ? "lastName" : "firstName";
        let isUnique = await hasUniquePersonNameAsync(
            firstName,
            lastName,
            allPeople,
            selectedPerson
        );
        if (isUnique) {
            // Remove the error from the other field
            form.setFields([
                {
                    name: otherFieldName,
                    value: form.getFieldValue(otherFieldName),
                    errors: null,
                },
            ]);
            resolve();
            return;
        }

        form.setFields([
            {
                name: otherFieldName,
                value: form.getFieldValue(otherFieldName),
                errors: [UniqueNameValidationError],
            },
        ]);
        reject(UniqueNameValidationError);
    });
};

export const hasUniqueThingNameAsync = async (thingName, selectedThingId) => {
    if (
        thingName === null ||
        thingName === undefined ||
        thingName.trim() === ""
    ) {
        // As the name is blank, we can return true and let the required validator catch this error
        return true;
    }

    // Perform a server unique name check
    try {
        let urlEncodedName = encodeURIComponent(thingName);
        let thingId = selectedThingId || "";
        let response = await ajaxGetAsync2(
            APIEndpoints.Account +
                `ThingNameCheck/${urlEncodedName}/${thingId}`, // apiEndpoint
            null, // data
            Defaults.REQUEST_TIMEOUT_MILLISECONDS // Add a timeout, in case there are server issues
        );
        return response.data;
    } catch (ex) {
        console.log("ThingNameCheck failed: Defaulting to local response", ex);
        return true;
    }
};

export const UniqueThingNameValidationError =
    "Another Thing already has the same name";
export const createUniqueThingNameValidator = (
    thingName,
    allThings,
    selectedThing
) => {
    return new Promise(async (resolve, reject) => {
        let isUnique = await hasUniqueThingNameAsync(
            thingName,
            allThings,
            selectedThing
        );
        if (isUnique) {
            resolve();
            return;
        }

        reject(UniqueThingNameValidationError);
    });
};

export const isValidTreeSelection = (valueSelected, treeData) => {
    let selected = treeData
        .find(
            (category) =>
                category.children.find((r) => r.key === valueSelected) !==
                undefined
        )
        ?.children.find((tt) => tt.key === valueSelected);

    // Check if the the value is invalid
    // This is true if the selectedValue corresponds to a category instead of valid item
    // or if the selectedValue wasn't in the valid list. Either way, reject it!
    if (selected === undefined) {
        return false;
    }

    return true;
};

export const createTreeSelectValidator = (
    valueSelected,
    treeData,
    rejectMessage
) => {
    // This validator prevents users from selecting categories instead of items
    // Users can select categories using the arrow keys in the tree select

    return new Promise((resolve, reject) => {
        if (!isValidTreeSelection(valueSelected, treeData)) {
            reject(rejectMessage);
        }

        resolve();
    });
};
