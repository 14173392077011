import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
} from "react-sortable-hoc";
import { Button, Col, Input, List, Popconfirm, Popover, Row, Spin } from "antd";

import arrayMove from "array-move";

import { clearSearchAndRedirectToThing } from "../../redux/actions";
import {
    getImportantThingsList,
    setImportantThingList,
} from "../../redux/actions/importantThingsActions";

import { MessageError } from "../../shared/MessageError";
import ThingCard from "../../shared/ThingCard";
import LoadingIndicator from "../../shared/LoadingIndicator";
import { MenuOutlined } from "@ant-design/icons";

import "../../styles/sortables.css";
import { APIEndpoints, Defaults, ThingTabs } from "../../constants";
import {
    showNotificationError,
    showNotificationSuccess,
} from "../../functions";
import { ajaxPostAsync2 } from "../../redux/actions/ajaxActions";

const SortableDragHandle = SortableHandle(() => (
    <div className="tt-sortable-drag-handle">
        <MenuOutlined />
    </div>
));

const SortableThingListItem = SortableElement(({ item, onThingClick }) => {
    return (
        <List.Item
            key={item.thingId}
            className="tt-list-item, tt-sortable"
            onClick={onThingClick}
        >
            <SortableDragHandle />
            <Popover
                trigger="hover"
                placement="bottom"
                title="Change Thing Sort Order"
                content={
                    "Drag this Thing to the position you want it to be in the list"
                }
                mouseEnterDelay={Defaults.POPOVER_MOUSE_ENTER_DELAY_FAST}
                mouseLeaveDelay={Defaults.POPOVER_MOUSE_LEAVE_DELAY}
            >
                {/* Following magic div is required to make popover work on each item */}
                <div className="tt-thing-card">
                    <ThingCard thing={item} />
                </div>
            </Popover>
        </List.Item>
    );
});

const SortableThingList = SortableContainer(({ items, onThingClick }) => {
    return (
        <List
            bordered
            dataSource={items}
            rowKey={(item) => `item-${item.thingId}`}
            renderItem={(item, index) => (
                <SortableThingListItem
                    index={index}
                    item={item}
                    onThingClick={onThingClick}
                />
            )}
        />
    );
});

export const ImportantThingsList = () => {
    const dispatch = useDispatch();
    const notificationKey = "NK_ImportantThings";

    const importantThingsList = useSelector(
        (state) => state.importantThings.importantThings
    );
    const isImportantThingsLoading = useSelector(
        (state) => state.importantThings.isImportantThingsLoading
    );
    const importantThingsLoadError = useSelector(
        (state) => state.importantThings.importantThingsLoadError
    );

    const [originalThingList, setOriginalThingList] = useState(null);
    const [isSortingImportantThings, setIsSortingImportantThings] = useState(
        false
    );
    const [isSavingSortOrder, setIsSavingSortOrder] = useState(false);

    useEffect(() => {
        dispatch(getImportantThingsList());
    }, [dispatch]);

    if (importantThingsLoadError) {
        return <MessageError error={importantThingsLoadError} />;
    }
    if (isImportantThingsLoading || !importantThingsList) {
        return <LoadingIndicator />;
    }

    const onSortEnd = ({ oldIndex, newIndex }) => {
        let newThingsList = arrayMove(
            importantThingsList.listData,
            oldIndex,
            newIndex
        );
        // console.log(oldIndex, newIndex, thingList, newThingsList);
        dispatch(setImportantThingList(newThingsList));
    };

    const startSorting = () => {
        setOriginalThingList(importantThingsList.listData);
        setIsSortingImportantThings(true);
    };

    const saveSortChanges = async () => {
        setIsSavingSortOrder(true);

        try {
            let postThings = importantThingsList.listData.map(
                (thing, index) => {
                    return {
                        thingId: thing.thingId,
                        sequence: index,
                    };
                }
            );
            let response = await ajaxPostAsync2(
                `${APIEndpoints.Thing}ImportantThings`,
                { importantThings: postThings }
            );
            let updatedImportantThingsList = response.data.listData;
            dispatch(setImportantThingList(updatedImportantThingsList));

            showNotificationSuccess(
                notificationKey,
                "Successfully updated Important Things!",
                null
            );
            setIsSortingImportantThings(false);
        } catch (err) {
            showNotificationError(
                notificationKey,
                "Failed to updated Important Things!",
                null
            );
        } finally {
            setIsSavingSortOrder(false);
        }
    };

    const discardSortChanges = () => {
        dispatch(setImportantThingList(originalThingList));
        setIsSortingImportantThings(false);
    };

    const renderButtonBar = () => {
        if (isSavingSortOrder) {
            return (
                <div className="tt-ted-button-bar">
                    <Row>
                        <Col span={22}></Col>
                        <Col span={2}>
                            <Spin className="tt-button-bar-spinner" />
                        </Col>
                    </Row>
                </div>
            );
        }

        if (isSortingImportantThings) {
            return (
                <div className="tt-button-bar">
                    {/* Following Input is a hack to force focus to this control so button does not get focus which results in button color being lowlighted */}
                    <Input className="tt-hidden"></Input>
                    <div className="tt-button-bar-float-contents">
                        <Popover
                            trigger="hover"
                            placement="bottom"
                            title="Save Changes"
                            content={"Click here to save your changes"}
                            mouseEnterDelay={
                                Defaults.POPOVER_MOUSE_ENTER_DELAY_FAST
                            }
                            mouseLeaveDelay={Defaults.POPOVER_MOUSE_LEAVE_DELAY}
                        >
                            <Button
                                htmlType="button"
                                type="danger"
                                onClick={saveSortChanges}
                                className="float-right tt-button"
                            >
                                Save Changes
                            </Button>
                        </Popover>
                        <Popover
                            trigger="hover"
                            placement="bottom"
                            title="Discard Changes"
                            content={
                                "Click here to discard any changes you have made"
                            }
                            mouseEnterDelay={
                                Defaults.POPOVER_MOUSE_ENTER_DELAY_FAST
                            }
                            mouseLeaveDelay={Defaults.POPOVER_MOUSE_LEAVE_DELAY}
                        >
                            <Popconfirm
                                title="Are you sure you want to discard your changes?"
                                placement="bottom"
                                okText="Yes"
                                cancelText="No"
                                onConfirm={discardSortChanges}
                            >
                                <Button
                                    htmlType="button"
                                    className="float-right tt-button"
                                >
                                    Discard Changes
                                </Button>
                            </Popconfirm>
                        </Popover>
                    </div>
                </div>
            );
        }

        return (
            <div className="tt-button-bar">
                <div className="tt-button-bar-float-contents">
                    <Popover
                        trigger="hover"
                        placement="bottom"
                        title="Change Thing Sort Order"
                        content={
                            "Click here to edit the order of the important things"
                        }
                        mouseEnterDelay={
                            Defaults.POPOVER_MOUSE_ENTER_DELAY_FAST
                        }
                        mouseLeaveDelay={Defaults.POPOVER_MOUSE_LEAVE_DELAY}
                    >
                        <Button htmlType="button" onClick={startSorting}>
                            Change Sort Order
                        </Button>
                    </Popover>
                </div>
            </div>
        );
    };

    if (isSavingSortOrder) {
        return (
            <Fragment>
                <div className="tt-tc-scroll">
                    <List
                        bordered
                        className="tt-tc-scroll-list"
                        dataSource={importantThingsList.listData}
                        renderItem={(thing) => (
                            <List.Item
                                onClick={() =>
                                    dispatch(
                                        clearSearchAndRedirectToThing(
                                            thing.thingId,
                                            ThingTabs.ALL_THINGS
                                        )
                                    )
                                }
                            >
                                <ThingCard thing={thing} />
                            </List.Item>
                        )}
                    />
                </div>
                {renderButtonBar()}
            </Fragment>
        );
    }

    if (isSortingImportantThings && !isSavingSortOrder) {
        return (
            <Fragment>
                <div className="tt-tc-scroll">
                    <SortableThingList
                        lockAxis="y"
                        className="tt-tc-scroll-list"
                        items={importantThingsList.listData}
                        onSortStart={(_, event) => event.preventDefault()}
                        onSortEnd={onSortEnd}
                    />
                </div>
                {renderButtonBar()}
            </Fragment>
        );
    }

    return (
        <Fragment>
            <div className="tt-tc-scroll">
                <List
                    bordered
                    className="tt-tc-scroll-list"
                    dataSource={importantThingsList.listData}
                    renderItem={(thing) => (
                        <List.Item
                            onClick={() =>
                                dispatch(
                                    clearSearchAndRedirectToThing(
                                        thing.thingId,
                                        ThingTabs.ALL_THINGS
                                    )
                                )
                            }
                        >
                            <ThingCard thing={thing} />
                        </List.Item>
                    )}
                />
            </div>
            {renderButtonBar()}
        </Fragment>
    );
};

export default ImportantThingsList;
