import React from "react";

import ThingsLeftPanelContainer from "./ThingsLeftPanelContainer";
// import ThingsMessage from './ThingsMessage';
import ThingContainer from "./ThingContainer";

import wrapUserAccountPage from "../../shared/UserAccountPage";

export const ThingsContainer = wrapUserAccountPage(
    {
        redirectToNewAccountIfNoUserAccounts: true,
        attemptCookieAccountDeductionIfNoSelectedAccount: true,
        // Defer redirect until after trying to fetch the thing and attempting to select the account based on the result
        redirectToAccountSelectIfNoSelectedAccount: false,
    },
    () => {
        return (
            <div className="container-fluid">
                {/* <div className="row">
                    <div className="col-xs-12 col-md-12">
                        <ThingsMessage />
                    </div>
                </div> */}
                <div className="row">
                    <div className="col-xs-5 col-md-5">
                        <ThingsLeftPanelContainer />
                    </div>
                    <div className="col-xs-7 col-md-7">
                        <ThingContainer />
                    </div>
                </div>
            </div>
        );
    }
);

export default ThingsContainer;
