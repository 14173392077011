import { Card, Col } from "antd";
import React from "react";

export const InviteAcceptedContainer = (props) => {
    return (
        <Col offset={6} span={12}>
            <Card title="Invitation Accepted">
                <div>
                    The invitation was successfully accepted. The owner of the
                    account has been notified, and you'll receive an email when
                    they have verified your account details.
                </div>
            </Card>
        </Col>
    );
};

export default InviteAcceptedContainer;
