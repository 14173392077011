import React, { Fragment, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Input, Form, Alert } from "antd";

import {
    setReloadForAccountLists,
    createDispositionAction,
} from "../../redux/actions";
import { showNotificationSuccess, hideNotification } from "../../functions";
import { LastPostStatus, ThingPostTypes } from "../../constants";

import ThingInput from "./ThingInput";
import LoadingIndicator from "../../shared/LoadingIndicator";

export const ThingNewDispositionAction = (props) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [formRef, setFormRef] = useState();
    const paymentTypesList = useSelector(
        (state) => state.account.paymentTypesList
    );
    const lastPostType = useSelector((state) => state.thing.lastPostType);
    const lastPostStatus = useSelector((state) => state.thing.lastPostStatus);
    const lastPostData = useSelector((state) => state.thing.lastPostData);
    const newDispositionAction = useRef();
    const notificationKey = "NK_NewDispositionAction";

    useEffect(() => {
        if (formRef) {
            if (lastPostStatus) {
                if (
                    lastPostType === ThingPostTypes.NEW_DISPOSITION_ACTION &&
                    lastPostStatus === LastPostStatus.OK
                ) {
                    form.resetFields();
                    showNotificationSuccess(
                        notificationKey,
                        'Disposition action "' +
                            newDispositionAction.current +
                            '" created',
                        null
                    );
                    props.onClose();
                } else if (lastPostStatus === LastPostStatus.FAIL) {
                    hideNotification(notificationKey);
                }
            } else {
                form.resetFields(); // need this to force Form initialValues to reload
                // If we don't use the formRef the above line results in following console warning on page load
                // Warning: Instance created by `useForm` is not connect to any Form element. Forget to pass `form` prop?
                // See formRef solution at https://github.com/ant-design/ant-design/issues/21543#issuecomment-602008000
            }
        }
    }, [form, formRef, lastPostStatus, lastPostType, props]);

    const submitNewDispositionActionForm = (values) => {
        //console.info("newDispositionActionForm Values", values);
        newDispositionAction.current = values.name;
        dispatch(createDispositionAction(values));
        dispatch(setReloadForAccountLists());
    };

    const initialFormValues = {
        name: "",
    };

    if (!paymentTypesList) {
        return <LoadingIndicator />;
    }

    return (
        <Fragment>
            <h3>New Disposition Action</h3>
            <Form
                name="newDispositionActionForm"
                form={form}
                ref={setFormRef}
                onFinish={submitNewDispositionActionForm}
                layout="vertical"
                hideRequiredMark
                scrollToFirstError
                initialValues={initialFormValues}
            >
                <ThingInput visible={true} label="Disposition Action Name">
                    <Form.Item
                        name="name"
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: "Required",
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input
                            placeholder="Enter a name for the disposition action"
                            allowClear
                            autoFocus
                            autoComplete="off"
                        />
                    </Form.Item>
                </ThingInput>
                <Form.Item>
                    <Button type="danger" htmlType="submit">
                        Save Disposition Action
                    </Button>
                    <Button
                        htmlType="reset"
                        onClick={props.onClose}
                        className="tt-button"
                    >
                        Discard Changes
                    </Button>
                </Form.Item>
            </Form>
            {lastPostStatus === LastPostStatus.ACTIVE ? (
                <Alert
                    type="info"
                    message={
                        'Creating disposition action "' +
                        newDispositionAction.current +
                        '"...'
                    }
                />
            ) : null}
            {lastPostStatus === LastPostStatus.FAIL ? (
                <Alert
                    type="error"
                    message={
                        'Disposition action "' +
                        newDispositionAction.current +
                        '" could not be created'
                    }
                    description={
                        lastPostData && lastPostData.message
                            ? lastPostData.message
                            : "An error occurred"
                    }
                />
            ) : null}
        </Fragment>
    );
};

export default ThingNewDispositionAction;
