import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Select, Button, Row, Col } from "antd";

import { GroupByTypes } from "../../constants";
import { setActiveGroupByType, clearSearchResults } from "../../redux/actions";
import {
    getGroupByThingsListDispatchable,
    setReloadForGroupByThingsList,
} from "../../redux/actions/thingListsActions";

export const ThingGroupBy = () => {
    const { Option } = Select;
    const dispatch = useDispatch();
    const groupByType = useSelector((state) => state.selected.groupByType);
    const searchText = useSelector((state) => state.selected.searchText);
    const loading = useSelector(
        (state) => state.thinglists.groupByThingsLoading
    );
    const error = useSelector((state) => state.thinglists.groupByThingsError);

    const handleSetActiveGroupByType = (groupByType) => {
        dispatch(setActiveGroupByType(groupByType));
        dispatch(setReloadForGroupByThingsList());
        dispatch(getGroupByThingsListDispatchable(groupByType, searchText));
    };

    const clearSearch = () => {
        dispatch(clearSearchResults());
    };

    return (
        <Row>
            <Col span={16}>
                <div>
                    <span>Group By</span>
                    <Select
                        id="groupBy"
                        value={groupByType}
                        size="small"
                        className="tt-group-by-options"
                        onChange={handleSetActiveGroupByType}
                    >
                        <Option value={GroupByTypes.THING_NAME}>
                            No Grouping
                        </Option>
                        <Option value={GroupByTypes.THING_TYPE}>
                            Thing Type
                        </Option>
                        <Option value={GroupByTypes.RENEWAL_DATE}>
                            Renewal Date
                        </Option>
                        <Option value={GroupByTypes.PAYMENT_DATE}>
                            Payment Date
                        </Option>
                        <Option value={GroupByTypes.RENEWAL_FREQUENCY}>
                            Renewal Frequency
                        </Option>
                        <Option value={GroupByTypes.PAYMENT_FREQUENCY}>
                            Payment Frequency
                        </Option>
                        <Option value={GroupByTypes.DISPOSITION_ACTION}>
                            Disposition Action
                        </Option>
                        {searchText !== "" ? (
                            <Option value={GroupByTypes.SEARCH_AREA}>
                                Search Area
                            </Option>
                        ) : null}
                    </Select>
                </div>
            </Col>
            <Col span={8}>
                <div className="float-right">
                    {searchText !== "" && !loading && !error ? (
                        <Button
                            type="danger"
                            size="small"
                            onClick={clearSearch}
                        >
                            Clear Search
                        </Button>
                    ) : null}
                </div>
            </Col>
        </Row>
    );
};

export default ThingGroupBy;
