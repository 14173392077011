import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import parse from "html-react-parser";
import { Button, Card, Drawer, Popover } from "antd";

import { MessageError } from "../../shared/MessageError";
import LoadingIndicator from "../../shared/LoadingIndicator";
import { Defaults, PersonTypeIds } from "../../constants";
import ImportantMessageEditor from "./ImportantMessageEditor";
import { getImportantMessage } from "../../redux/actions/importantThingsActions";

export const ImportantMessage = () => {
    const dispatch = useDispatch();

    const importantMessage = useSelector(
        (state) => state.importantThings.importantMessage
    );
    const isLoadingMessage = useSelector(
        (state) => state.importantThings.isImportantMessageLoading
    );
    const messageLoadError = useSelector(
        (state) => state.importantThings.importantMessageLoadError
    );

    const isPrimaryOwner = useSelector(
        (state) => state.account.accountType === PersonTypeIds.PRIMARY_OWNER
    );
    const [isEditingMessage, setIsEditingMessage] = useState(false);
    const startEditingMessage = () => setIsEditingMessage(true);
    const endEditingMessage = () => setIsEditingMessage(false);

    // Effect: Get important message if not already gotten
    useEffect(() => {
        if (!importantMessage) {
            dispatch(getImportantMessage());
        }
    }, [dispatch, importantMessage]);

    if (messageLoadError) {
        return <MessageError error={messageLoadError} />;
    }

    if (isLoadingMessage) {
        return <LoadingIndicator />;
    }

    if (!importantMessage) {
        return null;
    }

    const renderEditBar = () => {
        if (!isPrimaryOwner) {
            return null; // Only the primary owner can edit the important message
        }

        return (
            <div className="tt-button-bar">
                <div className="tt-button-bar-float-contents">
                    <Popover
                        trigger="hover"
                        placement="bottom"
                        title="Edit Message"
                        content={
                            "Click here to edit the message displayed above"
                        }
                        mouseEnterDelay={
                            Defaults.POPOVER_MOUSE_ENTER_DELAY_FAST
                        }
                        mouseLeaveDelay={Defaults.POPOVER_MOUSE_LEAVE_DELAY}
                    >
                        <Button
                            htmlType="button"
                            onClick={startEditingMessage}
                            className="tt-button"
                        >
                            Edit Message
                        </Button>
                    </Popover>
                </div>
            </div>
        );
    };

    return (
        <Fragment>
            <Card title={importantMessage.title}>
                {parse(importantMessage.body)}
            </Card>
            {renderEditBar()}

            <Drawer
                title="Important Message Editor"
                placement="right"
                width={Defaults.EDITOR_DRAWER_WIDTH}
                closable={false}
                visible={isEditingMessage}
                onClose={endEditingMessage}
            >
                <ImportantMessageEditor
                    onClose={endEditingMessage}
                    isVisible={isEditingMessage}
                />
            </Drawer>
        </Fragment>
    );
};

export default ImportantMessage;
