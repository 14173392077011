import keyMirror from "keymirror";

import { ajaxGetAsync, ajaxGetAsync2 } from "./ajaxActions";

import store from "../store";
import { APIEndpoints } from "../../constants";
import { MyAccountsData } from "../reducers/accountReducers";
import { setReloadForAccountLists } from "../actions";
import { reloadThingListsDispatchable } from "./thingListsActions";

export const AccountActions = keyMirror({
    SET_CURRENT_ACCOUNT: null,

    GET_ACCOUNT_BEGIN: null,
    GET_ACCOUNT_END_OK: null,
    GET_ACCOUNT_END_FAIL: null,
    GET_ACCOUNT_END_IGNORE: null,

    GET_ACCOUNT_LISTS_BEGIN: null,
    GET_ACCOUNT_LISTS_END_OK: null,
    GET_ACCOUNT_LISTS_END_FAIL: null,
    GET_ACCOUNT_LISTS_END_IGNORE: null,

    UPDATE_PEOPLE_LIST: null,
    UPDATE_PERSON_IN_LIST: null,
    REMOVE_PERSON_FROM_LIST: null,
});

export function getMyAccountsDispatchable() {
    return async (dispatchAsync) => {
        await MyAccountsData.actions.performLoadAsync(async () => {
            let result = await ajaxGetAsync2(
                APIEndpoints.Account + "MyAccounts"
            );
            return { data: result.data, error: null };
        }, dispatchAsync);
    };
}

export function getDataForCurrentAccount() {
    return async (dispatchAsync) => {
        await dispatchAsync(setReloadForAccountLists());
        await dispatchAsync(getAccountLists());
    };
}

export function setCurrentAccountDispatchable(account) {
    return async (dispatchAsync) => {
        await dispatchAsync({
            type: AccountActions.SET_CURRENT_ACCOUNT,
            data: account,
        });
        await dispatchAsync(getDataForCurrentAccount());
        await dispatchAsync(reloadThingListsDispatchable());
    };
}

export function getAccountLists() {
    let state = store.getState();
    if (state.account.accountListsLoad && !state.account.accountListsLoading) {
        return async (dispatchAsync) => {
            await ajaxGetAsync(
                APIEndpoints.Account + "Lists",
                AccountActions.GET_ACCOUNT_LISTS_BEGIN,
                AccountActions.GET_ACCOUNT_LISTS_END_OK,
                AccountActions.GET_ACCOUNT_LISTS_END_FAIL,
                dispatchAsync
            );
        };
    }

    return {
        type: AccountActions.GET_ACCOUNT_LISTS_END_IGNORE,
    };
}

export function setPeopleList(people) {
    return {
        type: AccountActions.UPDATE_PEOPLE_LIST,
        data: people,
    };
}

export function updatePersonInList(updatedPerson) {
    return {
        type: AccountActions.UPDATE_PERSON_IN_LIST,
        data: updatedPerson,
    };
}

export function removePersonFromList(person) {
    return {
        type: AccountActions.REMOVE_PERSON_FROM_LIST,
        data: person.personId,
    };
}
