import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import {
    DollarCircleTwoTone,
    StopTwoTone,
    ReloadOutlined,
} from "@ant-design/icons";

import { ThingDateTypes } from "../constants";
import { defaultDateFormat, formatWithCommas } from "../functions";
import { Row, Col, Tooltip } from "antd";

export const ThingDueInfo = (props) => {
    const countryCode = useSelector(
        (state) => state.account.accountCountryCode
    );
    const currencies = useSelector((state) => state.sharedLists.currencies);
    const thing = props.thing;

    var date =
        thing.type === ThingDateTypes.RENEWAL
            ? thing.renewalDate
            : thing.nextPaymentDate;
    var dmoment = moment(date);

    const currencySymbol = (currencyCode) => {
        let symbol = currencyCode;
        if (currencies) {
            for (let currency of currencies) {
                if (currency.currencyCode === currencyCode) {
                    symbol = currency.currencySymbol;
                    break;
                }
            }
        }
        return symbol;
    };

    const renderIcon = () => {
        var paymentMethod = "";
        var frequency = thing.frequency ? " " + thing.frequency : "";
        if (thing.paymentMethodType && thing.paymentMethod) {
            paymentMethod =
                thing.paymentMethodType + ": " + thing.paymentMethod;
        }
        if (
            thing.type === ThingDateTypes.RENEWAL &&
            thing.autoRollverRenewalDate === true
        ) {
            return (
                <Tooltip title={"Renewal date will auto-rollover" + frequency}>
                    <ReloadOutlined className="tt-db-pay-renew-icon" />
                </Tooltip>
            );
        } else if (
            thing.type === ThingDateTypes.RENEWAL &&
            thing.autoRollverRenewalDate === false
        ) {
            return (
                <Tooltip title="Renewal date will NOT auto-rollover">
                    <StopTwoTone
                        twoToneColor="#dc3545"
                        className="tt-db-pay-renew-icon"
                    />
                </Tooltip>
            );
        } else if (
            thing.type === ThingDateTypes.PAYMENT &&
            thing.autoRollverPaymentDate === true &&
            thing.preAuthorizedPayment === true
        ) {
            if (paymentMethod !== "") {
                paymentMethod = " and will be paid from " + paymentMethod;
            }
            return (
                <Fragment>
                    <Tooltip
                        title={"Payment date will auto-rollover" + frequency}
                    >
                        <ReloadOutlined className="tt-db-pay-renew-icon" />
                    </Tooltip>
                    <Tooltip
                        title={"Payment is pre-authorized" + paymentMethod}
                    >
                        <DollarCircleTwoTone
                            twoToneColor="#28a745"
                            className="tt-db-pay-renew-icon"
                        />
                    </Tooltip>
                </Fragment>
            );
        } else if (
            thing.type === ThingDateTypes.PAYMENT &&
            thing.autoRollverPaymentDate === false &&
            thing.preAuthorizedPayment === true
        ) {
            if (paymentMethod !== "") {
                paymentMethod = " and will be paid from " + paymentMethod;
            }
            return (
                <Fragment>
                    <Tooltip title="Payment date will NOT auto-rollover">
                        <StopTwoTone
                            twoToneColor="#dc3545"
                            className="tt-db-pay-renew-icon"
                        />
                    </Tooltip>
                    <Tooltip
                        title={"Payment is pre-authorized" + paymentMethod}
                    >
                        <DollarCircleTwoTone
                            twoToneColor="#28a745"
                            className="tt-db-pay-renew-icon"
                        />
                    </Tooltip>
                </Fragment>
            );
        } else if (
            thing.type === ThingDateTypes.PAYMENT &&
            thing.autoRollverPaymentDate === true &&
            thing.preAuthorizedPayment === false
        ) {
            if (paymentMethod !== "") {
                paymentMethod = " from " + paymentMethod;
            }
            return (
                <Fragment>
                    <Tooltip
                        title={"Payment date will auto-rollover" + frequency}
                    >
                        <ReloadOutlined className="tt-db-pay-renew-icon" />
                    </Tooltip>
                    <Tooltip
                        title={"Payment must be made manually" + paymentMethod}
                    >
                        <DollarCircleTwoTone
                            twoToneColor="#dc3545"
                            className="tt-db-pay-renew-icon"
                        />
                    </Tooltip>
                </Fragment>
            );
        } else if (
            thing.type === ThingDateTypes.PAYMENT &&
            thing.autoRollverPaymentDate === false &&
            thing.preAuthorizedPayment === false
        ) {
            if (paymentMethod !== "") {
                paymentMethod = " from " + paymentMethod;
            }
            return (
                <Fragment>
                    <Tooltip title="Payment date will NOT auto-rollover">
                        <StopTwoTone
                            twoToneColor="#dc3545"
                            className="tt-db-pay-renew-icon"
                        />
                    </Tooltip>
                    <Tooltip
                        title={"Payment must be made manually" + paymentMethod}
                    >
                        <DollarCircleTwoTone
                            twoToneColor="#dc3545"
                            className="tt-db-pay-renew-icon"
                        />
                    </Tooltip>
                </Fragment>
            );
        } else {
            return null;
        }
    };

    if (thing.type === ThingDateTypes.PAYMENT && thing.paymentAmount) {
        let fmtAmount = "";
        try {
            fmtAmount =
                currencySymbol(thing.currencyCode) +
                formatWithCommas(thing.paymentAmount);
            if (thing.paymentAmountApproximate) {
                fmtAmount = "approx. " + fmtAmount;
            }
        } catch {
            // ignore
        }
        return (
            <Row>
                <Col>{renderIcon()}</Col>
                <Col>
                    {thing.type} of {fmtAmount} due{" "}
                    {dmoment.format(defaultDateFormat(countryCode)) +
                        " (" +
                        dmoment.fromNow() +
                        ")"}
                </Col>
            </Row>
        );
    }
    return (
        <Row>
            <Col>{renderIcon()}</Col>
            <Col>
                {thing.type} due{" "}
                {dmoment.format(defaultDateFormat(countryCode)) +
                    " (" +
                    dmoment.fromNow() +
                    ")"}
            </Col>
        </Row>
    );
};

export default ThingDueInfo;
