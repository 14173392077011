import React, { Fragment, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Input, Form, Alert, Select } from "antd";

import { createPaymentThing } from "../../redux/actions";
import { showNotificationSuccess, hideNotification } from "../../functions";
import { LastPostStatus, ThingPostTypes } from "../../constants";

import ThingInput from "./ThingInput";
import { createUniqueThingNameValidator } from "../../shared/FormItemValidators";
import { setReloadForPaymentThingsList } from "../../redux/actions/thingListsActions";

export const ThingNewPaymentMethod = (props) => {
    const { Option } = Select;
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [formRef, setFormRef] = useState();
    const paymentThingTypesList = useSelector(
        (state) => state.account.paymentThingTypesList
    );
    const lastPostType = useSelector((state) => state.thing.lastPostType);
    const lastPostStatus = useSelector((state) => state.thing.lastPostStatus);
    const lastPostData = useSelector((state) => state.thing.lastPostData);
    const newThingName = useRef();
    const notificationKey = "NK_NewPaymentMethod";

    useEffect(() => {
        if (formRef) {
            if (lastPostStatus) {
                if (
                    lastPostType === ThingPostTypes.NEW_PAYMENT_THING &&
                    lastPostStatus === LastPostStatus.OK
                ) {
                    form.resetFields();
                    showNotificationSuccess(
                        notificationKey,
                        'Payment method  "' +
                            newThingName.current +
                            '" created',
                        null
                    );
                    props.onCloseSave();
                } else if (lastPostStatus === LastPostStatus.FAIL) {
                    hideNotification(notificationKey);
                }
            } else {
                form.resetFields(); // need this to force Form initialValues to reload
                // If we don't use the formRef the above line results in following console warning on page load
                // Warning: Instance created by `useForm` is not connect to any Form element. Forget to pass `form` prop?
                // See formRef solution at https://github.com/ant-design/ant-design/issues/21543#issuecomment-602008000
            }
        }
    }, [form, formRef, lastPostStatus, lastPostType, props]);

    const submitNewThingForm = (values) => {
        console.info("newPaymentThingForm Values", values);
        newThingName.current = values.thingName;
        dispatch(createPaymentThing(values));
        dispatch(setReloadForPaymentThingsList());
    };

    const initialFormValues = {
        thingName: "",
        thingTypeId: undefined,
    };

    return (
        <Fragment>
            <h3>New Payment Method</h3>
            <Form
                name="newPaymentThingForm"
                form={form}
                ref={setFormRef}
                onFinish={submitNewThingForm}
                layout="vertical"
                hideRequiredMark
                scrollToFirstError
                initialValues={initialFormValues}
            >
                <ThingInput visible={true} label="Name">
                    <Form.Item
                        name="thingName"
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: "Required",
                                whitespace: true,
                            },
                            {
                                validator: (rule, value, callback) =>
                                    createUniqueThingNameValidator(value, null),
                            },
                        ]}
                    >
                        <Input
                            placeholder="Enter a name for your payment method thing"
                            allowClear
                            autoFocus
                        />
                    </Form.Item>
                </ThingInput>
                <ThingInput visible={true} label="Type">
                    <Form.Item
                        name="thingTypeId"
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: "Required",
                            },
                        ]}
                    >
                        <Select
                            name="thingTypeId"
                            dropdownClassName="tt-ted-dropdown"
                            placeholder="Select thing type"
                        >
                            <Option key="" value=""></Option>
                            {paymentThingTypesList.map((pt) => (
                                <Option
                                    key={pt.thingTypeId}
                                    value={pt.thingTypeId}
                                >
                                    {pt.localizedName}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </ThingInput>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Save
                    </Button>
                    <Button
                        htmlType="reset"
                        onClick={props.onCloseCancel}
                        className="tt-button"
                    >
                        Cancel
                    </Button>
                </Form.Item>
            </Form>
            {lastPostStatus === LastPostStatus.ACTIVE ? (
                <Alert
                    type="info"
                    message={
                        'Creating payment method thing "' +
                        newThingName.current +
                        '"...'
                    }
                />
            ) : null}
            {lastPostStatus === LastPostStatus.FAIL ? (
                <Alert
                    type="error"
                    message={
                        'Payment method thing "' +
                        newThingName.current +
                        '" could not be created'
                    }
                    description={
                        lastPostData && lastPostData.message
                            ? lastPostData.message
                            : "An error occurred"
                    }
                />
            ) : null}
        </Fragment>
    );
};

export default ThingNewPaymentMethod;
