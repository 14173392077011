import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
    DatePicker,
    Switch,
    Input,
    Row,
    Col,
    Form,
    Drawer,
    Button,
} from "antd";
import parse from "html-react-parser";

import ThingInput from "./ThingInput";
import ThingDetailsEditor from "./ThingDetailsEditor";

import { dateFormatList, getReviewDate } from "../../functions";
import { Defaults } from "../../constants";
import moment from "moment";

export const ThingReviewContainer = (props) => {
    const form = props.form;
    const initialFormValues = props.initialFormValues;
    const countryCode = useSelector(
        (state) => state.account.accountCountryCode
    );
    const [editorDrawerVisible, setEditorDrawerVisible] = useState(false);
    const [reviewNotes, setReviewNotes] = useState(
        initialFormValues && initialFormValues.reviewNotes
            ? initialFormValues.reviewNotes
            : ""
    );
    const [shouldShowReviewFields, setShouldShowReviewFields] = useState(
        initialFormValues && initialFormValues.requiresReview
    );

    // Effect to reset state when the initialForm values change
    useEffect(() => {
        setReviewNotes(initialFormValues?.reviewNotes ?? "");
    }, [initialFormValues]);

    const handleEditDetailsClick = () => {
        setEditorDrawerVisible(true);
    };

    const handleEditorDrawerClose = () => {
        setEditorDrawerVisible(false);
    };

    const updateReviewNotes = (content) => {
        setReviewNotes(content);
        form.setFieldsValue({ reviewNotes: content }); // force form isFieldsTouched to register change
        props.handleValuesChange();
    };

    const handleReviewFlagChange = (completed) => {
        var reviewDate = getReviewDate(
            form.getFieldValue("nextRenewalDate"),
            completed
        );
        form.setFieldsValue({ nextReviewDate: reviewDate });
    };

    const isInvalidReviewDate = (dateToCheck) => {
        const earliestDate = moment().startOf("day");
        // Any date in the future is valid
        return !earliestDate.isSameOrBefore(dateToCheck);
    };

    const handleRequiresReviewOnChange = (requiresReview) => {
        setShouldShowReviewFields(requiresReview);
        form.setFieldsValue({ completed: false });
    };

    return (
        <div className="tt-ted-panel-container">
            <ThingInput
                visible={true}
                label="Requires Review"
                tooltipkey="ThingInput_RequiresReview"
            >
                <Form.Item name="requiresReview" valuePropName="checked">
                    <Switch
                        checkedChildren={"Yes"}
                        unCheckedChildren={"No"}
                        onChange={handleRequiresReviewOnChange}
                    />
                </Form.Item>
            </ThingInput>
            {shouldShowReviewFields && (
                <Fragment>
                    <Input.Group>
                        <Row gutter={16}>
                            <Col span={10}>
                                <ThingInput
                                    visible={true}
                                    label="Reviewed and Completed"
                                    tooltipkey="ThingInput_ReviewCompleted"
                                >
                                    <Form.Item
                                        name="completed"
                                        valuePropName="checked"
                                    >
                                        <Switch
                                            disabled={!shouldShowReviewFields}
                                            checkedChildren={"Yes"}
                                            unCheckedChildren={"No"}
                                            onChange={handleReviewFlagChange}
                                        />
                                    </Form.Item>
                                </ThingInput>
                            </Col>
                            <Col>
                                <ThingInput
                                    visible={true}
                                    label="Next Review Date"
                                    tooltipkey="ThingInput_ReviewDate"
                                >
                                    <Form.Item name="nextReviewDate">
                                        <DatePicker
                                            disabled={!shouldShowReviewFields}
                                            disabledDate={isInvalidReviewDate}
                                            disabledTime
                                            id="nextReviewDate"
                                            format={dateFormatList(countryCode)}
                                        />
                                    </Form.Item>
                                </ThingInput>
                            </Col>
                        </Row>
                    </Input.Group>
                </Fragment>
            )}
            <div className={shouldShowReviewFields ? null : "tt-hidden"}>
                <ThingInput visible={true} label="Review Notes">
                    <Form.Item name="reviewNotes" className="tt-hidden">
                        <Input />
                    </Form.Item>
                    <div className="tt-ted-html-details">
                        {reviewNotes ? (
                            parse(reviewNotes)
                        ) : (
                            <span className="tt-ted-html-details-placeholder">
                                Click the Edit Review Notes button below to
                                modify the content that appears here.
                            </span>
                        )}
                    </div>
                    <Button onClick={handleEditDetailsClick}>
                        Edit Review Notes
                    </Button>
                </ThingInput>
            </div>
            <Drawer
                title="Review Notes"
                placement="right"
                width={Defaults.EDITOR_DRAWER_WIDTH}
                closable={false}
                onClose={handleEditorDrawerClose}
                visible={editorDrawerVisible}
            >
                <ThingDetailsEditor
                    title="Review Notes"
                    content={reviewNotes}
                    onClose={handleEditorDrawerClose}
                    onChange={updateReviewNotes}
                />
            </Drawer>
        </div>
    );
};

export default ThingReviewContainer;
