import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "antd";

import { ThingTabs, Routes } from "../constants";
import {
    setSearchText,
    redirectToThing,
    getSearchResults,
    clearSearchResults,
} from "../redux/actions";
import TTooltip from "./TTooltip";

export const ThingSimpleSearch = () => {
    const { Search } = Input;
    const dispatch = useDispatch();

    const searchInputRef = useRef();

    const searchText = useSelector((state) => state.selected.searchText);
    const pathname = useSelector((state) => state.router.location.pathname);

    let pageIsThings =
        pathname.startsWith(Routes.THING) ||
        pathname.startsWith(Routes.THINGS) ||
        pathname.startsWith(Routes.NEWTHING);

    // Effect: focus the search text box when the user navigates to the Things page
    useEffect(() => {
        if (
            pathname.startsWith(Routes.THINGS) &&
            searchInputRef !== undefined
        ) {
            searchInputRef.current.focus();
        }
    }, [pathname]);

    const handleSetSearchText = (event) => {
        dispatch(setSearchText(event.target.value));
    };

    const handleSearch = (value) => {
        // value has changed then get search results
        if (value === "") {
            dispatch(clearSearchResults());
        } else {
            dispatch(getSearchResults(value));
        }

        // if page is not the Thing/Things/NewThing page and we have a search value then redirect to Things page
        if (!pageIsThings && value !== "") {
            dispatch(redirectToThing(null, ThingTabs.ALL_THINGS));
        }
    };

    return (
        <div id="search" className="tt-search-box">
            <TTooltip ttkey="Nav-Search" placement="bottom">
                <Search
                    ref={searchInputRef}
                    placeholder="Enter search text"
                    value={searchText}
                    onChange={handleSetSearchText}
                    onSearch={handleSearch}
                    allowClear
                    enterButton
                    size="default"
                />
            </TTooltip>
        </div>
    );
};

export default ThingSimpleSearch;
