import React from "react";

import AuthorizedRoute from "./auth/AuthorizedRoute";

const AppAuthorizedRoute = ({
    component: Component,
    render,
    layout: Layout,
    ...rest
}) => {
    return (
        // <AuthorizedRoute {...rest} component={Component} />
        <AuthorizedRoute
            {...rest}
            render={(props) => (
                <Layout>
                    {!!Component && <Component {...props} />}
                    {!!render && render(props)}
                </Layout>
            )}
        />
    );
};

export default AppAuthorizedRoute;
