import React from "react";
import { Tooltip } from "antd";

export const TruncateWithTooltip = (props) => {
    const content = props.children;
    return (
        <Tooltip title={content} placement="right">
            <span className="tt-truncate">{props.children}</span>
        </Tooltip>
    );
};

export default TruncateWithTooltip;
