import keyMirror from "keymirror";

import { APIEndpoints } from "../../constants";
import { ajaxGetAsync2, ajaxPostAsync2 } from "./ajaxActions";

export const PersonActions = keyMirror({
    SET_SELECTED_PERSON: null,

    UPDATE_PERSON_BEGIN: null,
    UPDATE_PERSON_END_OK: null,
    UPDATE_PERSON_END_FAIL: null,
});

export function setSelectedPerson(person) {
    return {
        type: PersonActions.SET_SELECTED_PERSON,
        data: person,
    };
}

export async function updatePerson(personValues) {
    // let state = store.getState();

    // let personId = personValues.personId;
    // let existingPerson = state.account.peopleList
    //     .find(p => p.personId === personId);

    // console.log(personValues);
    // console.log(existingPerson);

    let response = await ajaxPostAsync2(
        APIEndpoints.Account + "UpdatePerson",
        personValues
    );
    return response.data;
}

export function getInviteAsync(invitationGUID) {
    if (invitationGUID === null || invitationGUID === "") return null;
    return ajaxGetAsync2(
        APIEndpoints.Invite + `GetInvite/${invitationGUID}/`
    ).then((response) => response.data);
}

export function acceptInviteAsync(invitationGUID) {
    if (invitationGUID === null || invitationGUID === "") return null;
    return ajaxPostAsync2(APIEndpoints.Invite + "AcceptInvite", {
        InviteGUID: invitationGUID,
    }).then((response) => {
        return response.data;
    });
}

export async function approveInvite(person) {
    let personId = person?.personId;
    if (personId == null) return null;
    let response = await ajaxPostAsync2(
        APIEndpoints.Account + "ApproveInvite",
        { personId }
    );
    return response.data;
}

export async function rejectInvite(person) {
    let personId = person?.personId;
    if (personId == null) return null;
    let response = await ajaxPostAsync2(APIEndpoints.Account + "RejectInvite", {
        personId,
    });
    return response.data;
}
