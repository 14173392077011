import KeyMirror from "keymirror";

export const SharedListsActions = KeyMirror({
    SHARED_LISTS_SET_RESULT: null,
    SHARED_LISTS_SET_IS_LOADING: null,
});

const initialState = {
    isLoading: false,
    hasData: false,
    hasError: false,
    error: null,

    countries: null,
    currencies: null,
    languages: null,
    timeZones: null,
};

function sharedListsReducer(state = initialState, action) {
    switch (action.type) {
        case SharedListsActions.SHARED_LISTS_SET_IS_LOADING:
            return {
                ...state,
                isLoading: action.data,
            };

        case SharedListsActions.SHARED_LISTS_SET_RESULT:
            const { data, error } = action.data;
            return {
                ...state,
                isLoading: false,
                hasError: !!error,
                error: error,
                
                hasData: data !== null && data !== undefined,
                countries: data?.countries,
                currencies: data?.currencies,
                languages: data?.languages,
                timeZones: data?.timeZones,
            };
        default:
            return state;
    }
}

export default sharedListsReducer;
