import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { Popover } from "antd";
import parse from "html-react-parser";
import { Defaults } from "../constants";

export const TTooltip = (props) => {
    const tooltipKey = props.ttkey;
    const tooltipTrigger = props.trigger;
    const tooltipPlacement = props.placement;
    const tooltipSpeed = props.speed;
    const children = props.children;
    const tooltipsList = useSelector((state) => state.account.tooltipsList);

    var trigger = "hover";
    if (tooltipTrigger) {
        trigger = tooltipTrigger;
    }
    var placement = "leftTop"; // options: top, topLeft, topRight, bottom, bottomLeft, bottomRight, left, leftTop, leftBottom, right, rightTop, rightBottom
    if (tooltipPlacement) {
        placement = tooltipPlacement;
    }
    var speed = Defaults.POPOVER_MOUSE_ENTER_DELAY_FAST;
    if (tooltipSpeed === "slow") {
        speed = Defaults.POPOVER_MOUSE_ENTER_DELAY_SLOW;
    }

    var selectedItem = null;
    var showPopover = false;
    var title = "";
    var content = "";
    if (tooltipKey) {
        if (tooltipsList) {
            selectedItem = tooltipsList.filter((t) => t.key === tooltipKey)[0];
            if (selectedItem) {
                showPopover = true;
                title = selectedItem.title;
                content = selectedItem.content;
                if (content === null) {
                    content = "";
                }
            }
        }
    }

    if (showPopover) {
        return (
            <Popover
                trigger={trigger}
                placement={placement}
                content={parse(content)}
                title={title}
                mouseEnterDelay={speed}
                mouseLeaveDelay={Defaults.POPOVER_MOUSE_LEAVE_DELAY}
            >
                {children}
            </Popover>
        );
    }

    return <Fragment>{children}</Fragment>;
};

export default TTooltip;
