import { Alert } from "antd";
import React from "react";

export const PageNotFoundContainer = () => {
    return (
        <Alert
            type="warning"
            message="The page you were looking for was not found."
        />
    );
};

export default PageNotFoundContainer;
