import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Select, Button, Input, Form } from "antd";
import moment from "moment";

import { updateRelatedLinks } from "../../redux/actions";
import {
    createLengthValidationRule,
    jsonClone,
    showNotificationInfo,
} from "../../functions";

import ThingInput from "./ThingInput";
import { ThingUpdateActions, EMPTY_GUID, Defaults } from "../../constants";

const uniqueThingLinkURLValidator = (value, thingLinks) => {
    return new Promise((resolve, reject) => {
        let thingWithSameURL = thingLinks.find(
            (l) =>
                l.url.trim().toLowerCase() === value.trim().toLowerCase() &&
                l.action !== ThingUpdateActions.DELETE
        );
        if (thingWithSameURL !== undefined) {
            reject("A link already exists with the same URL");
            return;
        }
        resolve();
    });
};

export const ThingNewLink = (props) => {
    const dispatch = useDispatch();
    const { Option } = Select;
    const thingForm = props.form;
    const [form] = Form.useForm();
    const [formRef, setFormRef] = useState();
    const linkTypesList = useSelector((state) => state.account.linkTypesList);
    const activeThingLinks = useSelector(
        (state) => state.thing.activeThingLinks
    );
    const notificationKey = "NK_NewRelatedLink";

    useEffect(() => {
        if (formRef) {
            form.resetFields(); // need this to force Form initialValues to reload
            // If we don't use the formRef the above line results in following console warning on page load
            // Warning: Instance created by `useForm` is not connect to any Form element. Forget to pass `form` prop?
            // See formRef solution at https://github.com/ant-design/ant-design/issues/21543#issuecomment-602008000
        }
    }, [formRef, form]);

    // remove any non-existing items which have been marked for deletion
    const cleanupList = (list) => {
        var newList = [];
        list.forEach((l) => {
            if (l.existing || l.action !== ThingUpdateActions.DELETE) {
                newList.push(l);
            }
        });
        return newList;
    };

    const submitNewLinkForm = (values) => {
        var newList = jsonClone(activeThingLinks);
        newList.push({
            linkId: EMPTY_GUID,
            url: values.linkUrl,
            title: values.linkTitle,
            linkTypeId: values.linkType,
            tempLinkId: moment.utc(),
            existing: false,
            action: ThingUpdateActions.NEW,
        });
        dispatch(updateRelatedLinks(cleanupList(newList)));
        showNotificationInfo(
            notificationKey,
            'Link "' + values.linkTitle + '" added',
            null
        );
        form.resetFields();
        thingForm.setFieldsValue({ hiddenLinkId: null }); // force form isFieldsTouched to register change
        props.onClose();
    };

    const initialFormValues = {
        linkUrl: "",
        linkTitle: "",
        linkType: undefined,
    };

    return (
        <Fragment>
            <h3>New Link</h3>
            <Form
                name="newLink"
                form={form}
                ref={setFormRef}
                onFinish={submitNewLinkForm}
                layout="vertical"
                hideRequiredMark
                scrollToFirstError
                initialValues={initialFormValues}
            >
                <ThingInput visible={true} label="URL">
                    <Form.Item
                        name="linkUrl"
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: "Required",
                                whitespace: true,
                            },
                            createLengthValidationRule(
                                Defaults.MAX_URL_LENGTH,
                                "Link URL"
                            ),
                            {
                                validator: (rule, value, callback) =>
                                    uniqueThingLinkURLValidator(
                                        value,
                                        activeThingLinks
                                    ),
                            },
                        ]}
                    >
                        <Input
                            placeholder="Enter the URL (address) for your link"
                            allowClear
                            autoFocus
                            autoComplete="off"
                        />
                    </Form.Item>
                </ThingInput>
                <ThingInput visible={true} label="Title">
                    <Form.Item
                        name="linkTitle"
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: "Required",
                                whitespace: true,
                            },
                            createLengthValidationRule(200, "Link Title"),
                        ]}
                    >
                        <Input
                            placeholder="Enter the title (friendly name) for your link"
                            allowClear
                        />
                    </Form.Item>
                </ThingInput>
                <ThingInput visible={true} label="Type">
                    <Form.Item
                        name="linkType"
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: "Required",
                            },
                        ]}
                    >
                        <Select
                            name="linkType"
                            placeholder="Select link type"
                            rules={[
                                {
                                    required: true,
                                    message: "Required",
                                },
                            ]}
                        >
                            <Option key="" value=""></Option>
                            {linkTypesList.map((t) => (
                                <Option key={t.linkTypeId} value={t.linkTypeId}>
                                    {t.localizedName}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </ThingInput>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Apply Changes
                    </Button>
                    <Button
                        htmlType="reset"
                        onClick={props.onClose}
                        className="tt-button"
                    >
                        Discard Changes
                    </Button>
                </Form.Item>
            </Form>
        </Fragment>
    );
};

export default ThingNewLink;
