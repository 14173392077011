import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import auth from "./authReducers";
import accountReducer from "./accountReducers";
import personReducer from "./personReducers";
import selected from "./selectedReducers";
import thingReducer from "./thingReducers";
import thinglists from "./thinglistsReducers";
import sharedListsReducer from "./sharedListsReducer";
import importantThingsReducer from "./importantThingsReducer";

const rootReducer = (history) => {
    return combineReducers({
        auth: auth,
        account: accountReducer,
        importantThings: importantThingsReducer,
        person: personReducer,
        sharedLists: sharedListsReducer,
        selected: selected,
        thing: thingReducer,
        thinglists: thinglists,
        router: connectRouter(history),
    });
};

export default rootReducer;
