import { PersonActions } from "../actions/personActions";

const initialState = {
    // Person
    selectedPerson: null,
    isUpdatingPerson: false,
};

function personReducer(state = initialState, action) {
    switch (action.type) {
        case PersonActions.SET_SELECTED_PERSON:
            return {
                ...state,
                selectedPerson: action.data,
            };

        case PersonActions.UPDATE_PERSON_BEGIN:
            return {
                ...state,
                isUpdatingPerson: true,
            };

        case PersonActions.UPDATE_PERSON_END_OK:
            return {
                ...state,
                isUpdatingPerson: false,
            };

        case PersonActions.UPDATE_PERSON_END_FAIL:
            return {
                ...state,
                isUpdatingPerson: false,
            };

        default:
            return state;
    }
}

export default personReducer;
