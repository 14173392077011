import React from "react";

export const Logo = () => {
    return (
        <div className="tlife">
            Tracked<span className="tlife2">.</span>
            <span className="tlife3">Life</span>
        </div>
    );
};

export default Logo;
