import React from "react";
import { useSelector } from "react-redux";
import { Popover } from "antd";
import parse from "html-react-parser";
import { Defaults } from "../constants";
import LoadingIndicator from "./LoadingIndicator";
import { ImportantIcon, InProgressIcon } from "./ThingIcons";

export const TitleBar = (props) => {
    const tooltips = useSelector((state) => state.account.tooltipsList);

    const { isImportant, isInProgress } = props;

    var selectedItem = null;
    if (tooltips) {
        selectedItem = tooltips.filter((t) => t.key === props.tooltipkey)[0];
    }

    if (!tooltips) {
        return <LoadingIndicator />;
    }

    if (!selectedItem) {
        return (
            <div
                className="tt-title-bar"
                style={{ backgroundColor: props.color }}
            >
                TOOLTIP KEY NOT FOUND
            </div>
        );
    }

    var title = selectedItem.title;
    if (props.tooltipkey === "Header_ThingDetails") {
        if (props.newThing) {
            title = "NEW THING";
        } else {
            if (props.thingName !== null) {
                title = props.thingName;
            }
        }
    }

    let showPopover = true;
    if (props.tooltipkey === "Header_ThingDetails") {
        showPopover = false;
    }

    if (showPopover) {
        return (
            <Popover
                trigger="hover"
                placement="rightTop"
                content={parse(selectedItem.content)}
                title={title}
                mouseEnterDelay={Defaults.POPOVER_MOUSE_ENTER_DELAY_SLOW}
                mouseLeaveDelay={Defaults.POPOVER_MOUSE_LEAVE_DELAY}
            >
                <div
                    className="tt-title-bar"
                    style={{ backgroundColor: props.color }}
                >
                    {isImportant && <ImportantIcon />}
                    {isInProgress && <InProgressIcon />}
                    <span className="tt-title-bar-title">{title}</span>
                </div>
            </Popover>
        );
    }

    return (
        <div className="tt-title-bar" style={{ backgroundColor: props.color }}>
            {isImportant && <ImportantIcon />}
            {isInProgress && <InProgressIcon />}
            <span className="tt-title-bar-title">{title}</span>
        </div>
    );
};

export default TitleBar;
