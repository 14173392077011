import axios from "axios";

import authService from "../../auth/AuthenticationService";

import store from "../store";

async function createHeadersAsync() {
    const authToken = await authService.getAccessTokenAsync();
    let headers = {};

    if (!!authToken) {
        headers["Authorization"] = `Bearer ${authToken}`;
    }

    let state = store.getState();
    let accountId = state.account.currentAccount?.accountId;
    if (!!accountId) {
        headers["TLifeAccount"] = `${accountId}`;
    }

    // console.log("Request headers", headers);
    return headers;
}

function logAjaxErrorInfo(error) {
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(
            "Ajax Error Response: ",
            "data=",
            error.response.data,
            "status=",
            error.response.status,
            "headers=",
            error.response.headers
        );
    } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log("Ajax Error Request: ", error.request);
    }
}

export async function ajaxGetAsync(
    apiUrl,
    actionInitiate,
    actionSuccess,
    actionFailure,
    dispatchAsync
) {
    await dispatchAsync({ type: actionInitiate });

    try {
        // DEBUG: Uncomment to simulate delay
        // await sleep(3000);

        await axios
            .get(apiUrl, {
                headers: await createHeadersAsync(),
            })
            .then(async (response) => {
                await dispatchAsync({
                    type: actionSuccess,
                    data: response.data,
                });
            })
            .catch(async (error) => {
                console.error("axios get error 1", error);
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log("axios get error 1a", error.response.data);
                    console.log("axios get error 1b", error.response.status);
                    console.log("axios get error 1c", error.response.headers);
                    await dispatchAsync({
                        type: actionFailure,
                        data: error.response,
                    });
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log("axios get error 1d", error.request);
                    await dispatchAsync({
                        type: actionFailure,
                        data: error.request,
                    });
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log("axios get error 1e", error.message);
                    await dispatchAsync({ type: actionFailure, data: error });
                }
            });
    } catch (error) {
        console.error("axios get error 2", error);
        await dispatchAsync({ type: actionFailure, data: error });
    }
}

export async function ajaxPostAsync(
    apiUrl,
    actionInitiate,
    actionSuccess,
    actionFailure,
    dispatchAsync,
    postData
) {
    await dispatchAsync({ type: actionInitiate });

    try {
        // DEBUG: Uncomment to simulate delay
        // await sleep(3000);

        await axios
            .post(apiUrl, postData, {
                headers: await createHeadersAsync(),
            })
            .then(async (response) => {
                //console.log("axios post result", response.data);
                await dispatchAsync({ type: actionSuccess, data: response });
            })
            .catch(async (error) => {
                console.error("axios post error 1", error);
                await dispatchAsync({ type: actionFailure, data: error });
            });
    } catch (error) {
        console.error("axios post error 2", error);
        await dispatchAsync({ type: actionFailure, data: error });
    }
}

export async function ajaxGetAsync2(apiUrl, getData, timeout) {
    try {
        // DEBUG: Uncomment to simulate delay
        // await sleep(3000);

        return await axios.get(apiUrl, {
            headers: await createHeadersAsync(),
            params: getData,
            timeout: timeout ?? 0,
        });
    } catch (error) {
        console.error("Async Ajax Request failed with error:", error);
        logAjaxErrorInfo(error);
        throw error;
    }
}

export async function ajaxPostAsync2(apiUrl, postData) {
    try {
        // DEBUG: Uncomment to simulate delay
        // await sleep(3000);

        return await axios.post(apiUrl, postData, {
            headers: await createHeadersAsync(),
        });
    } catch (error) {
        console.error("axios post error 2", error);
        throw error;
    }
}
export function ajaxGetAnonymous(url) {
    // console.log("Anonymous Get Request to URL", url);
    return fetch(url, {
        redirect: "manual",
    })
        .then((response) => {
            // console.log("Anonymous Get Request Response", response);

            if (response.type === "opaqueredirect") {
                window.location.href = response.url;
            } else {
                return response;
            }
        })
        .catch((error) => {
            console.error("get error anonymous", error);
            throw error;
        });
}
