import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Spin } from "antd";
import { CookieKeys, Routes } from "../constants";
import { Redirect } from "react-router-dom";
import cookieService from "../cookies/CookieService";
import { setCurrentAccountDispatchable } from "../redux/actions/accountActions";

/** Component to handle cases where the user has multiple or no accounts */
export const UserAccountPage = (props) => {
    const {
        redirectToNewAccountIfNoUserAccounts,
        attemptCookieAccountDeductionIfNoSelectedAccount,
        redirectToAccountSelectIfNoSelectedAccount,
    } = props;

    const dispatch = useDispatch();
    const myAccountsData = useSelector((state) => state.account.myAccounts);
    const currentAccount = useSelector((state) => state.account.currentAccount);

    // Spin until we have the myAccounts data
    if (myAccountsData.isLoading || !myAccountsData.hasData) {
        return <Spin />;
    }

    // Handle the case where the user has no accounts, and needs to create one
    if (
        redirectToNewAccountIfNoUserAccounts &&
        myAccountsData.data.accounts.length === 0
    ) {
        console.log(
            "User does not currently have an account, redirecting to account setup."
        );
        return <Redirect to={Routes.NEW_ACCOUNT} />;
    }

    // Handle the case where the user has multiple accounts, but hasn't selected one
    if (myAccountsData.data.accounts.length > 1 && !currentAccount) {
        // Attempt cookie deduction
        if (attemptCookieAccountDeductionIfNoSelectedAccount) {
            console.log(
                "User has multiple accounts, attempting cookie deduction"
            );
            let cookie = cookieService.get(CookieKeys.LastSelectedAccount);
            if (!!cookie) {
                let cookieAccountId = cookie.accountId;
                let cookieAccount = myAccountsData.data.accounts.find(
                    (a) => a.accountId === cookieAccountId
                );
                if (!!cookieAccount) {
                    console.log(
                        "User has multiple accounts, selecting last selected account using cookie"
                    );
                    dispatch(setCurrentAccountDispatchable(cookieAccount));
                    return <Spin />;
                } else {
                    console.log(
                        "User has multiple accounts, but cookie didn't match any of those accounts"
                    );
                }
            }
        }

        if (redirectToAccountSelectIfNoSelectedAccount) {
            console.log(
                "User has multiple accounts, so redirecting to account select."
            );
            let redirectUrl =
                Routes.ACCOUNT_SELECT +
                "?returnUrl=" +
                encodeURIComponent(window.location.pathname);
            return <Redirect to={redirectUrl} />;
        }
    }

    return <Fragment>{props.children}</Fragment>;
};

export default function wrapUserAccountPage(multiAccountOptions, page) {
    return (props) => {
        return (
            <UserAccountPage {...multiAccountOptions}>
                {page(props)}
            </UserAccountPage>
        );
    };
}
