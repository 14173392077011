import React from "react";
import { PageHeader } from "antd";

export const Header = (props) => {
    return (
        <div>
            <PageHeader className="tt-page-header" title={props.title} />
        </div>
    );
};

export default Header;
