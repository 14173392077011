import React, { Fragment, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Card, Col, Spin, List } from "antd";

import {
    showNotificationError,
    personTypeDescription,
    personTypeToSortOrder,
} from "../../functions";
import { setCurrentAccountDispatchable } from "../../redux/actions/accountActions";
import { push } from "connected-react-router";
import { Routes } from "../../constants";

export const AccountSelectContainer = () => {
    const dispatch = useDispatch();

    const notificationKey = "NK_AccountSelect";

    const getReturnUrl = () => {
        // Try to get a query string parameter for the return Url
        let params = new URLSearchParams(window.location.search);
        let returnUrlFromQueryString = params.get("returnUrl");

        return returnUrlFromQueryString ?? Routes.DASHBOARD;
    };

    const myAccountsData = useSelector((state) => state.account.myAccounts);

    const sortAccounts = (accounts) => {
        return accounts.sort((a1, a2) => {
            // Sort by role in account
            let a1RoleOrder = personTypeToSortOrder(a1.userRoleInAccount);
            let a2RoleOrder = personTypeToSortOrder(a2.userRoleInAccount);
            
            if (a1RoleOrder !== a2RoleOrder) {
                return Math.sign(a1RoleOrder - a2RoleOrder);
            }
            
            // Then by primary owner full name
            if (a1.primaryOwnerFullName < a2.primaryOwnerFullName) return -1;
            if (a1.primaryOwnerFullName > a2.primaryOwnerFullName) return 1;

            return 0;
        });
    };

    const performSelectAccount = useCallback(
        (account) => {
            // Set the current account
            dispatch(setCurrentAccountDispatchable(account));
            // Redirect
            dispatch(push(getReturnUrl()));
        },
        [dispatch]
    );

    // Effect to auto-select the account if there is only one
    useEffect(() => {
        if (
            myAccountsData.hasData &&
            myAccountsData.data.accounts.length === 1
        ) {
            console.log("User only has one account, so auto-selecting it.");
            let autoSelectedAccount = myAccountsData.data.accounts[0];
            performSelectAccount(autoSelectedAccount);
        }
    }, [dispatch, myAccountsData, performSelectAccount]);

    if (!myAccountsData.hasData) {
        return <Spin />;
    }

    if (myAccountsData.hasError) {
        // Show error notification
        console.error("My Accounts Error:", myAccountsData.error);
        showNotificationError(
            notificationKey,
            "Failed to load your accounts!",
            "Refresh the page and try again"
        );
    }

    const renderAccountOption = (account) => {
        return (
            <List.Item
                onClick={() => performSelectAccount(account)}
                className="tt-account-select-account-option tt-hover"
            >
                <div className="tt-account-select-account-name">
                    <b>{account.primaryOwnerFullName}'s Account</b>
                </div>
                <div className="tt-account-select-account-role">
                    {personTypeDescription(account.userRoleInAccount)}
                </div>
            </List.Item>
        );
    };

    return (
        <Fragment>
            <Col offset={6} span={12}>
                <Card title="Select the account you want to manage">
                    <List
                        bordered
                        itemLayout="vertical"
                        dataSource={sortAccounts(myAccountsData.data.accounts)}
                        renderItem={renderAccountOption}
                    />
                </Card>
            </Col>
        </Fragment>
    );
};

export default AccountSelectContainer;
