import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Collapse, Badge } from "antd";
import { CaretRightFilled } from "@ant-design/icons";

import { MessageError } from "../../shared/MessageError";
import LoadingIndicator from "../../shared/LoadingIndicator";
import { GroupByTypes } from "../../constants";

import ThingsList from "./ThingsList";
import { loadActiveThing } from "../../redux/actions";

export const GroupByList = () => {
    const dispatch = useDispatch();
    const { Panel } = Collapse;
    const groupByType = useSelector((state) => state.selected.groupByType);
    const groupByList = useSelector((state) => state.thinglists.groupByThings);
    const loading = useSelector(
        (state) => state.thinglists.groupByThingsLoading
    );
    const error = useSelector((state) => state.thinglists.groupByThingsError);
    const activeThingId = useSelector((state) => state.thing.activeThingId);
    const searchText = useSelector((state) => state.selected.searchText);
    const isThingLoading = useSelector((state) => state.thing.isThingLoading);
    const newThing = useSelector((state) => state.thing.newThing);

    useEffect(() => {
        // if we don't have an active thing load the first item in the list
        if (
            !newThing &&
            searchText !== "" &&
            !isThingLoading &&
            groupByList &&
            groupByList.listData
        ) {
            if (
                groupByList.listData[0] &&
                groupByList.listData[0].things.length > 0
            ) {
                if (!activeThingId) {
                    dispatch(
                        loadActiveThing(
                            groupByList.listData[0].things[0].thingId
                        )
                    );
                }
            }
        }
    }, [
        activeThingId,
        dispatch,
        groupByList,
        newThing,
        searchText,
        isThingLoading,
    ]);

    const findGroupIdByThingId = (thingId) => {
        var groupId = null;
        if (groupByList && groupByList.listData) {
            for (var group of groupByList.listData) {
                for (var thing of group.things) {
                    if (thing.thingId === thingId) {
                        groupId = group.groupTypeId;
                        break;
                    }
                }
                if (groupId !== null) {
                    break;
                }
            }
        }
        return groupId;
    };

    const groupName = (name, count) => {
        return (
            <div>
                {name}
                <Badge
                    count={count}
                    className="tt-group-count-badge"
                    overflowCount={999}
                />
            </div>
        );
    };

    var preExpandedList = [];
    if (groupByType === GroupByTypes.SEARCH_AREA) {
        var saName = false;
        var saDetails = false;
        if (groupByList && groupByList.count > 0) {
            groupByList.listData.forEach((g) => {
                if (g.groupTypeId === "01TN") {
                    saName = true;
                } else if (g.groupTypeId === "02TD") {
                    saDetails = true;
                }
            });
            if (saName || saDetails) {
                preExpandedList = ["01TN", "02TD"];
            } else {
                preExpandedList = [groupByList.listData[0].groupTypeId];
            }
        }
    } else {
        if (preExpandedList.length === 0) {
            if (groupByList && groupByList.count > 0) {
                if (activeThingId) {
                    var groupId = findGroupIdByThingId(activeThingId);
                    if (groupId) {
                        preExpandedList = [groupId];
                    }
                }
            }
        }
    }

    if (error) {
        return <MessageError error={error} />;
    }
    if (loading || !groupByList) {
        return <LoadingIndicator />;
    }
    if (groupByList.listData.length === 0) {
        if (searchText === "") {
            return (
                <div className="tt-empty-list">You do not have any things</div>
            );
        } else {
            return (
                <div className="tt-empty-list">
                    No things were found that match your search
                </div>
            );
        }
    }
    if (groupByType === GroupByTypes.THING_NAME) {
        return (
            <Fragment>
                {groupByList.listData.map((type) => (
                    <ThingsList
                        key={type.groupTypeId}
                        thingList={type.things}
                        groupByType={groupByType}
                    />
                ))}
            </Fragment>
        );
    }
    return (
        <Collapse
            bordered={false}
            defaultActiveKey={preExpandedList}
            expandIcon={({ isActive }) => (
                <CaretRightFilled rotate={isActive ? 90 : 0} />
            )}
        >
            {groupByList.listData.map((type) => (
                <Panel
                    header={groupName(type.name, type.things.length)}
                    key={type.groupTypeId}
                    className="tt-collapsible-panel"
                >
                    <ThingsList
                        thingList={type.things}
                        groupByType={groupByType}
                    />
                </Panel>
            ))}
        </Collapse>
    );
};

export default GroupByList;
