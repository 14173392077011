import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Input, Form, Select, Spin, Popconfirm } from "antd";

import {
    showNotificationSuccess,
    compareValues,
    showNotificationError,
    loginProviderIdToString,
    defaultDateFormat,
    createLengthValidationRule,
    trimAuthInfoFromEmail,
} from "../../functions";
import { Application, PersonStatuses, PersonTypeIds } from "../../constants";

import ThingInput from "../things/ThingInput";
import {
    approveInvite,
    PersonActions,
    rejectInvite,
    updatePerson,
} from "../../redux/actions/personActions";
import { updatePersonInList } from "../../redux/actions/accountActions";
import moment from "moment";

import keyMirror from "keymirror";
import { createUniquePersonNameValidator } from "../../shared/FormItemValidators";

const canAccountEditPersonTypes = (accountType) =>
    accountType === PersonTypeIds.PRIMARY_OWNER;

const wouldUpdateRequireInvite = (person, newAccountType) =>
    newAccountType === PersonTypeIds.SECONDARY_OWNER &&
    person?.personStatusId !== PersonStatuses.VERIFIED;

const wouldUpdateDowngradePerson = (person, newAccountType) =>
    newAccountType !== PersonTypeIds.SECONDARY_OWNER &&
    person?.accountPersonTypeId === PersonTypeIds.SECONDARY_OWNER;

const PersonEditTypes = keyMirror({
    CREATE: null,
    CREATE_AND_INVITE: null,
    UPDATE: null,
    UPDATE_AND_INVITE: null,
    UPDATE_AND_CANCEL_INVITE: null,
    UPDATE_AND_DOWNGRADE: null,
    VERIFY: null,
});

const getPersonEditType = (
    person,
    newAccountType,
    isNewPerson,
    doesPersonRequireVerification
) => {
    if (doesPersonRequireVerification) return PersonEditTypes.VERIFY;

    let isInviting = wouldUpdateRequireInvite(person, newAccountType);
    if (isInviting)
        return isNewPerson
            ? PersonEditTypes.CREATE_AND_INVITE
            : PersonEditTypes.UPDATE_AND_INVITE;

    let isDowngradingPerson = wouldUpdateDowngradePerson(
        person,
        newAccountType
    );
    if (isDowngradingPerson) {
        let isInviteOutstanding =
            person?.personStatusId !== PersonStatuses.VERIFIED;
        return isInviteOutstanding
            ? PersonEditTypes.UPDATE_AND_CANCEL_INVITE
            : PersonEditTypes.UPDATE_AND_DOWNGRADE;
    }

    return isNewPerson ? PersonEditTypes.CREATE : PersonEditTypes.UPDATE;
};

export const PersonEditor = (props) => {
    const { Option } = Select;
    const dispatch = useDispatch();
    const countryCode = useSelector(
        (state) => state.account.accountCountryCode
    );
    const hasPermissionToEditPersonType = useSelector((state) =>
        canAccountEditPersonTypes(
            state.account.currentAccount?.userRoleInAccount
        )
    );

    const [form] = Form.useForm();
    const [formRef, setFormRef] = useState();
    const notificationKey = "NK_PersonUpdate";

    const allPeople = useSelector((state) => state.account.peopleList);
    const selectedPerson = useSelector((state) => state.person.selectedPerson);
    const isNewPerson = useSelector(
        (state) => state.person.selectedPerson === null
    );
    const [selectedPersonAccountType, setPersonAccountType] = useState(
        selectedPerson?.accountPersonTypeId ?? PersonTypeIds.PERSON
    );
    const isUpdatingPerson = useSelector(
        (state) => state.person.isUpdatingPerson
    );
    const doesPersonRequireVerification = useSelector(
        (state) =>
            state.person.selectedPerson?.personStatusId ===
            PersonStatuses.PENDING
    );
    const isUninvitingPerson =
        wouldUpdateDowngradePerson(selectedPerson, selectedPersonAccountType) &&
        selectedPerson?.personStatusId === PersonStatuses.VERIFIED;

    const [isEmailRequired, setIsEmailRequired] = useState(
        selectedPersonAccountType !== PersonTypeIds.PERSON
    );

    const typesList = useSelector((state) => state.account.personTypesList)
        .filter(
            (t) =>
                t.accountPersonTypeId !== PersonTypeIds.PRIMARY_OWNER &&
                t.accountPersonTypeId !== PersonTypeIds.INHERITOR &&
                t.accountPersonTypeId !== PersonTypeIds.VIEWER &&
                t.accountPersonTypeId !== PersonTypeIds.VIEWER_INHERITOR
        )
        .sort(compareValues("sort", "asc"));

    const uniqueEmailValidator = (rule, value, callback) => {
        return new Promise((resolve, reject) => {
            let peopleWithSameEmail = allPeople.filter((p) => {
                if (p?.personId === selectedPerson?.personId) return false; // Same person, no need to check email

                if (p?.email === null || p?.email === "") return false; // No email

                return p.email.toLowerCase() === value.toLowerCase();
            });
            if (peopleWithSameEmail.length > 0)
                reject(
                    "This email address is already associated with another person"
                );
            resolve();
        });
    };

    const submitPersonForm = async (values) => {
        // Update values to include selected person ID
        values.newPerson = isNewPerson;
        values.personId = selectedPerson?.personId ?? null;

        try {
            // Start person update
            dispatch({ type: PersonActions.UPDATE_PERSON_BEGIN });
            let updatedPerson = await updatePerson(values);
            dispatch({
                type: PersonActions.UPDATE_PERSON_END_OK,
                data: updatedPerson,
            });
            dispatch(updatePersonInList(updatedPerson));

            // Success
            form.resetFields();
            showNotificationSuccess(notificationKey, "Person updated", null);
            props.onClose();
        } catch (ex) {
            // Failure
            dispatch({ type: PersonActions.UPDATE_PERSON_END_FAIL });
            let errorMessage = ex.response?.data || ex.message;
            showNotificationError(
                notificationKey,
                "Failed to update person!",
                errorMessage
            );
        }
    };

    const handleApproveInviteClick = async (person) => {
        try {
            dispatch({ type: PersonActions.UPDATE_PERSON_BEGIN });
            let updatedPerson = await approveInvite(person);
            dispatch({
                type: PersonActions.UPDATE_PERSON_END_OK,
                data: updatedPerson,
            });
            dispatch(updatePersonInList(updatedPerson));
            showNotificationSuccess(
                notificationKey,
                "Invitation approved",
                null
            );
            props.onClose();
        } catch (ex) {
            showNotificationError(
                notificationKey,
                "Failed to approve invitation",
                ex
            );
        } finally {
            dispatch({ type: PersonActions.UPDATE_PERSON_END_FAIL });
        }
    };

    const handleRejectInviteClick = async (person) => {
        try {
            dispatch({ type: PersonActions.UPDATE_PERSON_BEGIN });
            let updatedPerson = await rejectInvite(person);
            dispatch({
                type: PersonActions.UPDATE_PERSON_END_OK,
                data: updatedPerson,
            });
            dispatch(updatePersonInList(updatedPerson));
            showNotificationSuccess(
                notificationKey,
                "Invitation rejected",
                null
            );
            props.onClose();
        } catch (ex) {
            showNotificationError(
                notificationKey,
                "Failed to reject invitation",
                ex
            );
        } finally {
            dispatch({ type: PersonActions.UPDATE_PERSON_END_FAIL });
        }
    };

    // Effect: When the selected person changes, refresh the form initial values
    useEffect(() => {
        if (formRef) {
            // console.log("Reseting PersonEditor fields", selectedPerson);
            setPersonAccountType(
                selectedPerson?.accountPersonTypeId ?? PersonTypeIds.PERSON
            );
            form.resetFields();
        }
    }, [form, formRef, selectedPerson, setPersonAccountType]);

    // Effect: Valid the email when isEmailRequired changes
    useEffect(() => {
        form.validateFields(["email"]);
    }, [form, isEmailRequired]);

    const initialFormValues = {
        personId: selectedPerson?.personId ?? "",
        firstName: selectedPerson?.firstName ?? "",
        lastName: selectedPerson?.lastName ?? "",
        email: selectedPerson?.email ?? "",
        accountPersonTypeId:
            selectedPerson?.accountPersonTypeId ?? PersonTypeIds.PERSON,
    };

    const renderButtonBar = () => {
        if (isUpdatingPerson) return <Spin />;

        let editType = getPersonEditType(
            selectedPerson,
            selectedPersonAccountType,
            isNewPerson,
            doesPersonRequireVerification
        );
        switch (editType) {
            case PersonEditTypes.CREATE:
            case PersonEditTypes.UPDATE:
                return (
                    <Fragment>
                        <Button type="danger" htmlType="submit">
                            {editType === PersonEditTypes.CREATE
                                ? "Create"
                                : "Update"}{" "}
                            Person
                        </Button>
                        <Button
                            htmlType="reset"
                            onClick={props.onClose}
                            className="tt-button"
                        >
                            Discard Changes
                        </Button>
                    </Fragment>
                );

            case PersonEditTypes.CREATE_AND_INVITE:
            case PersonEditTypes.UPDATE_AND_INVITE:
                let confirmationWording =
                    (isNewPerson
                        ? "Creating this person as"
                        : "Changing this person to") +
                    " a Secondary Owner will require inviting them to your " +
                    Application.Name +
                    " account. Please confirm their email before continuing.";
                return (
                    <Fragment>
                        <Popconfirm
                            title={confirmationWording}
                            placement="bottom"
                            okText="Continue"
                            cancelText="Cancel"
                            onConfirm={() => form.submit()}
                            disabled={false}
                        >
                            <Button type="danger" htmlType="submit">
                                {editType === PersonEditTypes.CREATE_AND_INVITE
                                    ? "Create"
                                    : "Update"}{" "}
                                and Invite Person
                            </Button>
                        </Popconfirm>
                        <Button
                            htmlType="reset"
                            onClick={props.onClose}
                            className="tt-button"
                        >
                            Discard Changes
                        </Button>
                    </Fragment>
                );

            case PersonEditTypes.UPDATE_AND_CANCEL_INVITE:
                return (
                    <Fragment>
                        <Button type="danger" htmlType="submit">
                            Update and Cancel Invite
                        </Button>
                        <Button
                            htmlType="reset"
                            onClick={props.onClose}
                            className="tt-button"
                        >
                            Discard Changes
                        </Button>
                    </Fragment>
                );

            case PersonEditTypes.UPDATE_AND_DOWNGRADE:
                let downgradeWarning =
                    "Changing this person's type will remove their access from your " +
                    Application.Name +
                    " account. Please confirm this is what you want to do before continuing.";
                return (
                    <Fragment>
                        <Popconfirm
                            title={downgradeWarning}
                            placement="bottom"
                            okText="Continue"
                            cancelText="Cancel"
                            onConfirm={() => form.submit()}
                            disabled={false}
                        >
                            <Button type="danger" htmlType="submit">
                                Update and Uninvite Person
                            </Button>
                        </Popconfirm>
                        <Button
                            htmlType="reset"
                            onClick={props.onClose}
                            className="tt-button"
                        >
                            Discard Changes
                        </Button>
                    </Fragment>
                );

            case PersonEditTypes.VERIFY:
                return (
                    <Fragment>
                        <Button
                            type="danger"
                            onClick={() =>
                                handleApproveInviteClick(selectedPerson)
                            }
                        >
                            Approve
                        </Button>
                        <Button
                            htmlType="submit"
                            onClick={() =>
                                handleRejectInviteClick(selectedPerson)
                            }
                            className="tt-button"
                        >
                            Reject
                        </Button>
                    </Fragment>
                );

            default:
                throw Error(`Unimplemented save button: [${editType}]`);
        }
    };

    const renderUninviteWarning = () => {
        return (
            <Fragment>
                <div>
                    <h3>Warning!</h3>
                    <div>
                        Changing this person's type will uninvite them and they
                        will no longer be able to access your {Application.Name}{" "}
                        account.
                    </div>
                </div>
                <div className="tt-spacer" />
            </Fragment>
        );
    };

    const renderVerificationSection = (person) => {
        let timestampString = person?.invitationAccepted;
        let timestamp = moment(timestampString);
        return (
            <Fragment>
                <div className="tt-spacer" />
                <div>
                    This invitation was accepted on{" "}
                    <b>
                        {timestamp.format(
                            "dddd, " +
                                defaultDateFormat(countryCode) +
                                " [at] h:mm a"
                        )}
                    </b>{" "}
                    by <b>{trimAuthInfoFromEmail(person?.username)}</b> using a{" "}
                    <b>{loginProviderIdToString(person?.loginProviderId)}</b>{" "}
                    account
                </div>
                <div className="tt-spacer" />
            </Fragment>
        );
    };

    return (
        <Fragment>
            <h3>Person Details</h3>

            <Form
                name="personForm"
                form={form}
                ref={setFormRef}
                onFinish={submitPersonForm}
                layout="vertical"
                hideRequiredMark
                scrollToFirstError
                initialValues={initialFormValues}
            >
                <ThingInput visible={true} label="First Name">
                    <Form.Item
                        name="firstName"
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: "Required",
                                whitespace: true,
                            },
                            createLengthValidationRule(100, "First Name"),
                            {
                                validator: (rule, value, callback) =>
                                    createUniquePersonNameValidator(
                                        form,
                                        true,
                                        value,
                                        form.getFieldValue("lastName"),
                                        allPeople,
                                        selectedPerson
                                    ),
                            },
                        ]}
                    >
                        <Input
                            placeholder="Enter a first name for the person"
                            allowClear
                            autoFocus
                            autoComplete="off"
                            disabled={doesPersonRequireVerification}
                        />
                    </Form.Item>
                </ThingInput>
                <ThingInput visible={true} label="Last Name">
                    <Form.Item
                        name="lastName"
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: "Required",
                                whitespace: true,
                            },
                            createLengthValidationRule(100, "Last Name"),
                            {
                                validator: (rule, value, callback) =>
                                    createUniquePersonNameValidator(
                                        form,
                                        false,
                                        form.getFieldValue("firstName"),
                                        value,
                                        allPeople,
                                        selectedPerson
                                    ),
                            },
                        ]}
                    >
                        <Input
                            placeholder="Enter a last name for the person"
                            allowClear
                            autoComplete="off"
                            disabled={doesPersonRequireVerification}
                        />
                    </Form.Item>
                </ThingInput>
                <ThingInput visible={true} label="Email">
                    <Form.Item
                        name="email"
                        hasFeedback
                        rules={[
                            {
                                type: "email",
                                message: "Please provide a valid email",
                            },
                            createLengthValidationRule(256, "Email"), // Antd's email pattern already checks for length, but this is just in case
                            {
                                required: isEmailRequired,
                                message: "Required",
                                whitespace: true,
                            },
                            {
                                validator: uniqueEmailValidator,
                            },
                        ]}
                    >
                        <Input
                            placeholder="Enter an email address for the person"
                            allowClear
                            autoComplete="off"
                            disabled={doesPersonRequireVerification}
                        />
                    </Form.Item>
                </ThingInput>
                <ThingInput visible={true} label="Type">
                    <Form.Item
                        name="accountPersonTypeId"
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: "Required",
                            },
                        ]}
                    >
                        <Select
                            placeholder="Select person type"
                            disabled={
                                !hasPermissionToEditPersonType ||
                                doesPersonRequireVerification
                            }
                            onSelect={(newKey) => {
                                setPersonAccountType(newKey);
                                setIsEmailRequired(
                                    newKey !== PersonTypeIds.PERSON
                                );
                            }}
                            value={selectedPersonAccountType}
                        >
                            {typesList.map((t) => (
                                <Option
                                    key={t.accountPersonTypeId}
                                    value={t.accountPersonTypeId}
                                >
                                    {t.localizedName}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </ThingInput>

                {isUninvitingPerson && renderUninviteWarning()}
                {doesPersonRequireVerification &&
                    renderVerificationSection(selectedPerson)}

                <Form.Item>{renderButtonBar()}</Form.Item>
            </Form>
        </Fragment>
    );
};

export default PersonEditor;
