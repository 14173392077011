import React, { Fragment, useState } from "react";
import {
    Collapse,
    Container,
    Navbar,
    NavbarBrand,
    NavbarToggler,
    Button,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";

import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { ThingTabs, Routes } from "../constants";
import {
    setNewThing,
    setActiveThingTab,
    clearSearchResults,
    unloadActiveThing,
} from "../redux/actions";

import ThingIsDirtyCheck from "./ThingIsDirtyCheck";
import ThingSimpleSearch from "./ThingSimpleSearch";
import Logo from "./Logo";
import TTooltip from "./TTooltip";
import { personTypeDescription } from "../functions";

export const NavigationBar = ({
    showAccountLinks,
}) => {
    const dispatch = useDispatch();
    const [collapsed, setCollapsed] = useState(false);
    const isAuthenticated = useSelector((state) => state.auth.authenticated);
    const userName = useSelector((state) => state.auth.userName);

    const accountId = useSelector(
        (state) => state.account.currentAccount?.accountId
    );
    const currentAccount = useSelector((state) => state.account.currentAccount);
    const numMyAccounts = useSelector(
        (state) => state.account.myAccounts.data?.accounts.length
    );

    const toggleNavbar = () => {
        setCollapsed(!collapsed);
    };

    const handleNewThing = () => {
        dispatch(setActiveThingTab(ThingTabs.ALL_THINGS));
        dispatch(clearSearchResults());
        dispatch(setNewThing());
    };

    const handleThings = () => {
        dispatch(clearSearchResults());
        dispatch(unloadActiveThing());
    };

    const clearSearchBeforeNavigating = () => {
        dispatch(clearSearchResults());
    };

    const renderAccountManageLine = (account) => {
        return (
            <Fragment>
                Managing{" "}
                <b>{account.primaryOwnerFullName}'s Account</b> as{" "}
                <b>{userName}</b> (
                {personTypeDescription(account.userRoleInAccount)})
            </Fragment>
        );
    };

    const renderUserIdentifier = () => {
        if (currentAccount) {
            // User has multiple accounts and has selected one, so render account name & role with user email tooltip to identify this account
            return (
                <div className="tt-navbar-account-identifier-text">
                    {currentAccount.primaryOwnerFullName}'s Account
                </div>
            );
        } else {
            // User has 0 or 1 account, so render user email to identify account
            return <Fragment>{userName}</Fragment>;
        }
    };

    const authenticatedView = (logoutPath) => {
        return (
            <ThingIsDirtyCheck>
                <Navbar
                    className="navbar-expand-md bg-white border-bottom box-shadow mb-3"
                    light
                    fixed="top"
                >
                    <Container>
                        <TTooltip ttkey="Nav_Dashboard" placement="bottom">
                            <NavbarBrand
                                tag={Link}
                                onClick={clearSearchBeforeNavigating}
                                to={Routes.DASHBOARD}
                            >
                                <Logo />
                            </NavbarBrand>
                        </TTooltip>
                        <NavbarToggler onClick={toggleNavbar} />
                        <Collapse
                            className="d-sm-inline-flex flex-sm-row-reverse navbar-collapse"
                            isOpen={!collapsed}
                            navbar
                        >
                            <ul className="navbar-nav flex-grow">
                                <ThingSimpleSearch />
                                <NavItem>
                                    <TTooltip
                                        ttkey="Nav_Dashboard"
                                        placement="bottom"
                                    >
                                        <NavLink
                                            tag={Link}
                                            className="navbar-item"
                                            onClick={
                                                clearSearchBeforeNavigating
                                            }
                                            to={Routes.DASHBOARD}
                                        >
                                            Dashboard
                                        </NavLink>
                                    </TTooltip>
                                </NavItem>
                                <NavItem>
                                    <TTooltip
                                        ttkey="Nav_NewThing"
                                        placement="bottom"
                                    >
                                        <NavLink
                                            tag={Link}
                                            className="navbar-item"
                                            onClick={handleNewThing}
                                            to={Routes.NEWTHING}
                                        >
                                            New Thing
                                        </NavLink>
                                    </TTooltip>
                                </NavItem>
                                <NavItem>
                                    <TTooltip
                                        ttkey="Nav_Things"
                                        placement="bottom"
                                    >
                                        <NavLink
                                            tag={Link}
                                            className="navbar-item"
                                            onClick={handleThings}
                                            to={Routes.THINGS}
                                        >
                                            Things
                                        </NavLink>
                                    </TTooltip>
                                </NavItem>
                                <NavItem>
                                    <TTooltip
                                        ttkey="Nav_ImportantThings"
                                        placement="bottom"
                                    >
                                        <NavLink
                                            tag={Link}
                                            className="navbar-item"
                                            onClick={
                                                clearSearchBeforeNavigating
                                            }
                                            to={Routes.IMPORTANTTHINGS}
                                        >
                                            Important Things
                                        </NavLink>
                                    </TTooltip>
                                </NavItem>
                                <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle
                                        nav
                                        caret
                                        className="navbar-item tt-navbar-account-identifier"
                                    >
                                        {renderUserIdentifier()}
                                    </DropdownToggle>
                                    <DropdownMenu
                                        right
                                        className={
                                            currentAccount
                                                ? "tt-navbar-dropdown-menu"
                                                : null
                                        }
                                    >
                                        {currentAccount && (
                                            <Fragment>
                                                <DropdownItem
                                                    tag="div"
                                                    className="tt-navbar-account-info"
                                                    disabled={true}
                                                >
                                                    {renderAccountManageLine(
                                                        currentAccount
                                                    )}
                                                </DropdownItem>
                                                <DropdownItem
                                                    divider
                                                    className="tt-navbar-account--dropdown-divider"
                                                />
                                            </Fragment>
                                        )}
                                        {numMyAccounts && numMyAccounts > 1 && (
                                            <Fragment>
                                                <TTooltip
                                                    ttkey="Nav_SwitchAccounts"
                                                    placement="left"
                                                >
                                                    <DropdownItem
                                                        tag={Link}
                                                        className="text-dark"
                                                        onClick={
                                                            clearSearchBeforeNavigating
                                                        }
                                                        to={
                                                            Routes.ACCOUNT_SELECT
                                                        }
                                                    >
                                                        Switch Account
                                                    </DropdownItem>
                                                </TTooltip>
                                                <DropdownItem divider />
                                            </Fragment>
                                        )}
                                        <TTooltip
                                            ttkey="Nav_People"
                                            placement="left"
                                        >
                                            <DropdownItem
                                                tag={Link}
                                                className="text-dark"
                                                onClick={
                                                    clearSearchBeforeNavigating
                                                }
                                                to={Routes.PEOPLE}
                                            >
                                                People & Inheritance
                                            </DropdownItem>
                                        </TTooltip>
                                        {/* <TTooltip
                                            ttkey="Nav_Lists"
                                            placement="left"
                                        >
                                            <DropdownItem
                                                tag={Link}
                                                className="text-dark"
                                                onClick={
                                                    clearSearchBeforeNavigating
                                                }
                                                to={Routes.LISTS}
                                            >
                                                Lists
                                            </DropdownItem>
                                        </TTooltip>
                                        <DropdownItem divider />
                                        <TTooltip
                                            ttkey="Nav_Account"
                                            placement="left"
                                        >
                                            <DropdownItem
                                                tag={Link}
                                                className="text-dark"
                                                onClick={
                                                    clearSearchBeforeNavigating
                                                }
                                                to={Routes.ACCOUNT}
                                            >
                                                Account
                                            </DropdownItem>
                                        </TTooltip>
                                        <TTooltip
                                            ttkey="Nav_Subscription"
                                            placement="left"
                                        >
                                            <DropdownItem
                                                tag={Link}
                                                className="text-dark"
                                                onClick={
                                                    clearSearchBeforeNavigating
                                                }
                                                to={Routes.SUBSCRIPTION}
                                            >
                                                Subscription
                                            </DropdownItem>
                                        </TTooltip>
                                        <DropdownItem divider />
                                        <TTooltip
                                            ttkey="Nav_NewUserTour"
                                            placement="left"
                                        >
                                            <DropdownItem
                                                tag={Link}
                                                className="text-dark"
                                                onClick={
                                                    clearSearchBeforeNavigating
                                                }
                                                to={Routes.TOUR}
                                            >
                                                New User Tour
                                            </DropdownItem>
                                        </TTooltip> */}
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <NavItem>
                                    <Button
                                        outline
                                        color="primary"
                                        size="sm"
                                        tag={Link}
                                        to={logoutPath}
                                    >
                                        Logout
                                    </Button>
                                </NavItem>
                            </ul>
                        </Collapse>
                    </Container>
                </Navbar>
            </ThingIsDirtyCheck>
        );
    };

    const authenticatedViewWithoutAccount = (logoutPath, homePath) => {
        return (
            <Navbar
                className="navbar-expand-md bg-white border-bottom box-shadow mb-3"
                light
                fixed="top"
            >
                <Container>
                    <NavbarBrand tag={Link} to={homePath}>
                        <Logo />
                    </NavbarBrand>
                    <NavbarToggler onClick={toggleNavbar} />
                    <Collapse
                        className="d-sm-inline-flex flex-sm-row-reverse navbar-collapse"
                        isOpen={!collapsed}
                        navbar
                    >
                        <ul className="navbar-nav flex-grow">
                            <NavItem>
                                <NavLink
                                    tag={Link}
                                    className="text-dark"
                                    to={homePath}
                                >
                                    {userName}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <Button
                                    outline
                                    color="primary"
                                    size="sm"
                                    tag={Link}
                                    to={logoutPath}
                                >
                                    Logout
                                </Button>
                            </NavItem>
                        </ul>
                    </Collapse>
                </Container>
            </Navbar>
        );
    };

    const anonymousView = () => {
        return (
            <Navbar
                className="navbar-expand-md bg-white border-bottom box-shadow mb-3"
                light
                fixed="top"
            >
                <Container>
                    <NavbarBrand tag={Link} to={Routes.HOME}>
                        <Logo />
                    </NavbarBrand>
                    <NavbarToggler onClick={toggleNavbar} />
                    <Collapse
                        className="d-sm-inline-flex flex-sm-row-reverse navbar-collapse"
                        isOpen={!collapsed}
                        navbar
                    >
                        <ul className="navbar-nav flex-grow"></ul>
                    </Collapse>
                </Container>
            </Navbar>
        );
    };

    if (!isAuthenticated) {
        return anonymousView();
    }

    if (!accountId || !showAccountLinks) {
        const logoutPath = {
            pathname: `${Routes.LOGOUT}`,
            state: { local: true },
        };

        const homePath =
            numMyAccounts && numMyAccounts > 0
                ? Routes.DASHBOARD // User has account(s) so links should point to the dashboard
                : Routes.NEW_ACCOUNT; // User does not have an account so links should point to the new account page

        return authenticatedViewWithoutAccount(logoutPath, homePath);
    }

    const logoutPath = { pathname: `${Routes.LOGOUT}`, state: { local: true } };
    return authenticatedView(logoutPath);
};

export default NavigationBar;
