import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    Input,
    Row,
    Col,
    Drawer,
    Popconfirm,
    Form,
    Popover,
} from "antd";

import { updateRelatedLinks } from "../../redux/actions";
import { compareValues, jsonClone } from "../../functions";

import { DeleteFilled } from "@ant-design/icons";
import ThingNewLink from "./ThingNewLink";
import { ThingUpdateActions, Defaults } from "../../constants";
import TTooltip from "../../shared/TTooltip";
import TitleWithPill from "../../shared/TitleWithPill";
import LoadingIndicator from "../../shared/LoadingIndicator";

export const ThingLinks = (props) => {
    const dispatch = useDispatch();
    const form = props.form;
    const [drawerVisible, setDrawerVisible] = useState(false);
    const activeThingLinks = useSelector(
        (state) => state.thing.activeThingLinks
    );
    const linkTypesList = useSelector((state) => state.account.linkTypesList);

    const findTypeNameByKey = (linkTypeId) => {
        let foundType = null;
        if (linkTypesList) {
            for (let type of linkTypesList) {
                if (type.linkTypeId === linkTypeId) {
                    foundType = type;
                    break;
                }
            }
        }
        if (foundType) {
            return foundType.localizedName;
        }
        return linkTypeId;
    };

    var relatedLinks = jsonClone(activeThingLinks);
    if (relatedLinks) {
        relatedLinks.forEach((l) => {
            l.sort = findTypeNameByKey(l.linkTypeId) + ": " + l.title;
        });
        relatedLinks.sort(compareValues("sort", "asc"));
    }

    const handleNewItemClick = () => {
        setDrawerVisible(true);
    };

    const handleDrawerClose = () => {
        setDrawerVisible(false);
        props.handleValuesChange();
    };

    // remove any non-existing items which have been marked for deletion
    const cleanupList = (list) => {
        var newList = [];
        list.forEach((l) => {
            if (l.existing || l.action !== ThingUpdateActions.DELETE) {
                newList.push(l);
            }
        });
        return newList;
    };

    const deleteLink = (tempLinkId) => {
        var newList = jsonClone(activeThingLinks);
        newList.forEach((p) => {
            if (p.tempLinkId === tempLinkId) {
                p.action = ThingUpdateActions.DELETE;
            }
        });
        dispatch(updateRelatedLinks(cleanupList(newList)));
        form.setFieldsValue({ hiddenLinkId: null }); // force form isFieldsTouched to register change
        props.handleValuesChange();
    };

    if (!linkTypesList) {
        return <LoadingIndicator />;
    }

    return (
        <div className="tt-ted-panel-container">
            <Form.Item name="hiddenLinkId" className="tt-hidden">
                <Input />
            </Form.Item>
            {relatedLinks &&
                relatedLinks.map((l) =>
                    l.action === ThingUpdateActions.DELETE ? null : (
                        <Row className="tt-ted-list-item" key={l.tempLinkId}>
                            <Col flex="1 1" className="tt-no-overflow">
                                <TitleWithPill
                                    titleContent={l.title}
                                    titleLinkUrl={l.url}
                                    pillContent={findTypeNameByKey(
                                        l.linkTypeId
                                    )}
                                />
                            </Col>
                            <Col flex="0 1" className="tt-ted-list-item-icon">
                                <Popover
                                    trigger="hover"
                                    placement="rightTop"
                                    title="Delete Link"
                                    content="Click here to delete this link"
                                    mouseEnterDelay={
                                        Defaults.POPOVER_MOUSE_ENTER_DELAY_FAST
                                    }
                                    mouseLeaveDelay={
                                        Defaults.POPOVER_MOUSE_LEAVE_DELAY
                                    }
                                >
                                    <Popconfirm
                                        title="Are you sure you want to delete this link?"
                                        okText="Yes"
                                        cancelText="No"
                                        onConfirm={(e) =>
                                            deleteLink(l.tempLinkId)
                                        }
                                    >
                                        <DeleteFilled />
                                    </Popconfirm>
                                </Popover>
                            </Col>
                        </Row>
                    )
                )}
            <Input.Group className="tt-ted-link-add">
                <TTooltip ttkey="ThingInput_RelatedLinkAdd">
                    <Button type="button" onClick={handleNewItemClick}>
                        Add Link
                    </Button>
                </TTooltip>
            </Input.Group>
            <Drawer
                title="New Link"
                placement="right"
                width={Defaults.DRAWER_WIDTH}
                closable={false}
                onClose={handleDrawerClose}
                visible={drawerVisible}
            >
                <ThingNewLink onClose={handleDrawerClose} form={props.form} />
            </Drawer>
        </div>
    );
};

export default ThingLinks;
