import React from "react";
import { Alert } from "antd";

export const MessageError = (props) => {
    if (props.error.message && props.error.stack) {
        return (
            <Alert
                type="error"
                className="tt-message-error"
                message={props.error.message}
                description={props.error.stack}
            />
        );
    }

    return (
        <Alert
            type="error"
            className="tt-message-error"
            message="Exception"
            description={props.error}
        />
    );
};

export default MessageError;
