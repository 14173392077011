import React from "react";
import { useSelector } from "react-redux";

import RecentThingsList from "../../shared/RecentThingsList";
import TitleBar from "../../shared/TitleBar";

// import DashboardMessage from './DashboardMessage';
import DashboardTip from "./DashboardTip";
import OverdueThingsCard from "./OverdueThingsCard";
import UpcomingThingsList from "./UpcomingThingsList";
import UpcomingPaymentsList from "./UpcomingPaymentsList";
import InProgressThingsCard from "./InProgressThingsCard";
import LoadingIndicator from "../../shared/LoadingIndicator";
import TotalThingsCard from "./TotalThingsCard";
import PaymentSummaryList from "./PaymentSummaryList";
import wrapUserAccountPage from "../../shared/UserAccountPage";

export const DashboardContainer = wrapUserAccountPage(
    {
        redirectToNewAccountIfNoUserAccounts: true,
        attemptCookieAccountDeductionIfNoSelectedAccount: true,
        redirectToAccountSelectIfNoSelectedAccount: true,
    },
    () => {
        const accountListsLoaded = useSelector(
            (state) => state.account.accountListsLoaded
        );

        if (!accountListsLoaded) {
            return (
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xs-12 col-md-12">
                            <LoadingIndicator />
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xs-12 col-md-12">
                        {/* <DashboardMessage /> */}
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-6 col-md-6">
                        <OverdueThingsCard />
                        <div>
                            <TitleBar
                                tooltipkey="Header_UpcomingThings"
                                color="#fd7e14"
                            />
                            <div className="tt-container">
                                <UpcomingThingsList />
                            </div>
                        </div>
                        <div>
                            <TitleBar
                                tooltipkey="Header_UpcomingPayments"
                                color="#28a745"
                            />
                            <div className="tt-container">
                                <UpcomingPaymentsList />
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-6 col-md-6">
                        <div>
                            <TitleBar
                                tooltipkey="Header_PaymentSummary"
                                color="#28a745"
                            />
                            <div className="tt-container">
                                <PaymentSummaryList />
                            </div>
                        </div>
                        <InProgressThingsCard />
                        <TotalThingsCard />
                        <DashboardTip />
                        <div>
                            <TitleBar
                                tooltipkey="Header_RecentThings"
                                color="#999999"
                            />
                            <div className="tt-container">
                                <RecentThingsList
                                    redirectOnClick={true}
                                    maxItems={5}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
);

export default DashboardContainer;
