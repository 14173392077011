import React from "react";
import { Tooltip } from "antd";

export const ImportantIcon = () => {
    return (
        <Tooltip title="Important Thing.">
            <span className="tt-list-item-important-icon">!</span>
        </Tooltip>
    );
};

export const InProgressIcon = () => {
    return (
        <Tooltip title="In Progress Thing. This Thing needs to be reviewed and marked as completed.">
            <span className="tt-list-item-in-progress-icon">?</span>
        </Tooltip>
    );
};
