import React from "react";

import ThingTitleInfo from "./ThingTitleInfo";

export const ThingCard = (props) => {
    return (
        <div className="tt-thing-card">
            <ThingTitleInfo thing={props.thing} />
        </div>
    );
};

export default ThingCard;
