import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    DatePicker,
    Select,
    Switch,
    Form,
    Input,
    Row,
    Col,
    Drawer,
    InputNumber,
} from "antd";

import { resetThingPost } from "../../redux/actions";
import {
    getPaymentThingsListDispatchable,
    reloadThingListsDispatchable,
} from "../../redux/actions/thingListsActions";
import LoadingIndicator from "../../shared/LoadingIndicator";
import { Defaults, ThingPostTypes, LastPostStatus } from "../../constants";
import { setFocus, sleep, dateFormatList } from "../../functions";

import ThingInput from "./ThingInput";
import ThingNewPaymentMethod from "./ThingNewPaymentMethod";
import ThingReminderSchedule from "./ThingReminderSchedule";

export const ThingPaymentDetailsContainer = (props) => {
    const form = props.form;
    const thing = props.thing;
    const dispatch = useDispatch();
    const currentAccount = useSelector((state) => state.account.currentAccount);
    const { Option } = Select;
    const [
        resetPaymentMethodSelectField,
        setResetPaymentMethodSelectField,
    ] = useState(false);
    const [
        resetPaymentFrequencySelectField,
        setResetPaymentFrequencySelectField,
    ] = useState(false);
    const [
        resetReminderScheduleSelectField,
        setResetReminderScheduleSelectField,
    ] = useState(false);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const paymentThingsSelect = useRef(null);
    const countryCode = useSelector(
        (state) => state.account.accountCountryCode
    );
    const paymentThings = useSelector(
        (state) => state.thinglists.paymentThingsList
    );
    const loading = useSelector(
        (state) => state.thinglists.paymentThingsListLoading
    );
    const frequencies = useSelector((state) => state.account.frequenciesList);
    const currencies = useSelector((state) => state.sharedLists.currencies);
    const reminderSchedules = useSelector(
        (state) => state.account.reminderSchedulesList
    );
    const [hasPaymentDate, setHasPaymentDate] = useState(
        props.initialFormValues && props.initialFormValues.nextPaymentDate
    );
    const lastPostType = useSelector((state) => state.thing.lastPostType);
    const lastPostStatus = useSelector((state) => state.thing.lastPostStatus);
    const lastPostData = useSelector((state) => state.thing.lastPostData);
    const newPaymentMethodKey = "NEW_PAYMENT_METHOD";
    const [scheduleDate, setScheduleDate] = useState(
        props.initialFormValues.nextPaymentDate
    );
    const [scheduleId, setScheduleId] = useState(
        props.initialFormValues.paymentReminderScheduleId
    );

    useEffect(() => {
        if (!currentAccount) {
            return; // Can't load lists until currentAccount is populated
        }

        dispatch(getPaymentThingsListDispatchable());

        if (lastPostStatus) {
            if (
                lastPostType === ThingPostTypes.NEW_PAYMENT_THING &&
                lastPostStatus === LastPostStatus.OK &&
                lastPostData
            ) {
                var newThingId = lastPostData.data.thingId;
                sleep(500).then(() => {
                    form.setFieldsValue({ paymentThingId: newThingId });
                    setFocus(paymentThingsSelect.current);
                    props.handleValuesChange();
                });
                dispatch(reloadThingListsDispatchable());
                dispatch(resetThingPost());
            }
        }

        // hacks to force select placeholder to reappear
        if (resetPaymentMethodSelectField === true) {
            form.setFieldsValue({ paymentThingId: undefined });
            setResetPaymentMethodSelectField(false);
        }
        if (resetPaymentFrequencySelectField === true) {
            form.setFieldsValue({ paymentFrequencyId: undefined });
            setResetPaymentFrequencySelectField(false);
        }
        if (resetReminderScheduleSelectField === true) {
            form.setFieldsValue({ paymentReminderScheduleId: undefined });
            setResetReminderScheduleSelectField(false);
        }
    }, [
        dispatch,
        form,
        lastPostData,
        lastPostStatus,
        lastPostType,
        props,
        resetPaymentFrequencySelectField,
        resetPaymentMethodSelectField,
        resetReminderScheduleSelectField,
        currentAccount,
    ]);

    const handleNewItemClick = () => {
        setResetPaymentMethodSelectField(true);
        setDrawerVisible(true);
    };

    const handleDrawerCloseSave = () => {
        setDrawerVisible(false);
    };

    const handleDrawerCloseCancel = () => {
        setDrawerVisible(false);
        setFocus(paymentThingsSelect.current);
    };

    const defaultPaymentReminderScheduleId = () => {
        let foundScheduleId = "";
        if (reminderSchedules) {
            for (let schedule of reminderSchedules) {
                if (schedule.name === "Starting 2 Weeks Before") {
                    foundScheduleId = schedule.reminderScheduleId;
                    break;
                }
            }
        }
        return foundScheduleId;
    };

    const updateScheduleInfo = () => {
        setScheduleDate(form.getFieldValue("nextPaymentDate"));
        setScheduleId(form.getFieldValue("paymentReminderScheduleId"));
    };

    const handleDateChange = (paymentDate) => {
        setHasPaymentDate(paymentDate !== null);
        if (
            paymentDate &&
            (form.getFieldValue("paymentReminderScheduleId") === null ||
                form.getFieldValue("paymentReminderScheduleId") === "")
        ) {
            form.setFieldsValue({
                paymentReminderScheduleId: defaultPaymentReminderScheduleId(),
            });
        }
        updateScheduleInfo();
    };

    const handlePaymentMethodSelect = (value) => {
        if (value === newPaymentMethodKey) {
            handleNewItemClick();
            return;
        }
        if (value === "") {
            setResetPaymentMethodSelectField(true);
            return;
        }
    };

    const handlePaymentFrequencySelect = (value) => {
        if (value === "") {
            setResetPaymentFrequencySelectField(true);
            return;
        }
    };

    const handleReminderScheduleSelect = (value) => {
        updateScheduleInfo();
        if (value === "") {
            setResetReminderScheduleSelectField(true);
            return;
        }
    };

    if (loading || !paymentThings || !frequencies || !currencies) {
        return <LoadingIndicator />;
    }

    return (
        <div className="tt-ted-panel-container">
            <Input.Group>
                <ThingInput
                    visible={true}
                    label="Payment Method"
                    tooltipkey="ThingInput_PaymentMethod"
                >
                    <Form.Item name="paymentThingId" hasFeedback>
                        <Select
                            name="paymentThingId"
                            ref={paymentThingsSelect}
                            showSearch
                            optionFilterProp="children"
                            placeholder="Select payment method (type to search)"
                            onSelect={handlePaymentMethodSelect}
                            autoComplete="off"
                        >
                            <Option key="" value=""></Option>
                            <Option
                                key={newPaymentMethodKey}
                                value={newPaymentMethodKey}
                            >
                                {"< Add a New Payment Method >"}
                            </Option>
                            {paymentThings.map((pt) =>
                                thing && pt.thingId === thing.thingId ? null : (
                                    <Option key={pt.thingId} value={pt.thingId}>
                                        {pt.thingType}: {pt.name}
                                    </Option>
                                )
                            )}
                        </Select>
                    </Form.Item>
                </ThingInput>
            </Input.Group>
            <ThingInput
                visible={true}
                label="Payment Frequency"
                tooltipkey="ThingInput_PaymentFrequency"
            >
                <Form.Item name="paymentFrequencyId" hasFeedback>
                    <Select
                        placeholder="Select a payment frequency"
                        onSelect={handlePaymentFrequencySelect}
                    >
                        <Option key="" value=""></Option>
                        {frequencies.map((f) => (
                            <Option key={f.frequencyId} value={f.frequencyId}>
                                {f.localizedName}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
            </ThingInput>
            <ThingInput
                visible={true}
                label="Pre-Authorized Automatic Payment"
                tooltipkey="ThingInput_PaymentPreAuth"
            >
                <Form.Item name="automaticPayment" valuePropName="checked">
                    <Switch checkedChildren={"Yes"} unCheckedChildren={"No"} />
                </Form.Item>
            </ThingInput>
            <Input.Group>
                <Row gutter={16}>
                    <Col span={4}>
                        <ThingInput
                            visible={true}
                            label="Currency"
                            tooltipkey="ThingInput_PaymentCurrency"
                        >
                            <Form.Item name="currencyCode">
                                <Select>
                                    {currencies.map((c) => (
                                        <Option
                                            key={c.currencyCode}
                                            value={c.currencyCode}
                                        >
                                            {c.currencySymbol}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </ThingInput>
                    </Col>
                    <Col span={7}>
                        <ThingInput
                            visible={true}
                            label="Payment Amount"
                            tooltipkey="ThingInput_PaymentAmount"
                        >
                            <Form.Item name="paymentAmount">
                                <InputNumber
                                    className="tt-ted-input-amount"
                                    max={99999.99}
                                    min={0}
                                    precision={2}
                                    formatter={(value) =>
                                        `${value}`.replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                        )
                                    }
                                    parser={(value) =>
                                        value.replace(/\$\s?|(,*)/g, "")
                                    }
                                    autoComplete="off"
                                />
                            </Form.Item>
                        </ThingInput>
                    </Col>
                    <Col>
                        <ThingInput
                            visible={true}
                            label="Approximate Amount"
                            tooltipkey="ThingInput_PaymentApproxAmount"
                        >
                            <Form.Item
                                name="approximateAmount"
                                valuePropName="checked"
                            >
                                <Switch
                                    checkedChildren={"Yes"}
                                    unCheckedChildren={"No"}
                                />
                            </Form.Item>
                        </ThingInput>
                    </Col>
                </Row>
            </Input.Group>
            <Input.Group>
                <Row gutter={16}>
                    <Col span={9}>
                        <ThingInput
                            visible={true}
                            label="Next Payment Date"
                            tooltipkey="ThingInput_PaymentDate"
                        >
                            <Form.Item name="nextPaymentDate">
                                <DatePicker
                                    disabledTime
                                    onChange={handleDateChange}
                                    format={dateFormatList(countryCode)}
                                />
                            </Form.Item>
                        </ThingInput>
                    </Col>
                    <Col>
                        {/* We are going to default autoRolloverPaymentDate=true if a payment frequency has been specified */}
                        <ThingInput
                            visible={false}
                            label="Auto-Rollover Next Payment Date"
                            tooltipkey="ThingInput_PaymentAutoRollover"
                        >
                            <Form.Item
                                name="autoRolloverPaymentDate"
                                valuePropName="checked"
                            >
                                <Switch
                                    checkedChildren={"Yes"}
                                    unCheckedChildren={"No"}
                                />
                            </Form.Item>
                        </ThingInput>
                    </Col>
                </Row>
            </Input.Group>
            <Input.Group style={{ display: hasPaymentDate ? "block" : "none" }}>
                <ThingInput
                    visible={true}
                    label="Reminder Schedule"
                    tooltipkey="ThingInput_PaymentReminder"
                >
                    <Form.Item name="paymentReminderScheduleId" hasFeedback>
                        <Select
                            name="paymentReminderScheduleId"
                            placeholder="Select a reminder schedule"
                            onSelect={handleReminderScheduleSelect}
                        >
                            <Option key="" value=""></Option>
                            {reminderSchedules.map((rs) => (
                                <Option
                                    key={rs.reminderScheduleId}
                                    value={rs.reminderScheduleId}
                                >
                                    {rs.localizedName}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </ThingInput>
            </Input.Group>
            <ThingReminderSchedule
                date={scheduleDate}
                reminderScheduleId={scheduleId}
            />

            <Drawer
                title="New Reminder Schedule"
                placement="right"
                width={Defaults.DRAWER_WIDTH}
                closable={false}
                onClose={handleDrawerCloseCancel}
                visible={drawerVisible}
            >
                <ThingNewPaymentMethod
                    onCloseCancel={handleDrawerCloseCancel}
                    onCloseSave={handleDrawerCloseSave}
                />
            </Drawer>
        </div>
    );
};

export default ThingPaymentDetailsContainer;
