export const createAsyncData = (name) => {
    const initialState = {
        isLoaded: false,
        isLoading: false,
        hasError: false,
        error: null,
        data: null,
    };

    const reducerActions = {
        SetResult: `${name}_SET_RESULT`,
        SetIsLoading: `${name}_SET_IS_LOADING`,
        ResetResult: `${name}_RESET`,
    };

    const reducer = (state = initialState, action) => {
        switch (action?.type) {
            case reducerActions.SetResult:
                return {
                    ...state,
                    isLoaded: true,
                    isLoading: false,
                    hasError:  action.data.error !== null && action.data.error !== undefined,
                    error: action.data.error,
                    hasData: action.data.data !== null && action.data.data !== undefined,
                    data: action.data.data,
                };

            case reducerActions.SetIsLoading:
                return {
                    ...state,
                    isLoading: action.data,
                };

            case reducerActions.ResetResult:
                return {
                    isLoaded: false,
                    isLoading: false,
                    hasError: false,
                    error: null,
                    hasData: false,
                    data: null,
                };

            default:
                return state;
        }
    };

    const actions = {
        setIsLoadingDispatchable: (isLoading) => {
            return { type: reducerActions.SetIsLoading, data: isLoading };
        },
        setResultDispatchable: (data, error) => {
            return { type: reducerActions.SetResult, data: { data, error } };
        },
        resetDataDispatchable: () => {
            return { type: reducerActions.ResetResult, data: null };
        },
        performLoad: (func, dispatch) => {
            try {
                dispatch(actions.setIsLoadingDispatchable(true));
                const { data, error } = func();
                dispatch(actions.setResultDispatchable(data, error));
            } finally {
                dispatch(actions.setIsLoadingDispatchable(false));
            }
        },
        performLoadAsync: async (funcAsync, dispatchAsync) => {
            try {
                await dispatchAsync(actions.setIsLoadingDispatchable(true));
                const { data, error } = await funcAsync();
                await dispatchAsync(actions.setResultDispatchable(data, error));
            } catch (ex) {
                await dispatchAsync(actions.setResultDispatchable(null, ex));
            }
        },
    };

    return {
        reducerActions: reducerActions,
        reducer: reducer,
        actions: actions,
    };
};
