import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { List } from "antd";
import classNames from "classnames";

import { loadActiveThing } from "../../redux/actions";
import ThingCard from "../../shared/ThingCard";
import { GroupByTypes } from "../../constants";

import ThingIsDirtyCheck from "../../shared/ThingIsDirtyCheck";
import ThingTitleInfo from "../../shared/ThingTitleInfo";

export const ThingsList = (props) => {
    const dispatch = useDispatch();

    const activeThingId = useSelector((state) => state.thing.activeThingId);
    const thingList = props.thingList;
    const groupByType = props.groupByType;
    const thingRefs =
        thingList &&
        thingList.reduce((tr, thing) => {
            tr[thing.thingId] = React.createRef();
            return tr;
        }, {});

    useEffect(() => {
        if (
            thingRefs &&
            activeThingId &&
            thingRefs[activeThingId] &&
            thingRefs[activeThingId].current
        ) {
            thingRefs[activeThingId].current.scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
        }
    }, [activeThingId, thingRefs]);

    const handleSetActiveThing = (thingId) => {
        dispatch(loadActiveThing(thingId));
    };

    const renderItem = (thing) => {
        let active = activeThingId === thing.thingId;
        return (
            <ThingIsDirtyCheck>
                <List.Item
                    key={thing.thingId}
                    className={classNames(
                        "tt-list-item",
                        "tt-list-item-grouped",
                        { "tt-list-item-active": active },
                        { "tt-hover": !active },
                    )}
                    onClick={() => handleSetActiveThing(thing.thingId)}
                >
                    <div
                        ref={thingRefs[thing.thingId]}
                        className="tt-list-item-scroll-container"
                    >
                        <ThingTitleInfo thing={thing} />
                    </div>
                </List.Item>
            </ThingIsDirtyCheck>
        );
    };

    const renderItemNoGroup = (thing) => {
        let active = activeThingId === thing.thingId;
        return (
            <ThingIsDirtyCheck>
                <List.Item
                    key={thing.thingId}
                    className={classNames("tt-list-item", {
                        "tt-list-item-active": active,
                        "tt-hover": !active,
                    })}
                    onClick={() => handleSetActiveThing(thing.thingId)}
                >
                    <div
                        ref={thingRefs[thing.thingId]}
                        className="tt-list-item-scroll-container"
                    >
                        <ThingCard thing={thing} />
                    </div>
                </List.Item>
            </ThingIsDirtyCheck>
        );
    };

    return (
        <List
            dataSource={thingList}
            renderItem={(thing) =>
                groupByType === GroupByTypes.THING_NAME
                    ? renderItemNoGroup(thing)
                    : renderItem(thing)
            }
        />
    );
};

export default ThingsList;
