import React, { useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tabs, Badge, Affix, Popover } from "antd";
import parse from "html-react-parser";

import {
    getInProgressThingsListDispatchable,
    getGroupByThingsListDispatchable,
    getRecentThingsListDispatchable,
    getRelatedThingsListDispatchable,
} from "../../redux/actions/thingListsActions";
import { setActiveThingTab } from "../../redux/actions";
import { ThingTabs, Defaults } from "../../constants";
import RecentThingsList from "../../shared/RecentThingsList";

import GroupByList from "./GroupByList";
import ThingGroupBy from "./ThingGroupBy";
import InProgressThingsList from "./InProgressThingsList";

export const ThingsLeftPanelContainer = () => {
    const { TabPane } = Tabs;
    const dispatch = useDispatch();
    const currentAccount = useSelector((state) => state.account.currentAccount);
    const activeTabId = useSelector((state) => state.selected.thingTabId);
    const groupByType = useSelector((state) => state.selected.groupByType);
    const searchText = useSelector((state) => state.selected.searchText);
    const groupByThingsList = useSelector(
        (state) => state.thinglists.groupByThings
    );
    const inProgressThingsList = useSelector(
        (state) => state.thinglists.inProgressThings
    );
    const groubByThingsCount = groupByThingsList ? groupByThingsList.count : 0;
    const inProgressThingsCount = inProgressThingsList
        ? inProgressThingsList.count
        : 0;

    useEffect(() => {
        if (!currentAccount) {
            return; // Can't load lists until currentAccount is populated
        }

        dispatch(getGroupByThingsListDispatchable(groupByType, searchText));
        dispatch(getInProgressThingsListDispatchable());
        dispatch(getRecentThingsListDispatchable());
        dispatch(getRelatedThingsListDispatchable());
    }, [dispatch, groupByType, searchText, currentAccount]);

    const handleSetActiveTab = (tabId) => {
        dispatch(setActiveThingTab(tabId));
    };

    const tabPopoverContent = (tabId) => {
        switch (tabId) {
            case ThingTabs.ALL_THINGS:
                return parse(
                    "This is the list of all your things. When you do a search this list will only show you the items that match the search. The list can be grouped in different ways using the <strong>Group By</strong> drop down list."
                );
            case ThingTabs.IN_PROGRESS_THINGS:
                return parse(
                    "This is the list of all your things that you have not marked as <strong>Reviewed and completed</strong>. The item with the earliest review date is shown at the top. When a thing reaches the <strong>Next Review Date</strong> the <strong>Reviewed and completed</strong> flag will be reset and the item will re-appear in this list for your review."
                );
            case ThingTabs.RECENT_THINGS:
                return "This is the list your most recently updated items. The most recent item is shown at the top.";
            default:
                return "";
        }
    };

    return (
        <Affix offsetTop={90}>
            <Tabs
                type="card"
                activeKey={activeTabId}
                defaultActiveKey={activeTabId}
                onChange={handleSetActiveTab}
            >
                <TabPane
                    tab={
                        <Fragment>
                            <Popover
                                trigger="hover"
                                placement="bottom"
                                title="Things"
                                content={tabPopoverContent(
                                    ThingTabs.ALL_THINGS
                                )}
                                mouseEnterDelay={
                                    Defaults.POPOVER_MOUSE_ENTER_DELAY_SLOW
                                }
                                mouseLeaveDelay={
                                    Defaults.POPOVER_MOUSE_LEAVE_DELAY
                                }
                            >
                                Things
                                <Badge
                                    count={groubByThingsCount}
                                    className="tt-tab-badge"
                                    style={{ backgroundColor: "#28a745" }}
                                    overflowCount={999}
                                />
                            </Popover>
                        </Fragment>
                    }
                    key={ThingTabs.ALL_THINGS}
                >
                    <div>
                        <div className="tt-search-options-container">
                            <ThingGroupBy />
                        </div>
                        <div className="tt-tab-list-container">
                            <GroupByList />
                        </div>
                    </div>
                </TabPane>
                <TabPane
                    tab={
                        <Fragment>
                            <Popover
                                trigger="hover"
                                placement="bottom"
                                title="In Progress Things"
                                content={tabPopoverContent(
                                    ThingTabs.IN_PROGRESS_THINGS
                                )}
                                mouseEnterDelay={
                                    Defaults.POPOVER_MOUSE_ENTER_DELAY_SLOW
                                }
                                mouseLeaveDelay={
                                    Defaults.POPOVER_MOUSE_LEAVE_DELAY
                                }
                            >
                                In Progress
                                <Badge
                                    count={inProgressThingsCount}
                                    className="tt-tab-badge"
                                    overflowCount={999}
                                />
                            </Popover>
                        </Fragment>
                    }
                    key={ThingTabs.IN_PROGRESS_THINGS}
                >
                    <div className="tt-tab-list-container">
                        <InProgressThingsList />
                    </div>
                </TabPane>
                <TabPane
                    tab={
                        <Fragment>
                            <Popover
                                trigger="hover"
                                placement="bottom"
                                title="Recent Things"
                                content={tabPopoverContent(
                                    ThingTabs.RECENT_THINGS
                                )}
                                mouseEnterDelay={
                                    Defaults.POPOVER_MOUSE_ENTER_DELAY_SLOW
                                }
                                mouseLeaveDelay={
                                    Defaults.POPOVER_MOUSE_LEAVE_DELAY
                                }
                            >
                                Recent
                            </Popover>
                        </Fragment>
                    }
                    key={ThingTabs.RECENT_THINGS}
                >
                    <div className="tt-tab-list-container">
                        <RecentThingsList
                            redirectOnClick={false}
                            maxItems={15}
                        />
                    </div>
                </TabPane>
            </Tabs>
        </Affix>
    );
};

export default ThingsLeftPanelContainer;
