import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { List } from "antd";

import { clearSearchAndRedirectToThing } from "../../redux/actions";
import { getOverdueThingsListDispatchable } from "../../redux/actions/thingListsActions";

import { MessageError } from "../../shared/MessageError";
import ThingReminderCard from "../../shared/ThingReminderCard";
import { ThingTabs } from "../../constants";
import TitleBar from "../../shared/TitleBar";
import LoadingIndicator from "../../shared/LoadingIndicator";

export const OverdueThingsCard = () => {
    const dispatch = useDispatch();
    const thingList = useSelector((state) => state.thinglists.overdueThings);
    const thingsCount = useSelector((state) =>
        state.thinglists.overdueThings
            ? state.thinglists.overdueThings.count
            : 0
    );
    const loading = useSelector(
        (state) => state.thinglists.overdueThingsLoading
    );
    const error = useSelector((state) => state.thinglists.overdueThingsError);

    useEffect(() => {
        dispatch(getOverdueThingsListDispatchable());
    }, [dispatch]);

    if (error) {
        return <MessageError error={error} />;
    }
    if (loading) {
        return <LoadingIndicator />;
    }
    if (thingsCount === 0) {
        return null;
    }
    return (
        <div>
            <TitleBar tooltipkey="Header_OverdueThings" color="#dc3545" />
            <div className="tt-container">
                <List
                    dataSource={thingList.listData}
                    renderItem={(thing) => (
                        <List.Item
                            key={thing.thingId + "-" + thing.type}
                            onClick={() =>
                                dispatch(
                                    clearSearchAndRedirectToThing(
                                        thing.thingId,
                                        ThingTabs.ALL_THINGS
                                    )
                                )
                            }
                        >
                            <ThingReminderCard thing={thing} />
                        </List.Item>
                    )}
                />
            </div>
        </div>
    );
};

export default OverdueThingsCard;
