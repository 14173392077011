import { push } from "connected-react-router";
//import debounce from 'lodash.debounce';

import {
    ActionTypes,
    ThingPostTypes,
    GroupByTypes,
    ThingTabs,
    Routes,
    APIEndpoints,
} from "../constants";

import store from "./store";
import { showNotificationInfo, thingRoute } from "../functions";

import { ajaxGetAsync, ajaxPostAsync } from "./actions/ajaxActions";

import {
    getMyAccountsDispatchable,
    setCurrentAccountDispatchable,
} from "./actions/accountActions";
import { getThing } from "./actions/thingActions";
import {
    getGroupByThingsListDispatchable,
    setReloadForGroupByThingsList,
} from "./actions/thingListsActions";

export function setNewThing() {
    return {
        type: ActionTypes.NEW_THING,
    };
}

export function setActiveThingTab(thingTabId) {
    return {
        type: ActionTypes.CHANGE_THING_TAB,
        data: { thingTabId: thingTabId },
    };
}

export function setActiveGroupByType(groupByType) {
    return {
        type: ActionTypes.CHANGE_GROUP_BY_TYPE,
        data: { groupByType: groupByType },
    };
}

export function setSearchText(searchText) {
    return {
        type: ActionTypes.CHANGE_SEARCH_TEXT,
        data: { searchText: searchText },
    };
}

export function clearSearchAndRedirectToThing(thingId, thingTabId) {
    return (dispatch) => {
        dispatch(clearSearchResults());
        dispatch(redirectToThing(thingId, thingTabId));
    };
}

export function redirectToThing(thingId, thingTabId) {
    return (dispatch) => {
        dispatch(setActiveThingTab(thingTabId));
        if (thingId) {
            dispatch(loadActiveThing(thingId));
        } else {
            dispatch(unloadActiveThing());
            dispatch(push(Routes.THINGS));
        }
    };
}

export function clearSearchResults() {
    return (dispatch) => {
        dispatch(setSearchText(""));
        dispatch(setActiveGroupByType(GroupByTypes.THING_NAME));
        dispatch(setReloadForGroupByThingsList());
        dispatch(getGroupByThingsListDispatchable(GroupByTypes.THING_NAME, ""));
    };
}

export function getSearchResults(searchText) {
    return (dispatch) => {
        dispatch(push(Routes.THINGS));
        dispatch(unloadActiveThing());
        dispatch(setActiveGroupByType(GroupByTypes.SEARCH_AREA));
        dispatch(setActiveThingTab(ThingTabs.ALL_THINGS));
        dispatch(setReloadForGroupByThingsList());
        dispatch(
            getGroupByThingsListDispatchable(
                GroupByTypes.SEARCH_AREA,
                searchText
            )
        );
    };
}

export function loadActiveThing(thingId) {
    let state = store.getState();
    if (!state.thing.isThingLoading) {
        return async (dispatchAsync) => {
            await dispatchAsync(push(thingRoute(thingId)));
            await dispatchAsync(getThing(thingId));

            // Perform a check to align the current account with the thing
            let state = JSON.parse(JSON.stringify(store.getState()));
            if (!state.thing.thingError && state.thing.activeThing) {
                let currentAccountId = state.account.currentAccount?.accountId;
                let thingAccountId = state.thing.activeThing.accountId;
                if (thingAccountId !== currentAccountId) {
                    // Need to switch accounts
                    console.log(
                        "Thing AccountID does not match current selected account. Auto-swapping accounts."
                    );
                    let thingAccount = state.account.myAccounts.data.accounts.find(
                        (a) => a.accountId === thingAccountId
                    );
                    if (!!thingAccount) {
                        await dispatchAsync(
                            setCurrentAccountDispatchable(thingAccount)
                        );

                        // Only show a notification if another account was selected
                        if (!!currentAccountId) {
                            showNotificationInfo(
                                "NK_AccountSwap",
                                <span>
                                    Switched to managing{" "}
                                    <b>
                                        {thingAccount.primaryOwnerFullName}'s
                                        Account
                                    </b>
                                    .
                                </span>,
                                null
                            );
                        }
                    }
                }
            }
        };
    }
    return {
        type: ActionTypes.GET_THING_IGNORE,
    };
}

export function unloadActiveThing() {
    return {
        type: ActionTypes.UNLOAD_THING,
    };
}

export function setReloadForAccount() {
    return {
        type: ActionTypes.GET_ACCOUNT_LOAD,
    };
}

export function setReloadForAccountLists() {
    return {
        type: ActionTypes.GET_ACCOUNT_LISTS_LOAD,
    };
}

export function getInitialData() {
    return async (dispatchAsync) => {
        // Get a list of my accounts
        await dispatchAsync(getMyAccountsDispatchable());

        // Check we correctly got the accounts list
        let state = store.getState();
        if (
            !state.account.myAccounts.isLoaded ||
            state.account.myAccounts.hasError
        ) {
            // Not loaded. Something went wrong?
            console.log(
                "Failed to get my accounts list",
                state.account.myAccounts.error
            );
            // TODO: Potentially should do additional error handling here?
            return;
        }

        // If the user has no accounts, then they should setup a new one. Redirect to the NewAccount page
        // If the user only has one account we can auto-select that one and load the account data
        // Else the user has multiple accounts, so the following can be attempted at the current page component's discretino:
        //     - Attempt to deduce the desired account, using the URL
        //     - Attempt to select the account the user last selected (using cookies)
        //     - Redirect to the account select page and let the user decide
        let accounts = state.account.myAccounts.data.accounts;

        // User has only 1 account
        if (accounts.length === 1) {
            let selectedAccount = accounts[0];
            console.log("User only has one account, so auto-selecting it.");

            // Set the current account
            await dispatchAsync(setCurrentAccountDispatchable(selectedAccount));
            return;
        }
    };
}

export function getDashboardMessage() {
    let state = store.getState();
    if (
        state.account.dashboardMessageLoad &&
        !state.account.dashboardMessageLoading
    ) {
        return async (dispatchAsync) => {
            await ajaxGetAsync(
                APIEndpoints.Account + "DashboardMessage",
                ActionTypes.GET_DASHBOARD_MESSAGE,
                ActionTypes.GET_DASHBOARD_MESSAGE_OK,
                ActionTypes.GET_DASHBOARD_MESSAGE_FAIL,
                dispatchAsync
            );
        };
    }
    return {
        type: ActionTypes.GET_DASHBOARD_MESSAGE_IGNORE,
    };
}

export function getDashboardTip() {
    let state = store.getState();
    if (state.account.dashboardTipLoad && !state.account.dashboardTipLoading) {
        return async (dispatchAsync) => {
            await ajaxGetAsync(
                APIEndpoints.Account + "DashboardTip",
                ActionTypes.GET_DASHBOARD_TIP,
                ActionTypes.GET_DASHBOARD_TIP_OK,
                ActionTypes.GET_DASHBOARD_TIP_FAIL,
                dispatchAsync
            );
        };
    }
    return {
        type: ActionTypes.GET_DASHBOARD_TIP_IGNORE,
    };
}

export function getThingsMessage() {
    let state = store.getState();
    if (
        state.account.thingsMessageLoad &&
        !state.account.thingsMessageLoading
    ) {
        return async (dispatchAsync) => {
            await ajaxGetAsync(
                APIEndpoints.Account + "ThingsMessage",
                ActionTypes.GET_THINGS_MESSAGE,
                ActionTypes.GET_THINGS_MESSAGE_OK,
                ActionTypes.GET_THINGS_MESSAGE_FAIL,
                dispatchAsync
            );
        };
    }
    return {
        type: ActionTypes.GET_THINGS_MESSAGE_IGNORE,
    };
}

function postThingTypeCreate(postData) {
    return async (dispatchAsync) => {
        await ajaxPostAsync(
            APIEndpoints.Account + "CreateThingType",
            ActionTypes.POST_THING,
            ActionTypes.POST_THING_OK,
            ActionTypes.POST_THING_FAIL,
            dispatchAsync,
            postData
        );
    };
}

function postDispositionActionCreate(postData) {
    return async (dispatchAsync) => {
        await ajaxPostAsync(
            APIEndpoints.Account + "CreateDispositionAction",
            ActionTypes.POST_THING,
            ActionTypes.POST_THING_OK,
            ActionTypes.POST_THING_FAIL,
            dispatchAsync,
            postData
        );
    };
}

function postThingQuickCreate(postData) {
    return async (dispatchAsync) => {
        await ajaxPostAsync(
            APIEndpoints.Thing + "QuickCreate",
            ActionTypes.POST_THING,
            ActionTypes.POST_THING_OK,
            ActionTypes.POST_THING_FAIL,
            dispatchAsync,
            postData
        );
    };
}

function postUpdatePerson(postData) {
    return async (dispatchAsync) => {
        await ajaxPostAsync(
            APIEndpoints.Account + "UpdatePerson",
            ActionTypes.POST_THING,
            ActionTypes.POST_THING_OK,
            ActionTypes.POST_THING_FAIL,
            dispatchAsync,
            postData
        );
    };
}

export function createRelatedThing(postData) {
    return (dispatch) => {
        dispatch(setPostType(ThingPostTypes.NEW_RELATED_THING));
        dispatch(postThingQuickCreate(postData));
    };
}

export function createPaymentThing(postData) {
    return (dispatch) => {
        dispatch(setPostType(ThingPostTypes.NEW_PAYMENT_THING));
        dispatch(postThingQuickCreate(postData));
    };
}

export function createPerson(postData) {
    return (dispatch) => {
        dispatch(setPostType(ThingPostTypes.NEW_PERSON));
        dispatch(postUpdatePerson(postData));
    };
}

export function createThingType(postData) {
    return (dispatch) => {
        dispatch(setPostType(ThingPostTypes.NEW_THING_TYPE));
        dispatch(postThingTypeCreate(postData));
    };
}

export function createDispositionAction(postData) {
    return (dispatch) => {
        dispatch(setPostType(ThingPostTypes.NEW_DISPOSITION_ACTION));
        dispatch(postDispositionActionCreate(postData));
    };
}

export function resetThingPost() {
    return {
        type: ActionTypes.POST_THING_RESET,
    };
}

export function setPostType(type) {
    return {
        type: ActionTypes.POST_THING_TYPE,
        data: type,
    };
}

export function updateRelatedPeople(list) {
    return {
        type: ActionTypes.UPDATE_RELATED_PEOPLE,
        data: list,
    };
}

export function updateRelatedThings(list) {
    return {
        type: ActionTypes.UPDATE_RELATED_THINGS,
        data: list,
    };
}

export function updateRelatedLinks(list) {
    return {
        type: ActionTypes.UPDATE_RELATED_LINKS,
        data: list,
    };
}

export function updateThingPeopleList(primaryOwner) {
    return {
        type: ActionTypes.UPDATE_NEW_THING_PEOPLE,
        data: primaryOwner,
    };
}
