import React, { Fragment } from "react";
import { push } from "connected-react-router";

import { Spin, Alert } from "antd";

import { authService, AuthResults } from "./AuthenticationService";
import { setAuthMessage } from "../redux/actions/authActions";
import LoginContainer from "../routes/login";
import { BaseURL, Routes } from "../constants";

export const QueryParameterNames = {
    ReturnUrl: "returnUrl",
    Message: "message",
    State: "state",
};

const isLocalUrl = (url) => {
    return url.startsWith(`${window.location.origin}/`) || url.startsWith("/");
};

const getAuthReturnUrl = () => {
    let returnUrl = null;

    // Try to get a query string parameter for the return Url
    const params = new URLSearchParams(window.location.search);
    const fromQuery = params.get(QueryParameterNames.ReturnUrl);
    if (fromQuery) {
        returnUrl = fromQuery;
    }

    if (returnUrl) {
        if (!isLocalUrl(returnUrl)) {
            // This is an extra check to prevent open redirects.
            throw new Error(
                `Invalid return url. The return url needs to have the same origin as the current page. [${returnUrl}]`
            );
        }
        return returnUrl;
    }

    // Fallback to the origin
    return `${window.location.origin}${BaseURL}`;
};

const handleAuthResult = async (dispatch, authResultPromise) => {
    const authResult = await authResultPromise;
    switch (authResult.result) {
        case AuthResults.Success:
            // Our auth action succeeded. We should proceed by redirecting to the redirect url
            const redirectUrl = authResult.data;
            // Perform a replace so the user doesn't get authentication Urls in their history
            window.location.replace(redirectUrl);
            break;

        case AuthResults.Redirect:
            // No need to do anything. This result indicates a redirect has occured
            break;

        case AuthResults.Fail:
            // Something went wrong, so display an error notification
            const error = authResult.data;
            console.error("Performing Auth Action failed!", error);
            dispatch(setAuthMessage(true, error));
            // TODO: Consider redirecting to Login Failed page instead
            dispatch(push(Routes.LOGIN));
            break;

        default:
            throw new Error(`Unhandled AuthResult: [${authResult}]`);
    }
};

/** Component to render routes needed for authentication. */
const AuthRoutes = {
    renderLoginPage: () => {
        const returnUrl = getAuthReturnUrl();
        return <LoginContainer returnUrl={returnUrl} />;
    },

    renderLoginCallback: (dispatch) => {
        const returnUrl = getAuthReturnUrl();
        let result;
        const params = new URLSearchParams(window.location.search);
        if (params.get(QueryParameterNames.State)) {
            // We have state so this is a callback from an external provider
            result = authService.performLoginCallbackAsync(returnUrl);
        } else {
            // This is a silent login attempt
            result = authService.performSilentLoginAsync(returnUrl);
        }
        handleAuthResult(dispatch, result);

        return <Spin />;
    },

    renderLogout: (dispatch) => {
        const returnUrl = getAuthReturnUrl();
        handleAuthResult(dispatch, authService.performLogoutAsync(returnUrl));
        return <Spin />;
    },

    renderLogoutCallback: (dispatch) => {
        const returnUrl = getAuthReturnUrl();
        handleAuthResult(
            dispatch,
            authService.performLogoutCallbackAsync(returnUrl)
        );
        return <Spin />;
    },

    renderLoginFailedRoute: () => {
        const params = new URLSearchParams(window.location.search);
        const errorMessage = params.get(QueryParameterNames.Message);
        const returnUrl = getAuthReturnUrl();

        return (
            <Fragment>
                <Alert
                    type="error"
                    message={
                        <Fragment>
                            <div>
                                Login failed to complete. Please try again.
                            </div>
                            <div>{errorMessage}</div>
                        </Fragment>
                    }
                />
                <div className="tt-spacer" />
                <LoginContainer returnUrl={returnUrl} />
            </Fragment>
        );
    },
};

export default AuthRoutes;
