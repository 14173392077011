import React, { Fragment } from "react";
import NavigationBar from "../NavigationBar";

export const DefaultLayout = (props) => {
    return (
        <Fragment>
            <header>
                <NavigationBar showAccountLinks={true} />
            </header>
            <div className="container">{props.children}</div>
        </Fragment>
    );
};

export default DefaultLayout;
