import React, { Fragment, useCallback, useEffect, useState } from "react";

import { Spin, Col, Card, Alert, Space } from "antd";

import {
    getCheckinCodeStatusAsync,
} from "../../functions";
import {
    Application,
    CheckinCodeStatuses,
} from "../../constants";

export const CheckinContianer = (props) => {
    const checkinCodeFromURL = props?.match?.params?.checkinCode; // Get the checkinCode from the URL

    const [isProcessingCheckin, setIsProcessingCheckin] = useState(false);
    const [checkinData, setCheckinData] = useState(null);

    const checkSignupCode = useCallback(
        async (code) => {
            try {
                setIsProcessingCheckin(true);
                let checkinData = await getCheckinCodeStatusAsync(code);
                console.log("Checkin Status:", checkinData);
                setCheckinData(checkinData);
            } catch (ex) {
                console.error(ex);
            } finally {
                setIsProcessingCheckin(false);
            }
        },
        [setIsProcessingCheckin]
    );

    // Effect to get checkin status when code is provided through the URL
    useEffect(() => {
        if (checkinData == null && checkinCodeFromURL != null) {
            checkSignupCode(checkinCodeFromURL);
        }
    }, [checkinCodeFromURL, checkSignupCode, checkinData]);

    const renderCheckinStatus = (checkinData) => {
        const statusString = checkinData?.statusString;
        //console.log("statusString", statusString)

        if (statusString && statusString === CheckinCodeStatuses.ValidAndCheckedIn) {
            return (
                <Alert
                    type="success"
                    message={`Thank you for checking in to your ${Application.Name} account`}
                />
            );
        }

        if (statusString && statusString === CheckinCodeStatuses.ValidAndUsed) {
            return (
                <Alert
                    type="info"
                    message={`You have already checked in to your ${Application.Name} account with this link. The link can only be used once. If you have received a more recent link please try using that link instead.`}
                />
            );
        }
        
        if (statusString && statusString === CheckinCodeStatuses.Expired) {
            return (
                <Alert
                    type="info"
                    message={`The check in link has expired. If you have received a more recent link please try using that link instead.`}
                />
            );
        }

        // statusString is null or Invalid
        return (
            <Alert
                type="error"
                message={`The link used to check in is not valid`}
            />
        );
    };

    const renderInLayout = (content) => {
        return (
            <Fragment>
                <Col offset={6} span={12}>
                    <Card title="Account Check In">{content}</Card>
                </Col>
            </Fragment>
        );
    };

    // Wait until done processing the checkin code
    if (isProcessingCheckin) {
        return renderInLayout(<Spin />);
    }

    // Render messages
    return renderInLayout(
        <Space direction="vertical" style={{ width: "100%" }}>
            {renderCheckinStatus(checkinData)}
        </Space>
    );
};

export default CheckinContianer;
