import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingIndicator from "../../shared/LoadingIndicator";
import { Row, Col, Drawer, Popconfirm, Button, Tag, Popover } from "antd";
import {
    PersonTypeIds,
    Defaults,
    PersonStatuses,
    APIEndpoints,
    Routes,
} from "../../constants";
import {
    getTooltipByKey,
    personTypeDescription,
    showNotificationError,
    showNotificationSuccess,
} from "../../functions";
import PersonEditor from "./PersonEditor";
import classNames from "classnames";
import {
    DeleteFilled,
    EditFilled,
    DeleteOutlined,
    EditOutlined,
} from "@ant-design/icons";

import { setSelectedPerson } from "../../redux/actions/personActions";
import Header from "../../shared/Header";
import TruncateWithTooltip from "../../shared/TruncateWithTooltip";
import { ajaxGetAsync2, ajaxPostAsync2 } from "../../redux/actions/ajaxActions";
import {
    removePersonFromList,
    setCurrentAccountDispatchable,
    setPeopleList,
} from "../../redux/actions/accountActions";

import wrapUserAccountPage from "../../shared/UserAccountPage";
import { push } from "connected-react-router";
import store from "../../redux/store";

const getPersonDeleteInfo = (person, userAccountTypeId, tooltips) => {
    const createDeleteInfo = (
        isDeleteable,
        tooltipTitle,
        tooltipContent,
        confirmContent
    ) => {
        return {
            isDeleteable: isDeleteable,
            tooltipTitle: tooltipTitle,
            tooltipContent: tooltipContent,
            confirmContent: confirmContent,
        };
    };

    let tooltip;
    switch (person.deleteStatus) {
        // Deleteable
        case "D":
            tooltip = getTooltipByKey("PersonDelete_CanDelete", tooltips);
            return createDeleteInfo(true, tooltip.title, tooltip.content);

        // Related to a thing
        case "R":
            tooltip = getTooltipByKey(
                "PersonDelete_CannotDeleteRelated",
                tooltips
            );
            return createDeleteInfo(false, tooltip.title, tooltip.content);

        // Verified as part of the account
        case "V":
            // Only primary owners can delete this person
            if (userAccountTypeId === PersonTypeIds.PRIMARY_OWNER) {
                tooltip = getTooltipByKey(
                    "PersonDelete_CanDeleteVerified",
                    tooltips
                );
                return createDeleteInfo(
                    true,
                    tooltip.title,
                    tooltip.content,
                    tooltip.content
                );
            }

            tooltip = getTooltipByKey(
                "PersonDelete_CannotDeleteVerified",
                tooltips
            );
            return createDeleteInfo(false, tooltip.title, tooltip.content);

        // Unknown?
        default:
            tooltip = getTooltipByKey("PersonDelete_CannotDelete", tooltips);
            return createDeleteInfo(false, tooltip.title, tooltip.content);
    }
};

export const PeopleContainer = wrapUserAccountPage(
    {
        redirectToNewAccountIfNoUserAccounts: true,
        attemptCookieAccountDeductionIfNoSelectedAccount: true,
        redirectToAccountSelectIfNoSelectedAccount: false,
    },
    (props) => {
        const inviteId = props?.match?.params?.inviteId; // Get the inviteId from the URL

        const dispatch = useDispatch();
        const notificationKey = "NK_PersonContainer";
        const tooltips = useSelector((state) => state.account.tooltipsList);

        const userPersonId = useSelector(
            (state) => state.account.currentAccount?.myPersonId
        );
        const userTypeId = useSelector(
            (state) => state.account.currentAccount?.userRoleInAccount
        );
        const isPrimaryOwner = userTypeId === PersonTypeIds.PRIMARY_OWNER;

        const myAccounts = useSelector((state) => state.account.myAccounts);
        const currentAccount = useSelector(
            (state) => state.account.currentAccount
        );
        const [hasFetchedPeople, setHasFetchPeople] = useState(false);
        const [isFetchingPeople, setIsFetchingPeople] = useState(false);
        const peopleList = useSelector((state) => state.account.peopleList);

        const [isPersonEditorVisible, setIsPersonEditorVisible] = useState(
            false
        );

        // Effect: Update the people list the first time the component is loaded
        useEffect(() => {
            if (!myAccounts.hasData) {
                return; // Can't do anything until we have a list of accounts
            }

            if (!currentAccount && !inviteId) {
                // Can't fetch people yet as there is no selected account
                // Redirect to account select
                let redirectUrl =
                    Routes.ACCOUNT_SELECT +
                    "?returnUrl=" +
                    encodeURIComponent(window.location.pathname);
                dispatch(push(redirectUrl));
                return;
            }

            if (hasFetchedPeople) {
                return;
            }

            const fetchPeople = async () => {
                try {
                    setIsFetchingPeople(true);
                    let apiUrl = APIEndpoints.Account + "People";
                    if (!!inviteId) {
                        apiUrl += `/${inviteId}`;
                    }

                    let response = await ajaxGetAsync2(apiUrl);
                    dispatch(setPeopleList(response.data.people));

                    // Additionally check if the current account doesn't match the returned one
                    if (response.data.accountId !== currentAccount?.accountId) {
                        let state = store.getState();
                        let accountFromPeopleList = state.account.myAccounts.data.accounts.find(
                            (a) => a.accountId === response.data.accountId
                        );
                        console.log(
                            "Invitation AccountID does not match current selected account. Auto-swapping accounts."
                        );
                        dispatch(
                            setCurrentAccountDispatchable(accountFromPeopleList)
                        );
                    }
                } catch (ex) {
                    console.error("Error fetching people:", ex);
                    showNotificationError(
                        notificationKey,
                        "Failed to get people!",
                        null
                    );
                } finally {
                    setIsFetchingPeople(false);
                }
            };

            fetchPeople();
            setHasFetchPeople(true);
        }, [dispatch, hasFetchedPeople, myAccounts, currentAccount, inviteId]);

        const handleOpenNewPersonEditor = () => {
            dispatch(setSelectedPerson(null));
            setIsPersonEditorVisible(true);
        };

        const handleEditPersonClick = (person) => {
            dispatch(setSelectedPerson(person));
            setIsPersonEditorVisible(true);
        };

        const handlePersonEditorDrawerClose = () => {
            setIsPersonEditorVisible(false);
            dispatch(setSelectedPerson(null));
        };

        const handleDeleteClick = async (person) => {
            if (person?.personId == null) return; // Person or personId was invalid. Do nothing

            try {
                await ajaxPostAsync2(APIEndpoints.Account + `DeletePerson`, {
                    personId: person.personId,
                });
                //let response = await ajaxPostAsync2(APIEndpoints.Account + `DeletePerson`, { personId: person.personId });
                //console.log(response)
                dispatch(removePersonFromList(person));
                showNotificationSuccess(
                    notificationKey,
                    "Successfully deleted person",
                    null
                );
            } catch (ex) {
                console.log(ex);
                showNotificationError(
                    notificationKey,
                    "Failed to delete person!",
                    null
                );
            }
        };

        const renderType = (personTypeId) => {
            var typeDescription = personTypeDescription(personTypeId);
            return <span>{typeDescription}</span>;
        };

        const renderStatusID = (person, statusID) => {
            switch (statusID) {
                case PersonStatuses.INVITED:
                    return <Tag className="tt-yellow-pill">Invited</Tag>;

                case PersonStatuses.PENDING:
                    return <Tag className="tt-red-pill">Verify</Tag>;

                case PersonStatuses.VERIFIED:
                    return <Tag className="tt-green-pill">Verified</Tag>;

                default:
                    return "-";
            }
        };

        const renderPersonDeleteButton = (person, deleteInfo) => {
            if (!deleteInfo.isDeleteable) {
                return (
                    <Popover
                        trigger="hover"
                        placement="right"
                        content={deleteInfo.tooltipContent}
                        title={deleteInfo.tooltipTitle}
                        mouseEnterDelay={
                            Defaults.POPOVER_MOUSE_ENTER_DELAY_FAST
                        }
                        mouseLeaveDelay={Defaults.POPOVER_MOUSE_LEAVE_DELAY}
                    >
                        <DeleteOutlined />
                    </Popover>
                );
            }

            return (
                <Popover
                    trigger="hover"
                    placement="right"
                    content={deleteInfo.tooltipContent}
                    title={deleteInfo.tooltipTitle}
                    mouseEnterDelay={Defaults.POPOVER_MOUSE_ENTER_DELAY_FAST}
                    mouseLeaveDelay={Defaults.POPOVER_MOUSE_LEAVE_DELAY}
                >
                    <Popconfirm
                        title={
                            "Are you sure you want to delete this person?" +
                            (deleteInfo.confirmContent != null
                                ? " " + deleteInfo.confirmContent
                                : "")
                        }
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => handleDeleteClick(person)}
                    >
                        <DeleteFilled />
                    </Popconfirm>
                </Popover>
            );
        };

        const renderPersonEditButton = (person, isEditable) => {
            if (!isEditable) {
                let tooltip = getTooltipByKey(
                    "PersonEdit_SecondaryOwner",
                    tooltips
                );
                return (
                    <Popover
                        trigger="hover"
                        placement="right"
                        content={tooltip.content}
                        title={tooltip.title}
                        mouseEnterDelay={
                            Defaults.POPOVER_MOUSE_ENTER_DELAY_FAST
                        }
                        mouseLeaveDelay={Defaults.POPOVER_MOUSE_LEAVE_DELAY}
                    >
                        <EditOutlined />
                    </Popover>
                );
            }

            let tooltip = getTooltipByKey("PersonEdit_CanEdit", tooltips);
            return (
                <Popover
                    trigger="hover"
                    placement="right"
                    content={tooltip.content}
                    title={tooltip.title}
                    mouseEnterDelay={Defaults.POPOVER_MOUSE_ENTER_DELAY_FAST}
                    mouseLeaveDelay={Defaults.POPOVER_MOUSE_LEAVE_DELAY}
                >
                    <EditFilled onClick={() => handleEditPersonClick(person)} />
                </Popover>
            );
        };

        const renderPerson = (person, rownum) => {
            let isSelf = person.personId === userPersonId;
            let deleteInfo = getPersonDeleteInfo(person, userTypeId, tooltips);

            let hideDelete = false;
            if (
                person.accountPersonTypeId === PersonTypeIds.PRIMARY_OWNER || // Can't delete PO
                person.personStatusId === PersonStatuses.PENDING || // Can't delete Pending person
                isSelf || // Can't delete self
                (person.accountPersonTypeId !== PersonTypeIds.PERSON &&
                    !isPrimaryOwner) // SOs can only delete Persons
            ) {
                hideDelete = true;
            }

            let isEditable =
                person.accountPersonTypeId === PersonTypeIds.PERSON ||
                isPrimaryOwner;
            let hideEdit =
                person.accountPersonTypeId === PersonTypeIds.PRIMARY_OWNER;

            return (
                <Row
                    key={person.personId}
                    className={classNames(
                        "tt-people-row",
                        {
                            "tt-people-row-po": isSelf,
                        },
                        { "tt-people-row-even": rownum % 2 === 0 }
                    )}
                >
                    <Col span={6}>
                        <TruncateWithTooltip>
                            {person.fullName}
                        </TruncateWithTooltip>
                    </Col>
                    <Col span={6}>
                        <TruncateWithTooltip>
                            {person.email}
                        </TruncateWithTooltip>
                    </Col>
                    <Col span={4}>{renderType(person.accountPersonTypeId)}</Col>
                    <Col span={4}>
                        {renderStatusID(person, person.personStatusId)}
                    </Col>
                    <Col span={1}>
                        {!hideEdit &&
                            renderPersonEditButton(person, isEditable)}
                    </Col>
                    <Col span={1}>
                        {!hideDelete &&
                            renderPersonDeleteButton(person, deleteInfo)}
                    </Col>
                </Row>
            );
        };

        // Return a loading indicator if the people list is being fetched
        if (!peopleList || isFetchingPeople || !tooltips) {
            return <LoadingIndicator />;
        }

        var count = 1;
        return (
            <div>
                <Header title="People &amp; Inheritance" />

                <Button onClick={handleOpenNewPersonEditor} type="primary">
                    New Person
                </Button>

                <Row className="tt-people-row-header">
                    <Col span={6}>NAME</Col>
                    <Col span={6}>EMAIL ADDRESS</Col>
                    <Col span={4}>TYPE</Col>
                    <Col span={4}>STATUS</Col>
                    <Col span={2}>ACTIONS</Col>
                </Row>
                {peopleList.map((p) => renderPerson(p, count++))}

                <Drawer
                    title="Person Editor"
                    placement="right"
                    width={Defaults.DRAWER_WIDTH}
                    closable={false}
                    onClose={handlePersonEditorDrawerClose}
                    visible={isPersonEditorVisible}
                >
                    <PersonEditor onClose={handlePersonEditorDrawerClose} />
                </Drawer>
            </div>
        );
    }
);

export default PeopleContainer;
