import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import parse from "html-react-parser";
import { Card } from "antd";

import { getDashboardTip } from "../../redux/actions";

import { MessageError } from "../../shared/MessageError";
import LoadingIndicator from "../../shared/LoadingIndicator";

export const DashboardTip = () => {
    const dispatch = useDispatch();
    const message = useSelector((state) => state.account.dashboardTip);
    const loading = useSelector((state) => state.account.dashboardTipLoading);
    const error = useSelector((state) => state.account.dashboardTipError);

    useEffect(() => {
        if (!message) {
            dispatch(getDashboardTip());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // temporarily disable until ready
    var disabled = true;
    if (disabled) {
        return null;
    }
    if (error) {
        return <MessageError error={error} />;
    }
    if (loading) {
        return <LoadingIndicator />;
    }
    if (!message || message.title === null) {
        return null;
    }
    return (
        <Card title={message.title} className="tt-message-tip">
            {parse(message.body)}
        </Card>
    );
};

export default DashboardTip;
