import keyMirror from "keymirror";

export const Application = {
    Name: "Tracked.Life",
};

export const BaseURL = "";
export const Routes = {
    CHECKIN: `${BaseURL}/Checkin/`,
    CHECKIN_WITH_CODE: `${BaseURL}/Checkin/:checkinCode`,

    LOGIN: `${BaseURL}/Login`,
    LOGIN_CALLBACK: `${BaseURL}/LoginCallback`,
    LOGIN_FAILED: `${BaseURL}/LoginFailed`,
    LOGOUT: `${BaseURL}/Logout`,
    LOGOUT_CALLBACK: `${BaseURL}/LogoutCallback`,

    SIGNUP_WITH_CODE: `${BaseURL}/Signup/:signupCode`,
    SIGNUP: `${BaseURL}/Signup/`,

    NEWACCOUNT_WITH_CODE: `${BaseURL}/NewAccount/:signupCode`,
    NEW_ACCOUNT: `${BaseURL}/NewAccount/`,

    ACCOUNT_SELECT: `${BaseURL}/AccountSelect`,

    DASHBOARD: `${BaseURL}/Dashboard`,
    HOME: `${BaseURL}/`,
    IMPORTANTTHINGS: `${BaseURL}/ImportantThings`,
    LISTS: `${BaseURL}/Lists`,

    PEOPLE: `${BaseURL}/People`,
    PEOPLE_WITH_INVITE_ID: `${BaseURL}/People/:inviteId`,

    SUBSCRIPTION: `${BaseURL}/Subscription`,
    THINGS: `${BaseURL}/Things`,
    THING: `${BaseURL}/Thing`,
    NEWTHING: `${BaseURL}/NewThing`,
    TOUR: `${BaseURL}/Tour`,

    INVITE: `${BaseURL}/Invite/:inviteId`,
    INVITE_ACCEPT: `${BaseURL}/InviteAccept/:inviteId`,
    INVITE_ACCEPTED: `${BaseURL}/InviteAccepted/`,

    // Currently Unused
    REMINDERSCHEDULES: `${BaseURL}/ReminderSchedules`,
};

export const APIHost = window.location.origin;
export const APIEndpoints = {
    OIDC_Configuration: `${APIHost}/_configuration/${Application.Name}`,
    Auth: `${APIHost}/api/Auth/`,

    Account: `${APIHost}/api/Account/`,
    Thing: `${APIHost}/api/Thing/`,
    Invite: `${APIHost}/api/Invite/`,
    Signup: `${APIHost}/api/Signup/`,
    List: `${APIHost}/api/List/`,
};

export const ThingTabs = {
    ALL_THINGS: "AT",
    IN_PROGRESS_THINGS: "IT",
    RECENT_THINGS: "RT",
};

export const ThingEditorSections = {
    RELATED_PEOPLE: "RP",
    RELATED_THINGS: "RT",
    RELATED_LINKS: "LK",
    RENEWAL_DETAILS: "RD",
    PAYMENT_DETAILS: "PD",
    DISPOSITION_DETAILS: "DD",
    REVIEW_DETAILS: "RV",
};

export const GroupByTypes = {
    THING_NAME: "ThingName",
    THING_TYPE: "ThingType",
    RENEWAL_DATE: "RenewalDate",
    PAYMENT_DATE: "PaymentDate",
    RENEWAL_FREQUENCY: "RenewalFrequency",
    PAYMENT_FREQUENCY: "PaymentFrequency",
    DISPOSITION_ACTION: "DispositionAction",
    SEARCH_AREA: "SearchArea",
};

export const ActionTypes = keyMirror({
    CHANGE_GROUP_BY_TYPE: null,
    CHANGE_SEARCH_TEXT: null,
    CHANGE_THING_TAB: null,
    POST_THING_RESET: null,
    POST_THING: null,
    POST_THING_OK: null,
    POST_THING_FAIL: null,
    POST_THING_TYPE: null,

    // TODO: Remove these?
    GET_ACCOUNT_LISTS_LOAD: null,
    GET_ACCOUNT_LOAD: null,

    GET_DASHBOARD_MESSAGE_FAIL: null,
    GET_DASHBOARD_MESSAGE_IGNORE: null,
    GET_DASHBOARD_MESSAGE_LOAD: null,
    GET_DASHBOARD_MESSAGE_OK: null,
    GET_DASHBOARD_MESSAGE: null,
    GET_DASHBOARD_TIP_FAIL: null,
    GET_DASHBOARD_TIP_IGNORE: null,
    GET_DASHBOARD_TIP_LOAD: null,
    GET_DASHBOARD_TIP_OK: null,
    GET_DASHBOARD_TIP: null,
    GET_GROUP_BY_THINGS_LIST_FAIL: null,
    GET_GROUP_BY_THINGS_LIST_IGNORE: null,
    GET_GROUP_BY_THINGS_LIST_LOAD: null,
    GET_GROUP_BY_THINGS_LIST_OK: null,
    GET_GROUP_BY_THINGS_LIST: null,
    GET_IN_PROGRESS_THINGS_LIST_FAIL: null,
    GET_IN_PROGRESS_THINGS_LIST_IGNORE: null,
    GET_IN_PROGRESS_THINGS_LIST_LOAD: null,
    GET_IN_PROGRESS_THINGS_LIST_OK: null,
    GET_IN_PROGRESS_THINGS_LIST: null,
    POST_NEW_ACCOUNT_FAIL: null,
    POST_NEW_ACCOUNT_OK: null,
    POST_NEW_ACCOUNT: null,
    GET_OVERDUE_THINGS_LIST_FAIL: null,
    GET_OVERDUE_THINGS_LIST_IGNORE: null,
    GET_OVERDUE_THINGS_LIST_LOAD: null,
    GET_OVERDUE_THINGS_LIST_OK: null,
    GET_OVERDUE_THINGS_LIST: null,
    GET_RECENT_THINGS_LIST_FAIL: null,
    GET_RECENT_THINGS_LIST_IGNORE: null,
    GET_RECENT_THINGS_LIST_LOAD: null,
    GET_RECENT_THINGS_LIST_OK: null,
    GET_RECENT_THINGS_LIST: null,
    GET_RELATED_THINGS_LIST_FAIL: null,
    GET_RELATED_THINGS_LIST_IGNORE: null,
    GET_RELATED_THINGS_LIST_LOAD: null,
    GET_RELATED_THINGS_LIST_OK: null,
    GET_RELATED_THINGS_LIST: null,
    GET_SHARED_LISTS_FAIL: null,
    GET_SHARED_LISTS_IGNORE: null,
    GET_SHARED_LISTS_LOAD: null,
    GET_SHARED_LISTS_OK: null,
    GET_SHARED_LISTS: null,
    GET_THING_FAIL: null,
    GET_THING_IGNORE: null,
    GET_THING_OK: null,
    UNLOAD_THING: null,
    GET_PAYMENT_THINGS_LIST_FAIL: null,
    GET_PAYMENT_THINGS_LIST_IGNORE: null,
    GET_PAYMENT_THINGS_LIST_LOAD: null,
    GET_PAYMENT_THINGS_LIST_OK: null,
    GET_PAYMENT_THINGS_LIST: null,
    GET_THING: null,
    GET_THINGS_MESSAGE_FAIL: null,
    GET_THINGS_MESSAGE_IGNORE: null,
    GET_THINGS_MESSAGE_LOAD: null,
    GET_THINGS_MESSAGE_OK: null,
    GET_THINGS_MESSAGE: null,
    GET_PAYMENT_SUMMARY_LIST_FAIL: null,
    GET_PAYMENT_SUMMARY_LIST_IGNORE: null,
    GET_PAYMENT_SUMMARY_LIST_LOAD: null,
    GET_PAYMENT_SUMMARY_LIST_OK: null,
    GET_PAYMENT_SUMMARY_LIST: null,
    GET_UPCOMING_PAYMENTS_LIST_FAIL: null,
    GET_UPCOMING_PAYMENTS_LIST_IGNORE: null,
    GET_UPCOMING_PAYMENTS_LIST_LOAD: null,
    GET_UPCOMING_PAYMENTS_LIST_OK: null,
    GET_UPCOMING_PAYMENTS_LIST: null,
    GET_UPCOMING_THINGS_LIST_FAIL: null,
    GET_UPCOMING_THINGS_LIST_IGNORE: null,
    GET_UPCOMING_THINGS_LIST_LOAD: null,
    GET_UPCOMING_THINGS_LIST_OK: null,
    GET_UPCOMING_THINGS_LIST: null,
    INITIAL_LOAD_IGNORE: null,
    NEW_THING: null,
    UPDATE_RELATED_PEOPLE: null,
    UPDATE_RELATED_THINGS: null,
    UPDATE_RELATED_LINKS: null,
    UPDATE_NEW_THING_PEOPLE: null,
});

export const ThingPostTypes = keyMirror({
    NEW_THING_TYPE: null,
    NEW_PERSON: null,
    NEW_RELATED_THING: null,
    NEW_REMINDER_SCHEDULE: null,
    NEW_PAYMENT_THING: null,
    NEW_DISPOSITION_ACTION: null,
    UPDATE_THING: null,
    DELETE_THING: null,
});

export const LastPostStatus = keyMirror({
    ACTIVE: null,
    OK: null,
    FAIL: null,
});

export const ThingDateTypes = {
    RENEWAL: "Renewal",
    PAYMENT: "Payment",
};

export const ThingUpdateActions = {
    NEW: "New",
    UPDATE: "Update",
    DELETE: "Delete",
    NOCHANGE: "NoChange",
};

export const NotificationTypes = {
    SUCCESS: "success",
    INFO: "info",
    WARNING: "warning",
    ERROR: "error",
};

export const PersonTypeIds = {
    PERSON: "PN",
    PRIMARY_OWNER: "PO",
    SECONDARY_OWNER: "SO",
    INHERITOR: "IR",
    VIEWER_INHERITOR: "VI",
    VIEWER: "VR",
};

export const PersonStatuses = {
    INVITED: "I",
    PENDING: "P",
    VERIFIED: "V",
    EMAIL_VERIFIED: "E",
};

export const Defaults = {
    DEBOUNCE_MILLISECONDS: 500,
    THING_TAB: ThingTabs.IN_PROGRESS_THINGS,
    GROUP_BY_TYPE: GroupByTypes.THING_NAME,
    DRAWER_WIDTH: 400,
    EDITOR_DRAWER_WIDTH: 800,
    POPOVER_MOUSE_ENTER_DELAY_SLOW: 0.75,
    POPOVER_MOUSE_ENTER_DELAY_FAST: 0,
    POPOVER_MOUSE_LEAVE_DELAY: 0,
    REQUEST_TIMEOUT_MILLISECONDS: 3000,
    MAX_URL_LENGTH: 400,
    MAX_RICH_TEXT_CONTENT_SIZE_DISPLAY: "10MB",
    MAX_RICH_TEXT_CONTENT_SIZE: 10 * 1024 * 1024, // 10 MiB
};

export const EMPTY_GUID = "00000000-0000-0000-0000-000000000000";

export const SignupCodeStatuses = {
    Invalid: "Invalid",
    ValidNotUsed: "ValidNotUsed",
    ValidUsed: "ValidUsed",
};

export const CheckinCodeStatuses = {
    Invalid: "Invalid",
    Expired: "Expired",
    ValidAndCheckedIn: "ValidAndCheckedIn",
    ValidAndUsed: "ValidAndUsed",
};

export const CookieKeys = {
    LastSelectedAccount: "tlifeapp-last-selected-account",
};
