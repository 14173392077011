import React, { Fragment, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Input, Form, Alert } from "antd";

import { createPerson, setReloadForAccountLists } from "../../redux/actions";
import { showNotificationSuccess, hideNotification } from "../../functions";
import { LastPostStatus, ThingPostTypes, PersonTypeIds } from "../../constants";

import ThingInput from "./ThingInput";
import { createUniquePersonNameValidator } from "../../shared/FormItemValidators";

export const ThingNewPerson = (props) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [formRef, setFormRef] = useState();
    const lastPostType = useSelector((state) => state.thing.lastPostType);
    const lastPostStatus = useSelector((state) => state.thing.lastPostStatus);
    const lastPostData = useSelector((state) => state.thing.lastPostData);
    const allPeople = useSelector((state) => state.account.peopleList);
    const newPerson = useRef();
    const notificationKey = "NK_NewPerson";

    useEffect(() => {
        if (formRef) {
            if (lastPostStatus) {
                if (
                    lastPostType === ThingPostTypes.NEW_PERSON &&
                    lastPostStatus === LastPostStatus.OK
                ) {
                    form.resetFields();
                    showNotificationSuccess(
                        notificationKey,
                        'Person  "' + newPerson.current + '" created',
                        null
                    );
                    props.onClose();
                } else if (lastPostStatus === LastPostStatus.FAIL) {
                    hideNotification(notificationKey);
                }
            } else {
                form.resetFields(); // need this to force Form initialValues to reload
                // If we don't use the formRef the above line results in following console warning on page load
                // Warning: Instance created by `useForm` is not connect to any Form element. Forget to pass `form` prop?
                // See formRef solution at https://github.com/ant-design/ant-design/issues/21543#issuecomment-602008000
            }
        }
    }, [form, formRef, lastPostStatus, lastPostType, props]);

    const submitNewPersonForm = (values) => {
        values.newPerson = true;
        values.email = null;
        values.accountPersonTypeId = PersonTypeIds.PERSON;
        //console.info("newPersonForm Values", values);
        newPerson.current = values.firstName + " " + values.lastName;
        dispatch(createPerson(values));
        dispatch(setReloadForAccountLists());
    };

    const initialFormValues = {
        firstName: "",
        lastName: "",
    };

    return (
        <Fragment>
            <h3>New Person</h3>
            <Form
                name="newPersonForm"
                form={form}
                ref={setFormRef}
                onFinish={submitNewPersonForm}
                layout="vertical"
                hideRequiredMark
                scrollToFirstError
                initialValues={initialFormValues}
            >
                <ThingInput visible={true} label="First Name">
                    <Form.Item
                        name="firstName"
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: "Required",
                                whitespace: true,
                            },
                            {
                                validator: (rule, value, callback) =>
                                    createUniquePersonNameValidator(
                                        form,
                                        true,
                                        value,
                                        form.getFieldValue("lastName"),
                                        allPeople,
                                        null
                                    ),
                            },
                        ]}
                    >
                        <Input
                            placeholder="Enter a first name for the person"
                            allowClear
                            autoFocus
                            autoComplete="off"
                        />
                    </Form.Item>
                </ThingInput>
                <ThingInput visible={true} label="Last Name">
                    <Form.Item
                        name="lastName"
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: "Required",
                                whitespace: true,
                            },
                            {
                                validator: (rule, value, callback) =>
                                    createUniquePersonNameValidator(
                                        form,
                                        false,
                                        form.getFieldValue("firstName"),
                                        value,
                                        allPeople,
                                        null
                                    ),
                            },
                        ]}
                    >
                        <Input
                            placeholder="Enter a last name for the person"
                            allowClear
                            autoComplete="off"
                        />
                    </Form.Item>
                </ThingInput>
                <Form.Item>
                    <Button type="danger" htmlType="submit">
                        Save Person
                    </Button>
                    <Button
                        htmlType="reset"
                        onClick={props.onClose}
                        className="tt-button"
                    >
                        Discard Changes
                    </Button>
                </Form.Item>
            </Form>
            {lastPostStatus === LastPostStatus.ACTIVE ? (
                <Alert
                    type="info"
                    message={'Creating person "' + newPerson.current + '"...'}
                />
            ) : null}
            {lastPostStatus === LastPostStatus.FAIL ? (
                <Alert
                    type="error"
                    message={
                        'Person "' +
                        newPerson.current +
                        '" could not be created'
                    }
                    description={
                        lastPostData && lastPostData.message
                            ? lastPostData.message
                            : "An error occurred"
                    }
                />
            ) : null}
        </Fragment>
    );
};

export default ThingNewPerson;
