import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { Collapse, Popover } from "antd";
import { CaretRightFilled } from "@ant-design/icons";
import parse from "html-react-parser";
import classNames from "classnames";

import { ThingRelatedPeople } from "./ThingRelatedPeople";
import ThingPaymentDetailsContainer from "./ThingPaymentDetailsContainer";
import ThingRenewalDetailsContainer from "./ThingRenewalDetailsContainer";
import ThingDispositionDetailsContainer from "./ThingDispositionDetailsContainer";
import ThingReviewContainer from "./ThingReviewContainer";
import ThingRelatedThings from "./ThingRelatedThings";
import ThingLinks from "./ThingLinks";
import { Defaults, ThingEditorSections } from "../../constants";

export const ThingAccordianItem = (props) => {
    const tooltips = useSelector((state) => state.account.tooltipsList);
    const { Panel } = Collapse;
    const accordianItemId = props.itemId;
    const accordianItemTitle = props.itemTitle;
    const thing = props.thing;
    const form = props.form;
    const initialFormValues = props.initialFormValues;
    const handleValuesChange = props.handleValuesChange;
    const activeThingIdKey = useSelector(
        (state) => state.thing.activeThingId ?? "NEW_THING"
    );

    let selectedItem = null;
    let title = "TOOLTIP KEY NOT FOUND";
    let content = "";
    let showPopover = false;
    if (props.tooltipkey) {
        showPopover = true;
        if (tooltips) {
            selectedItem = tooltips.filter(
                (t) => t.key === props.tooltipkey
            )[0];
            if (selectedItem) {
                title = selectedItem.title;
                content = selectedItem.content;
            }
        }
    }

    const defaultActiveKeys = [
        ThingEditorSections.RELATED_PEOPLE,
        ThingEditorSections.REVIEW_DETAILS,
    ];

    if (initialFormValues.hasRelatedThingsInfo === true) {
        defaultActiveKeys.push(ThingEditorSections.RELATED_THINGS);
    }
    if (initialFormValues.hasLinksInfo === true) {
        defaultActiveKeys.push(ThingEditorSections.RELATED_LINKS);
    }
    if (initialFormValues.hasRenewalInfo === true) {
        defaultActiveKeys.push(ThingEditorSections.RENEWAL_DETAILS);
    }
    if (initialFormValues.hasPaymentInfo === true) {
        defaultActiveKeys.push(ThingEditorSections.PAYMENT_DETAILS);
    }
    if (initialFormValues.hasDispositionInfo === true) {
        defaultActiveKeys.push(ThingEditorSections.DISPOSITION_DETAILS);
    }

    var classNameActive = "";
    if (defaultActiveKeys.indexOf(accordianItemId) >= 0) {
        classNameActive = "tt-ted-panel-active";
    }

    const accordianTitle = () => {
        if (showPopover) {
            return (
                <Popover
                    trigger="hover"
                    placement="rightTop"
                    content={parse(content)}
                    title={title}
                    mouseEnterDelay={Defaults.POPOVER_MOUSE_ENTER_DELAY_SLOW}
                    mouseLeaveDelay={Defaults.POPOVER_MOUSE_LEAVE_DELAY}
                >
                    <strong>{accordianItemTitle}</strong>
                </Popover>
            );
        }
        return <strong>{accordianItemTitle}</strong>;
    };

    return (
        <Fragment>
            <Collapse
                bordered={false}
                defaultActiveKey={defaultActiveKeys}
                expandIcon={({ isActive }) => (
                    <CaretRightFilled rotate={isActive ? 90 : 0} />
                )}
                key={activeThingIdKey}
            >
                <Panel
                    header={accordianTitle()}
                    key={accordianItemId}
                    className={classNames("tt-ted-panel", classNameActive)}
                >
                    {accordianItemId === ThingEditorSections.RELATED_PEOPLE ? (
                        <ThingRelatedPeople
                            form={form}
                            thing={thing}
                            initialFormValues={initialFormValues}
                            handleValuesChange={handleValuesChange}
                        />
                    ) : null}
                    {accordianItemId === ThingEditorSections.RELATED_THINGS ? (
                        <ThingRelatedThings
                            form={form}
                            thing={thing}
                            initialFormValues={initialFormValues}
                            handleValuesChange={handleValuesChange}
                        />
                    ) : null}
                    {accordianItemId === ThingEditorSections.RELATED_LINKS ? (
                        <ThingLinks
                            form={form}
                            thing={thing}
                            initialFormValues={initialFormValues}
                            handleValuesChange={handleValuesChange}
                        />
                    ) : null}
                    {accordianItemId === ThingEditorSections.RENEWAL_DETAILS ? (
                        <ThingRenewalDetailsContainer
                            form={form}
                            thing={thing}
                            initialFormValues={initialFormValues}
                            handleValuesChange={handleValuesChange}
                            key={activeThingIdKey} // Add key to force remount on thing change
                        />
                    ) : null}
                    {accordianItemId === ThingEditorSections.PAYMENT_DETAILS ? (
                        <ThingPaymentDetailsContainer
                            form={form}
                            thing={thing}
                            initialFormValues={initialFormValues}
                            handleValuesChange={handleValuesChange}
                            key={activeThingIdKey} // Add key to force remount on thing change
                        />
                    ) : null}
                    {accordianItemId ===
                    ThingEditorSections.DISPOSITION_DETAILS ? (
                        <ThingDispositionDetailsContainer
                            form={form}
                            thing={thing}
                            initialFormValues={initialFormValues}
                            handleValuesChange={handleValuesChange}
                        />
                    ) : null}
                    {accordianItemId === ThingEditorSections.REVIEW_DETAILS ? (
                        <ThingReviewContainer
                            form={form}
                            thing={thing}
                            initialFormValues={initialFormValues}
                            handleValuesChange={handleValuesChange}
                        />
                    ) : null}
                </Panel>
            </Collapse>
        </Fragment>
    );
};

export default ThingAccordianItem;
