import React, {
    useEffect,
    useCallback,
    Fragment,
    useState,
    useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    Form,
    Popconfirm,
    Affix,
    Popover,
    Spin,
    Col,
    Row,
    Alert,
} from "antd";
import moment from "moment";

import {
    jsonClone,
    showNotificationSuccess,
    showNotificationError,
    showNotificationInfo,
    showNotificationWarning,
    nullIfEmptyOrWhitespace,
    convertToGuidString,
} from "../../functions";
import {
    loadActiveThing,
    updateThingPeopleList,
    unloadActiveThing,
    setNewThing,
} from "../../redux/actions";
import {
    setThingIsDirty,
    updateThing,
    deleteThing,
} from "../../redux/actions/thingActions";
import TitleBar from "../../shared/TitleBar";
import LoadingIndicator from "../../shared/LoadingIndicator";
import {
    LastPostStatus,
    ThingPostTypes,
    Defaults,
    PersonTypeIds,
    ThingEditorSections,
    Routes,
    BaseURL,
} from "../../constants";

import ThingAccordianItem from "./ThingAccordianItem";
import ThingDetailsContainer from "./ThingDetailsContainer";
import { push } from "connected-react-router";
import { reloadThingListsDispatchable } from "../../redux/actions/thingListsActions";

const getLocationThingId = (pathname) => {
    const thingUrl = `${BaseURL}/Thing/`;
    if (pathname) {
        if (pathname.startsWith(thingUrl)) {
            var thingId = convertToGuidString(pathname.replace(thingUrl, ""));
            return thingId;
        }
    }
    return null;
};

export const ThingContainer = () => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [formRef, setFormRef] = useState();
    const isDirty = useSelector((state) => state.thing.isDirty);
    const isSaving = useSelector((state) => state.thing.isSaving);
    const pathname = useSelector((state) => state.router.location.pathname);
    const pathThingId = useSelector((state) =>
        getLocationThingId(state.router.location.pathname)
    );
    const activeThingId = useSelector((state) => state.thing.activeThingId);
    const activeThingIdKey = useSelector(
        (state) => state.thing.activeThingId ?? "NEW_THING"
    );
    const thing = useSelector((state) =>
        state.thing.activeThingId === null ? null : state.thing.activeThing
    );
    const lastThingName = useSelector((state) =>
        state.thing.activeThingId === null ? null : state.thing.lastThingName
    );
    const isThingLoading = useSelector((state) => state.thing.isThingLoading);
    const error = useSelector((state) => state.thing.thingError);
    const newThing = useSelector((state) => state.thing.newThing);
    const accountPeopleList = useSelector((state) => state.account.peopleList);
    const activeThingPeople = useSelector(
        (state) => state.thing.activeThingPeople
    );
    const activeThingRelatedThings = useSelector(
        (state) => state.thing.activeThingRelatedThings
    );
    const activeThingLinks = useSelector(
        (state) => state.thing.activeThingLinks
    );
    const lastPostType = useSelector((state) => state.thing.lastPostType);
    const lastPostStatus = useSelector((state) => state.thing.lastPostStatus);
    const lastPostData = useSelector((state) => state.thing.lastPostData);
    const notificationKey = "NK_CreateUpdateThing";
    const thingName = useRef();
    const isRelatedToOtherThings = useSelector((state) =>
        state.thing.activeThing === null
            ? false
            : state.thing.activeThing.relatedToThingsCount > 0
    );
    const accountCurrency = useSelector(
        (state) => state.account.accountCurrency
    );

    useEffect(() => {
        // load thing if we have come directly to page witout loading the thing first
        if (!isThingLoading && activeThingId !== pathThingId && !error) {
            dispatch(loadActiveThing(pathThingId));
        }

        // if page reloads after a thing has been deleted and pathThingId is still deleted thing ID or
        // if a bad thing ID was supplied then redirect to Things
        if (!isThingLoading && activeThingId !== pathThingId && error) {
            if (
                error.data.includes(
                    "System.ArgumentException: thingId '" +
                        pathThingId +
                        "' not found"
                )
            ) {
                dispatch(unloadActiveThing());
                dispatch(reloadThingListsDispatchable());
                dispatch(push(Routes.THINGS));
                // TODO: Should return here?
            }
        }

        if (formRef) {
            if (lastPostStatus) {
                if (lastPostType === ThingPostTypes.UPDATE_THING) {
                    if (lastPostStatus === LastPostStatus.OK) {
                        if (thing || newThing) {
                            showNotificationSuccess(
                                notificationKey,
                                'Thing "' + thingName.current + '" saved',
                                null
                            );
                            dispatch(
                                loadActiveThing(lastPostData.data.thingId)
                            );
                            dispatch(reloadThingListsDispatchable());
                            dispatch(setThingIsDirty(false));
                        }
                    } else if (lastPostStatus === LastPostStatus.FAIL) {
                        if (thing) {
                            showNotificationError(
                                notificationKey,
                                'Thing "' + thingName.current + '" save failed',
                                lastPostData.message +
                                    "<hr/>" +
                                    lastPostData.stack
                            );
                        }
                    }
                } else if (lastPostType === ThingPostTypes.DELETE_THING) {
                    if (lastPostStatus === LastPostStatus.OK) {
                        if (thing) {
                            showNotificationWarning(
                                notificationKey,
                                'Thing "' + lastThingName + '" deleted',
                                null
                            );
                            dispatch(unloadActiveThing());
                            dispatch(reloadThingListsDispatchable());
                            dispatch(push(Routes.THINGS));
                        }
                    } else if (lastPostStatus === LastPostStatus.FAIL) {
                        if (thing) {
                            showNotificationError(
                                notificationKey,
                                'Thing "' + lastThingName + '" delete failed',
                                lastPostData.message +
                                    "<hr/>" +
                                    lastPostData.stack
                            );
                        }
                    }
                }
                // else if (lastPostStatus === LastPostStatus.FAIL) {
                //   hideNotification(notificationKey);
                // }
            } else {
                if (!form.isFieldsTouched()) {
                    form.resetFields(); // need this to force Form initialValues to reload
                    // If we don't use the formRef the above line results in following console warning on page load
                    // Warning: Instance created by `useForm` is not connect to any Form element. Forget to pass `form` prop?
                    // See formRef solution at https://github.com/ant-design/ant-design/issues/21543#issuecomment-602008000
                }
            }
        }
    }, [
        activeThingId,
        thing,
        formRef,
        form,
        dispatch,
        lastPostStatus,
        lastPostType,
        lastPostData,
        lastThingName,
        newThing,
        pathThingId,
        isThingLoading,
        error,
    ]);

    // Effect to ensure new thing is set if pathname changes to Routes.NEWTHING
    useEffect(() => {
        if (pathname === Routes.NEWTHING && !newThing) {
            dispatch(setNewThing());
        }
    }, [dispatch, pathname, newThing]);

    // Effect to handle a new thing being created
    useEffect(() => {
        const getPrimaryOwner = () => {
            let primaryOwner = null;
            if (accountPeopleList) {
                accountPeopleList.forEach((p) => {
                    if (p.accountPersonTypeId === PersonTypeIds.PRIMARY_OWNER) {
                        primaryOwner = jsonClone(p);
                    }
                });
            }
            return primaryOwner;
        };

        const ensureRelatedPeopleIncludesPrimaryOwner = () => {
            // for newThing default the related people to the primary owner
            if (
                newThing &&
                activeThingPeople &&
                activeThingPeople.length === 0
            ) {
                let primaryOwner = getPrimaryOwner();
                if (primaryOwner) {
                    dispatch(updateThingPeopleList(primaryOwner));
                }
            }
        };

        ensureRelatedPeopleIncludesPrimaryOwner();
    }, [dispatch, newThing, activeThingPeople, accountPeopleList]);

    // Effect to ensure WYSIWIG editors are cleared when a new thing is created
    useEffect(() => {
        if (newThing) {
            form.setFieldsValue({ thingDetails: "" });
        }
    }, [dispatch, form, newThing]);

    const resetForm = () => {
        dispatch(loadActiveThing(activeThingId));
        form.resetFields();
        showNotificationInfo(notificationKey, "Changes discarded", null);
        dispatch(setThingIsDirty(false));
    };

    const handleDelete = (thing) => {
        dispatch(deleteThing(thing.thingId));
    };

    const submitForm = async () => {
        // console.info("thingForm Values", form.getFieldsValue());

        if (!form.isFieldsTouched()) {
            showNotificationWarning(
                notificationKey,
                "No fields were updated",
                null
            );
            return;
        }

        try {
            const values = await form.validateFields();
            updateThingUsingFormValues(values);
        } catch (ex) {
            form.scrollToField("important");
        }
    };

    const updateThingUsingFormValues = useCallback(
        (values) => {
            thingName.current = values.thingName;
            values.newThing = newThing;
            values.thingId = newThing ? null : activeThingId;
            values.thingTypeId = values.thingTypeId.substring(
                0,
                values.thingTypeId.indexOf(":")
            );
            values.relatedPeople = jsonClone(activeThingPeople);
            if (values.relatedPeople) {
                values.relatedPeople.forEach((p) => {
                    delete p.existing;
                    delete p.firstName;
                    delete p.lastName;
                    delete p.accountPersonTypeId;
                });
            } else {
                values.relatedPeople = [];
            }
            values.relatedThings = jsonClone(activeThingRelatedThings);
            if (values.relatedThings) {
                values.relatedThings.forEach((t) => {
                    delete t.existing;
                    delete t.name;
                    delete t.thingTypeId;
                    delete t.thingTypeName;
                    delete t.paymentThing;
                    delete t.thisThingPaymentThing;
                });
            } else {
                values.relatedThings = [];
            }
            values.relatedLinks = jsonClone(activeThingLinks);
            if (values.relatedLinks) {
                values.relatedLinks.forEach((l) => {
                    delete l.existing;
                    delete l.tempLinkId;
                });
            } else {
                values.relatedLinks = [];
            }
            delete values.personId;
            delete values.relatedThingId;
            delete values.hiddenLinkId;
            values.paymentFrequencyId = nullIfEmptyOrWhitespace(
                values.paymentFrequencyId
            );
            values.autoRolloverPaymentDate = values.paymentFrequencyId !== null;
            values.paymentReminderScheduleId = nullIfEmptyOrWhitespace(
                values.paymentReminderScheduleId
            );
            values.paymentThingId = nullIfEmptyOrWhitespace(
                values.paymentThingId
            );
            values.renewalFrequencyId = nullIfEmptyOrWhitespace(
                values.renewalFrequencyId
            );
            values.renewalReminderScheduleId = nullIfEmptyOrWhitespace(
                values.renewalReminderScheduleId
            );
            values.thingDetails = nullIfEmptyOrWhitespace(values.thingDetails);
            values.dispositionActionId = nullIfEmptyOrWhitespace(
                values.dispositionActionId
            );
            values.dispositionDetails = nullIfEmptyOrWhitespace(
                values.dispositionDetails
            );
            values.reviewNotes = nullIfEmptyOrWhitespace(values.reviewNotes);
            values.currencyCode = nullIfEmptyOrWhitespace(values.currencyCode);
            values.paymentAmount = values.paymentAmount
                ? values.paymentAmount
                : 0;
            // remove time portion of dates
            values.nextPaymentDate = values.nextPaymentDate
                ? values.nextPaymentDate.startOf("day")
                : null;
            values.nextRenewalDate = values.nextRenewalDate
                ? values.nextRenewalDate.startOf("day")
                : null;
            values.nextReviewDate = values.nextReviewDate
                ? values.nextReviewDate.startOf("day")
                : null;
            // clear values when not relevant
            if (!values.nextPaymentDate) {
                values.paymentReminderScheduleId = null;
            }
            if (!values.nextRenewalDate) {
                values.autoRolloverRenewalDate = false;
                values.renewalReminderScheduleId = null;
            }
            // console.info("thingForm Post Values", values);

            dispatch(updateThing(values));
        },
        [
            activeThingId,
            activeThingLinks,
            activeThingPeople,
            activeThingRelatedThings,
            dispatch,
            newThing,
        ]
    );

    const handleValuesChange = () => {
        let newIsDirty = form.isFieldsTouched();
        dispatch(setThingIsDirty(newIsDirty));
    };

    const initialFormValues = {
        important: thing && thing.important ? true : false,
        thingName: thing && thing.name ? thing.name : "",
        thingTypeId:
            thing && thing.thingTypeId
                ? thing.thingTypeId + ":" + thing.thingType
                : undefined,
        thingDetails: thing && thing.details ? thing.details : "",

        hasRelatedThingsInfo:
            thing && thing.relatedThings && thing.relatedThings.length > 0
                ? true
                : false,

        hasLinksInfo:
            thing && thing.links && thing.links.length > 0 ? true : false,

        renewalFrequencyId:
            thing && thing.renewalFrequencyId
                ? thing.renewalFrequencyId
                : undefined,
        nextRenewalDate:
            thing && thing.renewalDate ? moment(thing.renewalDate) : null,
        autoRolloverRenewalDate:
            thing && thing.autoRolloverNextRenewal ? true : false,
        renewalReminderScheduleId:
            thing && thing.renewalReminderScheduleId
                ? thing.renewalReminderScheduleId
                : undefined,
        hasRenewalInfo:
            thing &&
            (thing.renewalFrequencyId ||
                thing.renewalDate ||
                thing.autoRolloverNextRenewal ||
                thing.renewalReminderScheduleId)
                ? true
                : false,

        paymentThingId:
            thing && thing.paymentThingId ? thing.paymentThingId : undefined,
        paymentFrequencyId:
            thing && thing.paymentFrequencyId
                ? thing.paymentFrequencyId
                : undefined,
        automaticPayment:
            thing && thing.paymentMethodId && thing.paymentMethodId === "AC"
                ? true
                : false,
        nextPaymentDate:
            thing && thing.nextPaymentDate
                ? moment(thing.nextPaymentDate)
                : null,
        autoRolloverPaymentDate:
            thing && thing.autoRolloverNextPayment ? true : false,
        currencyCode: newThing
            ? accountCurrency
            : thing && thing.currencyCode
            ? thing.currencyCode
            : accountCurrency,
        paymentAmount:
            thing?.paymentAmount === undefined ? null : thing?.paymentAmount,
        approximateAmount: thing && thing.approximateAmount ? true : false,
        paymentReminderScheduleId:
            thing && thing.paymentReminderScheduleId
                ? thing.paymentReminderScheduleId
                : undefined,
        hasPaymentInfo:
            thing &&
            (thing.paymentThingId ||
                thing.paymentMethodId ||
                thing.paymentFrequencyId ||
                thing.nextPaymentDate ||
                thing.autoRolloverNextPayment ||
                thing.paymentAmount ||
                thing.approximateAmount ||
                thing.paymentReminderScheduleId)
                ? true
                : false,

        dispositionActionId:
            thing && thing.dispositionActionId
                ? thing.dispositionActionId
                : undefined,
        dispositionDetails:
            thing && thing.dispositionDetails ? thing.dispositionDetails : "",
        hasDispositionInfo:
            thing && (thing.dispositionActionId || thing.dispositionDetails)
                ? true
                : false,

        requiresReview: thing ? (thing.requiresReview ? true : false) : true, // Default to true if this is a new Thing
        completed: thing && thing.complete ? true : false,
        nextReviewDate:
            thing && thing.nextReviewDate ? moment(thing.nextReviewDate) : null,
        reviewNotes: thing && thing.reviewNotes ? thing.reviewNotes : "",
    };

    const thingFormComponentProps = {
        form: form,
        thing: thing,
        initialFormValues: initialFormValues,
        handleValuesChange: handleValuesChange,
    };

    const renderButtonBar = () => {
        if (isSaving) {
            return (
                <div className="tt-ted-button-bar">
                    <Row>
                        <Col span={22}></Col>
                        <Col span={2}>
                            <Spin className="tt-ted-spinner" />
                        </Col>
                    </Row>
                </div>
            );
        }

        return (
            <div className="tt-ted-button-bar">
                <Form.Item>
                    <Popover
                        trigger="hover"
                        placement="top"
                        title="Delete Thing"
                        content={
                            isRelatedToOtherThings
                                ? "This button is disabled because this thing is related to other things. You must delete all relationships before you can delete this thing."
                                : "Click here to delete this thing"
                        }
                        mouseEnterDelay={
                            Defaults.POPOVER_MOUSE_ENTER_DELAY_FAST
                        }
                        mouseLeaveDelay={Defaults.POPOVER_MOUSE_LEAVE_DELAY}
                    >
                        <Popconfirm
                            title="Are you sure you want to delete this thing?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={(e) => handleDelete(thing)}
                        >
                            <Button
                                htmlType="button"
                                disabled={newThing || isRelatedToOtherThings}
                            >
                                Delete
                            </Button>
                        </Popconfirm>
                    </Popover>
                    <Popover
                        trigger="hover"
                        placement="top"
                        title="Save Changes"
                        content={
                            isDirty
                                ? "Click here to save your changes"
                                : "The Save button will be enabled when you make changes to the form"
                        }
                        mouseEnterDelay={
                            Defaults.POPOVER_MOUSE_ENTER_DELAY_FAST
                        }
                        mouseLeaveDelay={Defaults.POPOVER_MOUSE_LEAVE_DELAY}
                    >
                        <Button
                            htmlType="button"
                            type="danger"
                            onClick={submitForm}
                            className="float-right tt-button"
                            disabled={!isDirty}
                        >
                            Save Changes
                        </Button>
                    </Popover>
                    <Popover
                        trigger="hover"
                        placement="top"
                        title="Discard Changes"
                        content={
                            isDirty
                                ? "Click here to discard any changes you have made to the form"
                                : "The Discard Changes button will be enabled when you make changes to the form"
                        }
                        mouseEnterDelay={
                            Defaults.POPOVER_MOUSE_ENTER_DELAY_FAST
                        }
                        mouseLeaveDelay={Defaults.POPOVER_MOUSE_LEAVE_DELAY}
                    >
                        <Popconfirm
                            title="Are you sure you want to discard your changes?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={resetForm}
                        >
                            <Button
                                htmlType="button"
                                className="float-right tt-button"
                                disabled={!isDirty}
                            >
                                Discard Changes
                            </Button>
                        </Popconfirm>
                    </Popover>
                </Form.Item>
            </div>
        );
    };

    if (error) {
        return (
            <div>
                <TitleBar
                    tooltipkey="Header_ThingDetails"
                    color="#28a745"
                    newThing={newThing}
                    thingName={thing && thing.name}
                />
                <div className="tt-container">
                    <div className="tt-empty-list">
                        <Alert
                            message="The thing you were looking for was not found. It may have been deleted or belongs to a different account."
                            type="warning"
                        />
                    </div>
                </div>
            </div>
        );
    }

    if (isThingLoading) {
        return <LoadingIndicator />;
    }

    if ((!activeThingId || !thing) && !newThing) {
        return (
            <div>
                <TitleBar
                    tooltipkey="Header_ThingDetails"
                    color="#28a745"
                    newThing={newThing}
                    thingName={thing && thing.name}
                />
                <div className="tt-container">
                    <div className="tt-empty-list">
                        Click on an item in the list on the left to view the
                        details
                    </div>
                </div>
            </div>
        );
    }

    return (
        <Fragment>
            <Form
                name="thingForm"
                form={form}
                ref={setFormRef}
                layout="vertical"
                hideRequiredMark
                initialValues={initialFormValues}
                onValuesChange={handleValuesChange}
            >
                <Affix offsetTop={90}>
                    <div>
                        <TitleBar
                            tooltipkey="Header_ThingDetails"
                            color="#28a745"
                            newThing={newThing}
                            thingName={thing && thing.name}
                            isImportant={thing?.important}
                            isInProgress={thing && !thing.complete}
                        />
                    </div>
                </Affix>
                <div className="tt-tc-scroll">
                    <div className="tt-container">
                        <div className="tt-ted-details-section">
                            <ThingDetailsContainer
                                {...thingFormComponentProps}
                                key={activeThingIdKey} // Add key to force remount on thing change
                            />
                        </div>
                        <ThingAccordianItem
                            itemId={ThingEditorSections.RELATED_PEOPLE}
                            itemTitle="RELATED PEOPLE"
                            {...thingFormComponentProps}
                            tooltipkey="ThingHeader_RelatedPeople"
                        />
                        <ThingAccordianItem
                            itemId={ThingEditorSections.RELATED_THINGS}
                            itemTitle="RELATED THINGS"
                            {...thingFormComponentProps}
                            tooltipkey="ThingHeader_RelatedThings"
                        />
                        <ThingAccordianItem
                            itemId={ThingEditorSections.RELATED_LINKS}
                            itemTitle="LINKS"
                            {...thingFormComponentProps}
                            tooltipkey="ThingHeader_RelatedLinks"
                        />
                        <ThingAccordianItem
                            itemId={ThingEditorSections.RENEWAL_DETAILS}
                            itemTitle="RENEWAL DETAILS"
                            {...thingFormComponentProps}
                            tooltipkey="ThingHeader_RenewalDetails"
                        />
                        <ThingAccordianItem
                            itemId={ThingEditorSections.PAYMENT_DETAILS}
                            itemTitle="PAYMENT DETAILS"
                            {...thingFormComponentProps}
                            tooltipkey="ThingHeader_PaymentDetails"
                        />
                        <ThingAccordianItem
                            itemId={ThingEditorSections.DISPOSITION_DETAILS}
                            itemTitle="DISPOSITION DETAILS"
                            {...thingFormComponentProps}
                            tooltipkey="ThingHeader_DispositionDetails"
                        />
                        <ThingAccordianItem
                            itemId={ThingEditorSections.REVIEW_DETAILS}
                            itemTitle="REVIEW DETAILS"
                            {...thingFormComponentProps}
                            tooltipkey="ThingHeader_ReviewDetails"
                        />
                    </div>
                </div>
                {renderButtonBar()}
            </Form>
        </Fragment>
    );
};

export default ThingContainer;
