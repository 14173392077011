import React, { Fragment, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Input, Form, Alert } from "antd";

import { createThingType, setReloadForAccountLists } from "../../redux/actions";
import {
    showNotificationSuccess,
    hideNotification,
    createLengthValidationRule,
} from "../../functions";
import { LastPostStatus, ThingPostTypes } from "../../constants";

import ThingInput from "./ThingInput";
import LoadingIndicator from "../../shared/LoadingIndicator";

export const ThingNewThingType = (props) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [formRef, setFormRef] = useState();
    const paymentTypesList = useSelector(
        (state) => state.account.paymentTypesList
    );
    const lastPostType = useSelector((state) => state.thing.lastPostType);
    const lastPostStatus = useSelector((state) => state.thing.lastPostStatus);
    const lastPostData = useSelector((state) => state.thing.lastPostData);
    const newThingType = useRef();
    const notificationKey = "NK_NewThingType";

    useEffect(() => {
        if (formRef) {
            if (lastPostStatus) {
                if (
                    lastPostType === ThingPostTypes.NEW_THING_TYPE &&
                    lastPostStatus === LastPostStatus.OK
                ) {
                    form.resetFields();
                    showNotificationSuccess(
                        notificationKey,
                        'Thing Type  "' + newThingType.current + '" created',
                        null
                    );
                    props.onClose();
                } else if (lastPostStatus === LastPostStatus.FAIL) {
                    hideNotification(notificationKey);
                }
            } else {
                form.resetFields(); // need this to force Form initialValues to reload
                // If we don't use the formRef the above line results in following console warning on page load
                // Warning: Instance created by `useForm` is not connect to any Form element. Forget to pass `form` prop?
                // See formRef solution at https://github.com/ant-design/ant-design/issues/21543#issuecomment-602008000
            }
        }
    }, [form, formRef, lastPostStatus, lastPostType, props]);

    const submitNewThingTypeForm = (values) => {
        //console.info("newThingTypeForm Values", values);
        newThingType.current = values.name;
        dispatch(createThingType(values));
        dispatch(setReloadForAccountLists());
    };

    const initialFormValues = {
        name: "",
        paymentTypeCode: undefined,
    };

    if (!paymentTypesList) {
        return <LoadingIndicator />;
    }

    return (
        <Fragment>
            <h3>New Thing Type</h3>
            <Form
                name="newThingTypeForm"
                form={form}
                ref={setFormRef}
                onFinish={submitNewThingTypeForm}
                layout="vertical"
                hideRequiredMark
                scrollToFirstError
                initialValues={initialFormValues}
            >
                <ThingInput visible={true} label="Thing Type Name">
                    <Form.Item
                        name="name"
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: "Required",
                                whitespace: true,
                            },
                            createLengthValidationRule(50, "Thing Type Name"),
                        ]}
                    >
                        <Input
                            placeholder="Enter a name for the Thing Type"
                            allowClear
                            autoFocus
                            autoComplete="off"
                        />
                    </Form.Item>
                </ThingInput>
                {/* <p>
          If your thing type is a payment type, e.g. a Bank Account, Credit Card, etc. 
          select the appropriate Payment Type from the list below, otherwise leave it blank. 
          If you give this Thing Type a Payment Type, then Things you later create with 
          this Thing Type can be used as payment methods on things. 
        </p>
        <ThingInput visible={true} label="Payment Type">
          <Form.Item name="paymentTypeCode"
            hasFeedback
          >
            <Select name="paymentTypeCode"
              dropdownClassName="tt-ted-dropdown"
              placeholder="Select payment type (if applicable)"
            >
              <Option key="BLANK" value=""></Option>
              {paymentTypesList.map(pt =>
                <Option key={pt.paymentTypeId} value={pt.paymentTypeId}>{pt.localizedName}</Option>
              )}
            </Select>
          </Form.Item>
        </ThingInput> */}
                <p>
                    Thing Types you create will appear under the{" "}
                    <strong>My Thing Types</strong> category in the Thing types
                    list.
                </p>
                <Form.Item>
                    <Button type="danger" htmlType="submit">
                        Save Thing Type
                    </Button>
                    <Button
                        htmlType="reset"
                        onClick={props.onClose}
                        className="tt-button"
                    >
                        Discard Changes
                    </Button>
                </Form.Item>
            </Form>
            {lastPostStatus === LastPostStatus.ACTIVE ? (
                <Alert
                    type="info"
                    message={
                        'Creating thing type "' + newThingType.current + '"...'
                    }
                />
            ) : null}
            {lastPostStatus === LastPostStatus.FAIL ? (
                <Alert
                    type="error"
                    message={
                        'Thing type "' +
                        newThingType.current +
                        '" could not be created'
                    }
                    description={
                        lastPostData && lastPostData.message
                            ? lastPostData.message
                            : "An error occurred"
                    }
                />
            ) : null}
        </Fragment>
    );
};

export default ThingNewThingType;
