import KeyMirror from "keymirror";
import { UserManager, WebStorageStateStore } from "oidc-client";
import { APIEndpoints, Application } from "../constants";
import { ajaxGetAnonymous } from "../redux/actions/ajaxActions";

const shouldLog = false;
const log = (...args) => (shouldLog ? console.log(...args) : null);

export const AuthResults = KeyMirror({
    Redirect: null,
    Success: null,
    Fail: null,
});
const createAuthResult = (result, data) => {
    return {
        result: result,
        data: data,
    };
};

export const AuthProviders = {
    Microsoft: "Microsoft",
    Google: "Google",
    Amazon: "Amazon",
};

class AuthenticationService {
    _isAuthenticated = false;
    _user = null;
    _userManager = undefined;
    _logoutCallbacks = [];

    isInitialized() {
        return this.userManager !== undefined && this.userManager !== null;
    }

    async initializeAuthService(callback) {
        await this.ensureIsInitializedAsync();
        await this.getUserAsync();

        if (callback) {
            callback();
        }
    }

    async ensureIsInitializedAsync() {
        if (this.isInitialized()) {
            // Already setup
            return;
        }

        log("Initializing Authentication Service.");

        // Fetch the OIDC configuration from the server
        let response = await fetch(APIEndpoints.OIDC_Configuration);
        // console.log(await response.text())
        if (!response.ok) {
            throw new Error(
                `Could not load authentication settings for '${Application.Name}'`
            );
        }

        // Setup user manager
        let userManagerSettings = await response.json();
        userManagerSettings.automaticSilentRenew = true;
        userManagerSettings.includeIdTokenInSilentRenew = true;
        userManagerSettings.userStore = new WebStorageStateStore({
            prefix: Application.Name,
        });
        this.userManager = new UserManager(userManagerSettings);

        // Register callbacks
        this.userManager.events.addUserSignedOut(async () => {
            await this.userManager.removeUser();
            this.updateAuthState(undefined);
        });
        this.userManager.events.addAccessTokenExpired(() => {
            log("Access token expired");
        });
        this.userManager.events.addAccessTokenExpiring(() => {
            log("Access token expiring");
        });
        this.userManager.events.addSilentRenewError((error) => {
            console.error("Silent renew error", error);
        });
        this.userManager.events.addUserLoaded((...args) => {
            log("User loaded", ...args);
        });
        this.userManager.events.addUserSessionChanged((...args) => {
            log("User session changed", ...args);
        });
        this.userManager.events.addUserSignedOut((...args) => {
            log("User signed out", ...args);
        });
        this.userManager.events.addUserUnloaded((...args) => {
            log("User unloaded", ...args);
        });

        log("Successfully initialized Authentication Service.");
    }

    addLogoutCallback(callback) {
        this._logoutCallbacks.push(callback);
    }

    async getAuthStateAsync() {
        return {
            isAuthenticated: this._isAuthenticated,
            isExpired: this._user?.expired,
            user: this._user?.profile,
            userId: this._user?.profile?.sub,
        };
    }

    updateAuthState(user) {
        log("Updating Auth State", user);
        this._user = user;
        this._isAuthenticated = !!this._user;
        this.notifySubscribersOfAuthStateChange();
    }

    async getIsAuthenticatedAsync() {
        const user = await this.getUser();
        return !!user;
    }

    async getUserAsync() {
        if (this._user && this._user.profile) {
            // Already have the user
            return this._user.profile;
        }

        log("Attempting to get auth user.");
        await this.ensureIsInitializedAsync();
        const user = await this.userManager.getUser();
        this.updateAuthState(user);
        return user;
    }

    async getAccessTokenAsync() {
        // Perform a last check & attempt to refresh the access token if it is expired
        if (!!this._user && this._user.expired) {
            console.warn(
                "Access token was expired when attempting to get it. Attempting to silent renew it"
            );
            await this.performSilentLoginAsync();

            if (this._user.expired) {
                console.warn(
                    "User access token was still expired after silent login. Consider logging out due to inactivity"
                );
            }
        }

        return !!this._user ? this._user.access_token : null;
    }

    //#region Auth State Change Event

    _authStateSubscribers = [];
    _authStateSubscriberNextId = 0;

    subscribeToAuthStateChanges(callback) {
        if (callback === undefined || callback === null) {
            throw new Error(
                `Auth State Change callback cannot be null/undefined`
            );
        }

        this._authStateSubscribers.push({
            callback: callback,
            id: this._authStateSubscriberNextId,
        });
        this._authStateSubscriberNextId++;
        return this._authStateSubscriberNextId - 1;
    }

    unsubscribeFromAuthStateChanges(subscriptionId) {
        const subscriptions = this._authStateSubscribers
            .map((element, index) => {
                return {
                    element: element,
                    index: index,
                };
            })
            .filter((subscriber) => subscriber.element.id === subscriptionId);
        if (subscriptions.length !== 1) {
            throw new Error(
                `Found an invalid number of subscriptions (${subscriptions.length}) matching subscription Id (${subscriptionId})`
            );
        }

        this._authStateSubscribers.splice(subscriptions[0].index, 1);
    }

    notifySubscribersOfAuthStateChange() {
        for (let i = 0; i < this._authStateSubscribers.length; i++) {
            const callback = this._authStateSubscribers[i].callback;
            callback();
        }
    }

    //#endregion

    createSignInOutArguments(returnUrl) {
        let args = {
            useReplaceToNavigate: true,
        };

        if (returnUrl) {
            args.data = { returnUrl: returnUrl };
        }

        return args;
    }

    async performSilentLoginAsync(returnUrl) {
        await this.ensureIsInitializedAsync();

        if (this._isAuthenticated && !this._user?.expired) {
            log("User is already logged in. No action taken.");
            return createAuthResult(AuthResults.Success, returnUrl);
        }

        try {
            const silentAuthUser = await this.userManager.signinSilent(
                this.createSignInOutArguments()
            );
            this.updateAuthState(silentAuthUser);
            log("Successfully silently signed in user.");
            return createAuthResult(AuthResults.Success, returnUrl);
        } catch (ex) {
            console.error("Silent authentication error: ", ex);
            return createAuthResult(AuthResults.Fail, ex);
        }
    }

    async performLoginAsync(provider, returnUrl) {
        await this.ensureIsInitializedAsync();

        if (this._isAuthenticated && !this._user?.expired) {
            log("User is already logged in. No action taken.");
            return createAuthResult(AuthResults.Success, returnUrl);
        }

        try {
            // TODO: Check result to ensure we are being redirected
            await ajaxGetAnonymous(
                APIEndpoints.Auth +
                    `External?provider=${provider}&returnUrl=${returnUrl}`
            ); // This API request should start an authentication challenge + redirect
            return createAuthResult(AuthResults.Redirect, null);
        } catch (error) {
            console.error("Login failed with error: ", error);
            return createAuthResult(AuthResults.Fail, error);
        }
    }

    async performLoginCallbackAsync(returnUrl) {
        let url = window.location.href;
        try {
            await this.ensureIsInitializedAsync();
            const user = await this.userManager.signinCallback(url);
            this.updateAuthState(user);
            return createAuthResult(AuthResults.Success, returnUrl);
        } catch (error) {
            console.error("Login callback failed with error: ", error);
            return createAuthResult(AuthResults.Fail, "Login Callback Failed!");
        }
    }

    async performLogoutAsync(returnUrl) {
        if (!this._isAuthenticated) {
            return createAuthResult(AuthResults.Success, returnUrl);
        }

        await this.ensureIsInitializedAsync();

        try {
            await this.userManager.signoutRedirect(
                this.createSignInOutArguments(returnUrl)
            );
            return createAuthResult(AuthResults.Redirect, null);
        } catch (error) {
            console.error("Logout failed with error: ", error);
            return createAuthResult(AuthResults.Fail, error);
        }
    }

    async performLogoutCallbackAsync(returnUrl) {
        let url = window.location.href;
        try {
            await this.ensureIsInitializedAsync();
            await this.userManager.signoutCallback(url);
            this._logoutCallbacks.forEach((callback) => {
                if (!!callback) {
                    callback();
                }
            });
            return createAuthResult(AuthResults.Success, returnUrl);
        } catch (error) {
            console.error("Logout callback failed with error: ", error);
            return createAuthResult(AuthResults.Fail, error);
        }
    }
}

export const authService = new AuthenticationService();
export default authService;
