import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Popover } from "antd";
import parse from "html-react-parser";

import { clearSearchAndRedirectToThing } from "../../redux/actions";
import { getInProgressThingsListDispatchable } from "../../redux/actions/thingListsActions";

import { ThingTabs, Defaults } from "../../constants";
import { MessageError } from "../../shared/MessageError";
import LoadingIndicator from "../../shared/LoadingIndicator";

export const InProgressThingsCard = () => {
    const dispatch = useDispatch();
    const currentAccount = useSelector((state) => state.account.currentAccount);
    const tooltips = useSelector((state) => state.account.tooltipsList);
    const thingList = useSelector((state) => state.thinglists.inProgressThings);
    const thingsCount = useSelector((state) =>
        state.thinglists.inProgressThings
            ? state.thinglists.inProgressThings.count
            : 0
    );
    const loading = useSelector(
        (state) => state.thinglists.inProgressThingsLoading
    );
    const error = useSelector(
        (state) => state.thinglists.inProgressThingsError
    );

    useEffect(() => {
        if (!currentAccount) {
            return; // Can't load lists until currentAccount is populated
        }

        dispatch(getInProgressThingsListDispatchable());
    }, [dispatch, currentAccount]);

    var selectedItem = null;
    var title = "TOOLTIP KEY NOT FOUND";
    var content = "";
    if (tooltips) {
        selectedItem = tooltips.filter(
            (t) => t.key === "Header_InProgressThings"
        )[0];
        if (selectedItem) {
            title = selectedItem.title;
            content = selectedItem.content;
        }
    }

    if (error) {
        return <MessageError error={error} />;
    }
    if (loading) {
        return <LoadingIndicator />;
    }
    if (thingsCount === 0) {
        return null;
    }
    return (
        <Popover
            trigger="hover"
            placement="rightTop"
            content={parse(content)}
            title={title}
            mouseEnterDelay={Defaults.POPOVER_MOUSE_ENTER_DELAY_SLOW}
            mouseLeaveDelay={Defaults.POPOVER_MOUSE_LEAVE_DELAY}
        >
            <div
                className="tt-title-bar tt-pointer tt-bgcolor-inprogress"
                onClick={() => {
                    var thingId = thingList.listData[0].thingId;
                    dispatch(
                        clearSearchAndRedirectToThing(
                            thingId,
                            ThingTabs.IN_PROGRESS_THINGS
                        )
                    );
                }}
            >
                {title}{" "}
                <Badge
                    count={thingsCount}
                    className="tt-title-badge"
                    style={{ backgroundColor: "#ff4d4f" }}
                    overflowCount={999}
                />
            </div>
        </Popover>
    );
};

export default InProgressThingsCard;
