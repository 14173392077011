import React from "react";
import { Tag, Row, Col, Tooltip } from "antd";
import { ImportantIcon, InProgressIcon } from "./ThingIcons";

export const ThingTitleInfo = (props) => {
    const thing = props.thing;
    const { hideInProgressIcon, hideImportantIcon, titleLinkUrl } = props;

    const hasTitleURL = titleLinkUrl !== undefined && titleLinkUrl !== null;

    return (
        <Row>
            <Col flex="0 1">
                {!hideImportantIcon && thing.important === true && (
                    <ImportantIcon />
                )}
                {!hideInProgressIcon && thing.complete === false && (
                    <InProgressIcon />
                )}
            </Col>
            <Col flex="1 1" className="tt-thing-title-name-wrapper">
                <span>
                    <Tooltip title={thing.name} placement="right">
                        {hasTitleURL && (
                            <a
                                className="tt-title-info-name-link"
                                target="_blank"
                                rel="noopener noreferrer"
                                href={titleLinkUrl}
                            >
                                {thing.name}
                            </a>
                        )}
                        {!hasTitleURL && (
                            <span className="tt-title-info-name">
                                {thing.name}
                            </span>
                        )}
                    </Tooltip>
                </span>
            </Col>
            <Col flex="0 1">
                <Tag className="tt-thing-type-pill">{thing.thingType}</Tag>
            </Col>
        </Row>
    );
};

export default ThingTitleInfo;
