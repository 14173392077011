import React, { Fragment, useState, useEffect } from "react";
import { useSelector } from "react-redux";

import Dialog from "rc-dialog";

import { CloseOutlined } from "@ant-design/icons";

function ThingIsDirtyCheck(props) {
    const isDirty = useSelector((state) => state.thing.isDirty);

    const [isVisible, setIsVisible] = useState(false);

    // Effect to hide the dialogue if the thing is no longer dirty
    useEffect(() => {
        if (!isDirty && isVisible) {
            setIsVisible(false);
        }
    }, [isDirty, isVisible, setIsVisible]);

    const handleOnClickCapture = (e) => {
        if (isDirty) {
            setIsVisible(true);

            // Invoke all the methods to stop event propagation.
            // Yes this is overkill, but different component types need different methods. Not sure why
            e.preventDefault();
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
            return false;
        }
    };

    // const handleDiscardChanges = (e) => {
    // 	console.log("TODO: Implement discarding changes from IsDirty Modal");
    // 	setIsVisible(false);
    // };

    // const handleSaveChanges = (e) => {
    // 	console.log("TODO: Implement saving changes from IsDirty Modal");
    // 	setIsVisible(false);
    // };

    const handleClose = (e) => {
        setIsVisible(false);
    };

    const render = () => {
        if (isDirty) {
            const styleClassPrefix = "ant-modal";

            const modalFooter = null;
            // const modalFooter = (
            // 	<Fragment>
            // 		{/* <Button onClick={handleClose}>Okay</Button> */}
            // 		{/* {!isSaving && (
            // 			<Fragment>
            // 				<Button onClick={handleDiscardChanges}>Discard Changes</Button>
            // 				<Button onClick={handleSaveChanges}>Save Changes</Button>
            // 			</Fragment>
            // 		)}
            // 		{isSaving && (<Spin style={{ paddingLeft: "24px" }} />)} */}
            // 	</Fragment>
            // );

            return (
                <Fragment>
                    <Dialog
                        title="Unsaved Changes!"
                        visible={isVisible}
                        footer={modalFooter}
                        closable="true"
                        onClose={handleClose}
                        closeIcon={
                            <span className={`${styleClassPrefix}-close-x`}>
                                <CloseOutlined
                                    className={`${styleClassPrefix}-close-icon`}
                                />
                            </span>
                        }
                        prefixCls={styleClassPrefix}
                        width="520px"
                        transitionName="zoom"
                        mask="true"
                        maskClosable="true"
                        maskTransitionName="fade"
                    >
                        <div>
                            The current selected Thing has unsaved changes.
                            Please save or discard your changes before
                            continuing.
                        </div>
                    </Dialog>
                    <div onClickCapture={handleOnClickCapture}>
                        {props.children}
                    </div>
                </Fragment>
            );
        }

        return <Fragment>{props.children}</Fragment>;
    };
    return render();
}

export default ThingIsDirtyCheck;
