import React from "react";
import { Tag, Row, Col, Tooltip } from "antd";

export const TitleWithPill = (props) => {
    const { titleContent, pillContent, titleLinkUrl } = props;

    const hasTitleURL = titleLinkUrl !== undefined && titleLinkUrl !== null;

    return (
        <Row>
            <Col flex="1 1" className="tt-no-overflow">
                <span>
                    <Tooltip title={titleContent} placement="right">
                        {hasTitleURL && (
                            <a
                                className="tt-title-info-name-link"
                                target="_blank"
                                rel="noopener noreferrer"
                                href={titleLinkUrl}
                            >
                                {titleContent}
                            </a>
                        )}
                        {!hasTitleURL && (
                            <span className="tt-title-info-name">
                                {titleContent}
                            </span>
                        )}
                    </Tooltip>
                </span>
            </Col>
            <Col flex="0 1">
                <Tag className="tt-thing-type-pill">{pillContent}</Tag>
            </Col>
        </Row>
    );
};

export default TitleWithPill;
