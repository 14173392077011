import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

import { Routes } from "../../constants";
import LoginContainer from "../login";
import NewUserPrompt from "../login/NewUserPrompt";

export const HomeContainer = () => {
    const isAuthenticated = useSelector((state) => state.auth.authenticated);

    // Redirect to dashboard if already signed in
    if (isAuthenticated) {
        return <Redirect to={Routes.DASHBOARD} />;
    }

    return (
        <Fragment>
            <LoginContainer />
            <div className="tt-spacer" />
            <NewUserPrompt />
        </Fragment>
    );
};

export default HomeContainer;
